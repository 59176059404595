import * as yup from "yup";

export interface IFormValues {
  firstName: string;
  lastName: string;
  unit: string;
  email: string;
  phone: string;
}

export default yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid Email")
    .required("Please enter valid Email"),
  firstName: yup
    .string()
    .min(2, "Please enter valid First name")
    .required("Please enter valid First name"),
  lastName: yup
    .string()
    .min(2, "Please enter valid Last name")
    .required("Please enter valid Last name"),
  phone: yup
    .string()
    .test(
      "phoneFormat",
      "Please enter valid Phone",
      (value?: string) =>
        !!value && /^[2-9]\d{9}$/.test(value.replace(/\(|\)|-|\s+/g, ""))
    )
    .required("Please enter valid Phone"),
});
