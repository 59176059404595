import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import values from "lodash/values";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Price from "../../components/pricing";
import Button from "../../../../components/action-button";
import Coverage from "../../components/coverage";
import withConnectedQuote from "../../../../hoc/quote.hoc";
import { showModal } from "../../../../modules/modalProvider";
import {
  updateQuote,
  UpdateQuote,
} from "../../../../resources/insurance/insurance.actions";
import { IQuote } from "../../../../resources/insurance/types";

import styles from "./deductible.module.css";

interface IProps {
  quote: IQuote;
  updateQuote: UpdateQuote;
}

function Deductible({ quote, updateQuote }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [value, setValue] = React.useState(quote.baseDeductible.value);
  const [originalValue, setOriginalValue] = React.useState(
    quote.baseDeductible.value
  );
  const [serverError, setServerError] = React.useState("");

  async function setDeductible(newValue: number) {
    setServerError("");
    const prevValue = value;
    try {
      setValue(newValue);
      await updateQuote({ baseDeductible: newValue });
    } catch (err) {
      setValue(prevValue);
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  function onSubmit() {
    setServerError("");
    setOriginalValue(value);
    history.push(`/insurance/edit/${id}`);
  }

  const openRevert = () => {
    if (value !== originalValue) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (value !== originalValue) {
        await updateQuote({ baseDeductible: originalValue });
      }
      history.goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <SafeAreaContainer className={styles.head}>
          <Header>Set deductible</Header>
        </SafeAreaContainer>
        <hr className={styles.hr} />
        <SafeAreaContainer>
          <Price amount={quote.monthlyPremiumInCents} period="month" />
          <Coverage
            cents
            data-id="deductible"
            title="Deductible"
            description="This is the amount that will be deducted from any claim payment you may receive. For example, choosing a $1,000 deductible means that if you file a claim for $5,000 and it's approved, we will pay you $4,000."
            value={value}
            options={quote.baseDeductible.options}
            onChange={setDeductible}
            readonly={quote.status === "PAID"}
          />
          <div className={styles.errorBlock}>{serverError}</div>
        </SafeAreaContainer>
        <Button onClick={onSubmit} data-id="setDeductibleBtn">
          Set deductible
        </Button>
      </Container>
    </>
  );
}

Deductible.displayName = "Deductible";
export default withConnectedQuote(
  connect(() => ({}), {
    updateQuote,
  })(Deductible)
);
