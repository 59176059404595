import * as React from "react";
import cx from "classnames";

import styles from "./text.module.css";

interface IProps {
  className?: string;
  children: string | React.ReactChild | React.ReactChild[];
  bold?: boolean;
  font?: 16;
  "data-id"?: string;
}

export default function Text({
  className,
  children,
  bold,
  font = 16,
  ...props
}: IProps) {
  return (
    <div
      className={cx(styles.text, className, {
        [styles.bold]: bold,
        [styles.fontSize16]: font === 16
      })}
      data-id={props["data-id"]}
    >
      {children}
    </div>
  );
}
Text.displayName = "Text";
