export default class APIError extends Error {
  data: any;
  status: number;

  __proto__: any;

  constructor(data: any, status: number) {
    super(status.toString());
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    // a workaround to make `instanceof ApiError` work in ES5 with babel
    this.constructor = APIError;
    this.__proto__ = APIError.prototype; // eslint-disable-line

    this.data = data;
    this.status = status;
  }
}
