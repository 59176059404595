import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import values from "lodash/values";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Price from "../../components/pricing";
import Button from "../../../../components/action-button";
import MenuItem from "../../../../components/menu-item";
import GlobalSpinner from "../../../../components/spinner/global";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import { showModal } from "../../../../modules/modalProvider";
import {
  updateQuoteBoost,
  UpdateQuoteBoost,
} from "../../../../resources/insurance/insurance.actions";
import { getUser } from "../../../../resources/user/user.selectors";
import {
  IBoostQuote,
  ICoverageValue,
} from "../../../../resources/insurance/types";
import { IUser } from "../../../../resources/user/types";
import { IStore } from "../../../../resources/types";

import { IExtraCoverage, EXTRA_COVERAGES } from "../boost.config";

import styles from "./extra-coverage.module.css";

interface IProps {
  quote: IBoostQuote;
  user?: IUser;
  updateQuoteBoost: UpdateQuoteBoost;
}

function ExtraCoverage({ quote, user, updateQuoteBoost }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [value, setValue] = React.useState(
    quote ? quote.summary.extraCoverages : null
  );
  const [originalValue, setOriginalValue] = React.useState(
    quote ? quote.summary.extraCoverages : null
  );
  const [serverError, setServerError] = React.useState("");

  if (!user) {
    return <GlobalSpinner />;
  }

  const goBack = () => history.replace(`/insurance/boost/edit/${id}`);

  function onSubmit() {
    setServerError("");
    if (!quote) {
      return;
    }
    setOriginalValue(value);
    setValue(value);
    goBack();
  }

  const openRevert = () => {
    if (value !== originalValue) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (value !== originalValue && originalValue) {
        try {
          await updateQuoteBoost({
            extraCoverages: originalValue,
          });
        } catch (err) {
          history.push("/insurance/boost/error");
          return;
        }
      }
      goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const onCoverageClick = (coverage: IExtraCoverage) => {
    history.replace(
      `/insurance/boost/edit/${quote._id}/extra/${coverage.value}`
    );
  };

  const AVAILABLE_COVERAGES = EXTRA_COVERAGES.filter((cov) => {
    if (cov.availableStates) {
      if (cov.availableStates.includes(user.state)) {
        return true;
      }
      return false;
    }
    if (cov.notAvailableStates) {
      if (cov.notAvailableStates.includes(user.state)) {
        return false;
      }
      return true;
    }
    return true;
  });

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <SafeAreaContainer className={styles.head}>
          <Header>
            Optional
            <br />
            coverage
          </Header>
        </SafeAreaContainer>
        <hr className={styles.hr} />
        <SafeAreaContainer className={styles.content}>
          <Price
            amount={quote.summary.paymentAmount}
            period="month"
            className={styles.pricing}
          />

          {AVAILABLE_COVERAGES.filter((x) => x.top).map(
            (coverage: IExtraCoverage) => {
              const value = quote.summary.extraCoverages.find(
                (x: ICoverageValue) => x.id === coverage.value
              );
              const active =
                !!value ||
                (coverage.value === "enhancementType" &&
                  !!quote.summary.enhancementType);
              return (
                <MenuItem
                  data-id={coverage.value}
                  key={coverage.value}
                  title={coverage.title}
                  active={active}
                  onClick={() => onCoverageClick(coverage)}
                />
              );
            }
          )}
          <hr className={styles.line} />

          <div className={styles.description}>
            We also offer the following coverages:
          </div>

          {AVAILABLE_COVERAGES.filter((x) => !x.top).map(
            (coverage: IExtraCoverage) => {
              const value = quote.summary.extraCoverages.find(
                (x: ICoverageValue) => x.id === coverage.value
              );
              const active =
                !!value ||
                (coverage.value === "enhancementType" &&
                  !!quote.summary.enhancementType);
              return (
                <MenuItem
                  key={coverage.value}
                  title={coverage.title}
                  active={active}
                  onClick={() => onCoverageClick(coverage)}
                />
              );
            }
          )}
          <div className={styles.errorBlock}>{serverError}</div>
        </SafeAreaContainer>
        <Button onClick={onSubmit} data-id="returnBtn">
          Return to Quote Builder
        </Button>
      </Container>
    </>
  );
}

ExtraCoverage.displayName = "ExtraCoverage";
export default withBoostQuote(
  connect(
    (state: IStore) => ({
      user: getUser(state),
    }),
    {
      updateQuoteBoost,
    }
  )(ExtraCoverage)
);
