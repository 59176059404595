import * as React from "react";
import cx from "classnames";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import CardIcon from "../card-icon";

import CalendarIcon from "../icons/calendar";

import styles from "./card-element.module.css";

interface IProps {
  className?: string;
  cardBrand?: string;
  error?: string;
  type: "number" | "expiry" | "cvc";
  placeholder?: string;
  onChange?: (value: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onReady?: () => void;
  hideIcon?: boolean;
  iconOnRight?: boolean;
  hideErrorMessage?: boolean;
}

function CardElement({
  className,
  cardBrand,
  type,
  error,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onReady,
  hideIcon = false,
  iconOnRight = false,
  hideErrorMessage = false,
}: IProps) {
  let element;
  let icon;
  const elementProps = {
    placeholder,
    onChange,
    className: styles.input,
    onFocus,
    onBlur,
    onReady,
    options: {
      classes: {
        base: styles.input,
        focus: styles.focus,
        invalid: styles.error,
        complete: styles.valid,
      },
      style: {
        base: {
          fontSize: "17px",
          lineHeight: "20px",
          fontWeight: "500",
          fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#787878",
            fontWeight: "500",
          },
        },
      },
    },
  };

  switch (type) {
    case "number":
      icon = (
        <CardIcon
          cardBrand={cardBrand}
          className={cx(styles.cardIcon, iconOnRight ? styles.iconRight : null)}
        />
      );
      element = (
        <div>
          <CardNumberElement
            data-id="cardNumberInput"
            {...{
              ...elementProps,
              className: cx(
                elementProps.className,
                !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                error ? styles.error : undefined
              ),
              options: {
                ...elementProps.options,
                classes: {
                  ...elementProps.options.classes,
                  base: cx(
                    elementProps.options.classes.base,
                    !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                    error ? styles.error : undefined
                  ),
                },
              },
            }}
          />
          {!hideErrorMessage && error && (
            <span className={styles.errorMessage}>{error}</span>
          )}
        </div>
      );
      break;
    case "expiry":
      icon = (
        <CalendarIcon
          className={cx(styles.icon, iconOnRight ? styles.iconRight : null)}
        />
      );
      element = (
        <div>
          <CardExpiryElement
            data-id="expiryInput"
            {...{
              ...elementProps,
              className: cx(
                elementProps.className,
                !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                error ? styles.error : undefined
              ),
              options: {
                ...elementProps.options,
                classes: {
                  ...elementProps.options.classes,
                  base: cx(
                    elementProps.options.classes.base,
                    !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                    error ? styles.error : undefined
                  ),
                },
              },
            }}
          />
          {!hideErrorMessage && error && (
            <span className={styles.errorMessage}>{error}</span>
          )}
        </div>
      );
      break;
    case "cvc":
      element = (
        <div>
          <CardCvcElement
            data-id="cvcInput"
            {...{
              ...elementProps,
              className: cx(
                elementProps.className,
                !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                error ? styles.error : undefined
              ),
              options: {
                ...elementProps.options,
                classes: {
                  ...elementProps.options.classes,
                  base: cx(
                    elementProps.options.classes.base,
                    !hideIcon && !iconOnRight ? styles.hasIcon : styles.noIcon,
                    error ? styles.error : undefined
                  ),
                },
              },
            }}
          />
          {!hideErrorMessage && error && (
            <span className={styles.errorMessage}>{error}</span>
          )}
        </div>
      );
      break;
    default:
      element = undefined;
  }

  return (
    <div className={cx(styles.inputContainer, className)}>
      {!hideIcon && !iconOnRight ? icon : null}
      {element}
      {!hideIcon && iconOnRight ? icon : null}
    </div>
  );
}

CardElement.displayName = "CardElement";
export default CardElement;
