import { AnyAction } from "redux";
import { IInsuranceState } from "./types";

import {
  QUOTE_CREATE,
  QUOTE_FETCH,
  QUOTE_UPDATE,
  QUOTE_PAY,
  FETCH_INSURANCE,
  POLICY_FETCH,
  POLICY_DELETE,
  POLICY_UPLOAD,
  QUOTE_SET_TOKEN,
  BOOST_QUOTE_CREATE,
  BOOST_QUOTE_UPDATE,
  BOOST_QUOTE_FETCH,
  BOOST_POLICY_FETCH,
  BOOST_POLICY_FETCH_BY_TOKEN,
  BOOST_COVERAGE_SET,
  BOOST_COVERAGE_SET_FACTORS,
  BOOST_CLAIMS_FETCH,
  BOOST_CLAIM_FETCH,
  QUOTE_LIABILITY_OPTIONS_FETCH,
} from "./insurance.actions";

const defaultState: IInsuranceState = {};

export default function insuranceReducer(
  state: IInsuranceState | undefined = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_INSURANCE:
      return {
        ...state,
        insurance: action.payload,
      };
    case POLICY_FETCH:
    case POLICY_UPLOAD: {
      return {
        ...state,
        policy: action.payload,
        insurance: undefined,
      };
    }
    case POLICY_DELETE: {
      return {
        ...state,
        policy: undefined,
      };
    }
    case BOOST_CLAIM_FETCH: {
      return {
        ...state,
        claim: action.payload,
      };
    }
    case `${BOOST_CLAIM_FETCH}_START`: {
      return {
        ...state,
        claim: undefined,
      };
    }
    case BOOST_CLAIMS_FETCH: {
      return {
        ...state,
        claims: action.payload,
      };
    }
    case QUOTE_LIABILITY_OPTIONS_FETCH: {
      return {
        ...state,
        personalLiabilityOptions: action.payload,
      };
    }
    case QUOTE_CREATE:
    case QUOTE_FETCH:
    case QUOTE_UPDATE:
    case QUOTE_SET_TOKEN:
    case QUOTE_PAY: {
      return {
        ...state,
        quote: {
          ...state.quote,
          ...action.payload,
        },
        insurance: undefined,
      };
    }
    case BOOST_QUOTE_CREATE:
    case BOOST_QUOTE_UPDATE:
    case BOOST_QUOTE_FETCH: {
      return {
        ...state,
        boost: {
          ...action.payload,
        },
        quote: undefined,
        insurance: undefined,
      };
    }
    case BOOST_POLICY_FETCH:
    case BOOST_POLICY_FETCH_BY_TOKEN: {
      return {
        ...state,
        boostPolicy: {
          ...action.payload,
        },
        quote: undefined,
        insurance: undefined,
      };
    }
    case BOOST_COVERAGE_SET: {
      return {
        ...state,
        boostCoverage: action.coverage,
      };
    }
    case BOOST_COVERAGE_SET_FACTORS: {
      return {
        ...state,
        factors: action.factors,
      };
    }
    default:
      return state;
  }
}
