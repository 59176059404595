import * as React from "react";
import cx from "classnames";

import Switch from "react-switch";

import styles from "./index.module.css";

interface IProps extends React.HTMLProps<HTMLSelectElement> {
  className?: string;

  checked: boolean;
  disabled?: boolean;
  onChange: any;
}

export default function SwitchComponent({
  className = "",
  checked,
  disabled,
  onChange,
}: IProps) {
  return (
    <div
      className={cx(styles.inputContainer, className)}
      data-id="switchContainer"
    >
      <i />
      <Switch
        checkedIcon={false}
        uncheckedIcon={false}
        disabled={disabled || false}
        onColor="#0188ff"
        onChange={onChange}
        checked={checked || false}
      />
    </div>
  );
}
SwitchComponent.displayName = "Switch";
