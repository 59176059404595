import * as React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import Header from "../../../components/header";
import { SafeAreaContainer } from "../../../components/container";
import { getQuote } from "../../../resources/insurance/insurance.selectors";
import { IQuote } from "../../../resources/insurance/types";
import { IStore } from "../../../resources/types";

import GTag from "../../../gtag";

import styles from "./confirmation.module.css";

interface IProps {
  quote?: IQuote;
}

function Confirmation({ quote }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("lemonade_confirmation", "Page");
  }, []);

  if (!quote) {
    return null;
  }

  async function onSubmit() {
    if (!quote) {
      return;
    }
    history.push("/");
  }

  return (
    <SafeAreaContainer>
      <Header className={styles.title}>Payment confirmation</Header>
      <div className={styles.description}>
        You’re all set. You just purchased renters insurance from Lemonade.
      </div>
      <div className={styles.description}>
        Your policy will be active on
        <div className={styles.date}>
          {moment(quote.effectiveDate.currentValue * 1000).format(
            "MMMM D, YYYY"
          )}
          .
        </div>
      </div>

      <div className={styles.description}>
        You will get a confirmation from Lemonade in your email, and you can
        always visit the Lemonade app to access your policy.
      </div>
      <Button onClick={onSubmit} className={styles.btn} data-id="doneBtn">
        All done!
      </Button>
    </SafeAreaContainer>
  );
}

Confirmation.displayName = "Confirmation";
export default connect((state: IStore) => ({
  quote: getQuote(state),
}))(Confirmation);
