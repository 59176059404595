import * as React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import Spinner from "../../components/spinner";
import Container from "../../components/container";
import { IStore } from "../../resources/types";

import styles from "./layout.module.css";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  layoutClass?: string;
  showBackButton?: boolean;
  loading?: boolean;
}

function Layout({
  children,
  className,
  showBackButton,
  loading,
  layoutClass,
}: IProps) {
  React.useEffect(() => {
    if (layoutClass) {
      document.body.classList.add(layoutClass);
    } else {
      document.body.classList.add(styles.bodyClass);
    }

    return () => {
      if (layoutClass) {
        document.body.classList.remove(layoutClass);
      }
      document.body.classList.remove(styles.bodyClass);
    };
  }, [layoutClass]);

  return (
    <div id="layout">
      <Container
        className={cx(styles.container, layoutClass, className, {
          [styles.hideHeader]: showBackButton === false,
        })}
      >
        {children}
      </Container>
      {loading && <Spinner />}
    </div>
  );
}

Layout.displayName = "Layout";
export default connect((state: IStore) => ({
  loading: state.loading,
}))(Layout);
