import { createStore, applyMiddleware, AnyAction, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { History } from "history";
import { IStore } from "./types";
import createReducer from "./reducer";

declare global {
  interface Window {
    gtag: (...args: any[]) => {};
  }
}

export default function configureStore(initialState: IStore, history: History) {
  const store: Store = createStore<IStore | undefined, AnyAction, {}, {}>(
    createReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );

  if (module.hot) {
    module.hot.accept("./reducer", () => {
      store.replaceReducer(createReducer(history));
    });
  }

  return store;
}
