import client from "../../helpers/api";
import mergePath from "../../helpers/mergePath";
import mapToFormData from "../../helpers/mapToFormData";
import {
  IInsurance,
  IPolicy,
  IUploadPolicy,
  IUpdateQuoteParams,
  IUpdateBoostQuoteParams,
  ISetQuoteTokenParams,
  IPaymentMethod,
} from "./types";

export function createQuote(data: { dateOfBirth: Date }) {
  return client.post(mergePath("api", "/insurances/quote"), data);
}

export function createQuoteBoost() {
  return client.post(mergePath("api", "/insurances/boost/quote"), {});
}

export function getQuote() {
  return client.get(mergePath("api", "/insurances/quote"));
}

export function getQuoteBoost() {
  return client.get(mergePath("api", "/insurances/boost/quote"));
}

export function getPersonalLiabilityOptions() {
  return client.get(
    mergePath("api", "/insurances/boost/quote/liability/options")
  );
}

export function getPolicyBoost() {
  return client.get(mergePath("api", "/insurances/boost/policy"));
}

export function getPolicyBoostByToken(data: { token: string }) {
  return client.get(
    mergePath("api", `/insurances/public/boost/policy?token=${data.token}`)
  );
}

export function cancelPolicyBoost() {
  return client.del(mergePath("api", "/insurances/boost/policy"));
}

export function cancelPolicyBoostByToken(data: { token: string }) {
  return client.del(
    mergePath("api", `/insurances/public/boost/policy?token=${data.token}`)
  );
}

export function addAdditionalInsured(data: {
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) {
  return client.post(
    mergePath(
      "api",
      `/insurances/boost/quote/${data.quoteId}/additionalInsured`
    ),
    data
  );
}

export function updateAdditionalInsured(data: {
  id: string;
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) {
  return client.put(
    mergePath(
      "api",
      `/insurances/boost/quote/${data.quoteId}/additionalInsured/${data.id}`
    ),
    data
  );
}

export function removeAdditionalInsured(data: { id: string; quoteId: string }) {
  return client.del(
    mergePath(
      "api",
      `/insurances/boost/quote/${data.quoteId}/additionalInsured/${data.id}`
    )
  );
}

export function getAdditionalInsured(data: { id: string; quoteId: string }) {
  return client.get(
    mergePath(
      "api",
      `/insurances/boost/quote/${data.quoteId}/additionalInsured/${data.id}`
    )
  );
}

export function addAdditionalInterested(data: {
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) {
  return client.post(
    mergePath(
      "api",
      `/insurances/boost/${data.isPolicy ? "policy" : "quote"}/${
        data.entityId
      }/additionalInterested`
    ),
    data
  );
}

export function updateAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) {
  return client.put(
    mergePath(
      "api",
      `/insurances/boost/${data.isPolicy ? "policy" : "quote"}/${
        data.entityId
      }/additionalInterested/${data.id}`
    ),
    data
  );
}

export function removeAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) {
  return client.del(
    mergePath(
      "api",
      `/insurances/boost/${data.isPolicy ? "policy" : "quote"}/${
        data.entityId
      }/additionalInterested/${data.id}`
    )
  );
}

export function getAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) {
  return client.get(
    mergePath(
      "api",
      `/insurances/boost/${data.isPolicy ? "policy" : "quote"}/${
        data.entityId
      }/additionalInterested/${data.id}`
    )
  );
}

export function createClaimBoost(data: {
  dateOfLoss?: Date;
  description: string;
  email: string;
  phone: string;
  policyId: string;
  files?: File[];
}) {
  const formData = mapToFormData(data);
  return client.post(
    mergePath("api", `/insurances/boost/policy/${data.policyId}/claims`),
    formData
  );
}

export function addClaimDocuments(data: {
  policyId: string;
  claimId: string;
  files?: File[];
}) {
  const formData = mapToFormData({ files: data.files });
  return client.post(
    mergePath(
      "api",
      `/insurances/boost/policy/${data.policyId}/claims/${data.claimId}/documents`
    ),
    formData
  );
}

export function fetchClaimsBoost(data: { policyId: string }) {
  return client.get(
    mergePath("api", `/insurances/boost/policy/${data.policyId}/claims`)
  );
}

export function fetchClaimBoost(data: { policyId: string; claimId: string }) {
  return client.get(
    mergePath(
      "api",
      `/insurances/boost/policy/${data.policyId}/claims/${data.claimId}`
    )
  );
}

export function updateQuote(data: Partial<IUpdateQuoteParams>) {
  return client.patch(mergePath("api", "/insurances/quote"), data);
}

export function updateQuoteBoost(data: Partial<IUpdateBoostQuoteParams>) {
  return client.patch(mergePath("api", "/insurances/boost/quote"), data);
}

export function setQuoteToken(data: Partial<ISetQuoteTokenParams>) {
  return client.patch(mergePath("api", "/insurances/quote/token"), data);
}

export function payQuote(data: { tosApproved: boolean }) {
  return client.post(mergePath("api", "/insurances/quote/pay"), data);
}

export function payBoost({
  id,
  paymentMethod,
  confirmed,
}: {
  id: string;
  paymentMethod?: IPaymentMethod;
  confirmed?: boolean;
}) {
  return client.post(mergePath("api", "/insurances/boost/quote/pay"), {
    id,
    paymentMethod,
    confirmed,
  });
}

export function uploadPolicy(data: IUploadPolicy): Promise<IPolicy> {
  const formData = mapToFormData(data);
  return client.post(mergePath("api", "/insurances"), formData);
}

export function getPolicy(): Promise<IPolicy> {
  return client.get(mergePath("api", "/insurances/policy"));
}

export function getInsurance(): Promise<IInsurance> {
  return client.get(mergePath("api", "/insurances/me"));
}

export function deletePolicy({ id }: any): Promise<any> {
  return client.del(mergePath("api", `/insurances/${id}`));
}

export function getPolicyByPaymentIntentId(paymentIntentId: string) {
  return client.get(mergePath("api", `/payments/${paymentIntentId}`), {});
}
