import * as React from "react";
import moment, { Moment } from "moment";
import cx from "classnames";
import config from "../../config";
import Input from "../input";
import DatePicker from "../date-picker";

import { ReactComponent as CalendarIcon } from "../icons/calendar_icon.svg";

import styles from "./date.module.css";

interface IProps {
  title: string;
  className?: string;
  containerClassName?: string;
  textClassName?: string;
  value?: Date;
  startDate?: Date;
  onChange: (value: Date) => void;
  Icon?: any;
  hasMonthSelector?: boolean;
  hasYearSelector?: boolean;
  "data-id"?: string;
}

export default function Date({
  title,
  onChange,
  className,
  containerClassName,
  textClassName,
  value,
  startDate,
  hasMonthSelector,
  hasYearSelector,
  Icon,
  ...props
}: IProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const toggleCalendar = () => {
    if (!onChange) {
      return;
    }
    setIsCalendarOpen(!isCalendarOpen);
  };
  const onDateChange = (date: Moment) => {
    setIsCalendarOpen(false);
    onChange(date.toDate());
  };
  return (
    <>
      <div
        className={cx(containerClassName, styles.pointer)}
        data-name="open-calendar"
        data-id={props["data-id"]}
        onClick={toggleCalendar}
      >
        {!isCalendarOpen && (
          <div>
            <Input
              data-id={`${props["data-id"]}Input`}
              value={moment(value).format("YYYY-MM-DD")}
              type="text"
              className={className}
              readOnly
            />
            <div className={cx(textClassName)}>
              {value ? (
                moment(value, "YYYY-MM-DD").format("MMMM D, YYYY")
              ) : (
                <div style={{ color: "#9B9B9B" }}>{title}</div>
              )}
            </div>
            {Icon ? <Icon /> : <CalendarIcon />}
          </div>
        )}
        {isCalendarOpen && (
          <div className={styles.datePicker}>
            <DatePicker
              startDate={
                startDate
                  ? moment(startDate)
                  : moment().tz(config.defaultTimezone)
              }
              allowWeekends
              date={value ? moment(value) : null}
              onChange={onDateChange}
              hasYearSelector={hasYearSelector}
              hasMonthSelector={hasMonthSelector}
            />
          </div>
        )}
      </div>
    </>
  );
}
Date.displayName = "Date";
