import * as React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../config";
import InsuranceTask from "./insurance";
import Spinner from "../../components/spinner/global";
import LayoutHeader from "../../layouts/main/header";
import { getUser } from "../../resources/user/user.selectors";
import { IUser } from "../../resources/user/types";
import { IInsurance } from "../../resources/insurance/types";
import { IStore } from "../../resources/types";
import { fetchUser, FetchUser } from "../../resources/user/user.actions";
import {
  fetchInsurance,
  FetchInsurance,
} from "../../resources/insurance/insurance.actions";
import { getInsurance } from "../../resources/insurance/insurance.selectors";
import { useAuth } from "../../auth";

import { ReactComponent as AppStoreBadge } from "../../components/icons/app-store-badge.svg";
import { ReactComponent as GooglePlayBadge } from "../../components/icons/google-play-badge.svg";

import { getInsuranceText } from "../../helpers/task";

import GTag from "../../gtag";

import styles from "./home.module.css";

interface IProps {
  user: IUser;
  fetchUser: FetchUser;
  insurance?: IInsurance;
  fetchInsurance: FetchInsurance;
}

function Home({ user, fetchUser, insurance, fetchInsurance }: IProps) {
  const history = useHistory();
  const context = useAuth();

  React.useEffect(() => {
    fetchInsurance();
  }, [fetchInsurance]);

  React.useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  React.useEffect(() => {
    if (user && user.newAddress && !user.isAddressRejected) {
      history.push("/confirm-address");
    }
  }, [user, history]);

  React.useEffect(() => {
    if (insurance && !insurance._id) {
      history.replace("/insurance");
      return;
    }
    if (insurance && insurance.policyId && insurance.status !== "CANCELED") {
      history.replace(`/insurance/boost/policy/${insurance.policyId}`);
    }
  }, [insurance, history]);

  if (
    !insurance ||
    (insurance &&
      (!insurance._id ||
        (insurance.policyId && insurance.status !== "CANCELED")))
  ) {
    return <Spinner />;
  }

  function onDownloadClick(type: string) {
    GTag.event("download_lemonade", "Click");
    switch (type) {
      case "google":
        window.open(
          "https://play.google.com/store/apps/details?id=com.lemonadeinc.lemonade&shortlink=6a83b39e&pid=lemonade.com&c=footer_link"
        );
        break;
      case "apple":
        window.open(
          "https://apps.apple.com/app/id1055653645?c=footer_link&pid=lemonade.com&shortlink=84bca19b"
        );
        break;
      default:
        break;
    }
  }

  function onInsuranceClick() {
    if (!insurance) {
      return;
    }
    if (insurance.policyId && insurance.status !== "CANCELED") {
      GTag.event("open_boost_policy", "Page");
      history.push(`/insurance/boost/policy/${insurance.policyId}`);
      return;
    }

    GTag.event("open_insurance", "Page");
    history.push("/insurance");
  }

  async function logout() {
    if (context && context.type === "in-app") {
      const rawToken = await context.getRawToken();
      window.location.replace(
        `${config.returnLink}${rawToken ? `?token=${rawToken}` : ""}`
      );
    }
  }

  const isFinished = user.isConfirmed && user.isInsuranceComplete;
  // const isInApp = context && context.type === "in-app";

  const isLemonadeFinished =
    isFinished && insurance && insurance.type === "LEMONADE";

  const welcomeText =
    isFinished && insurance && insurance.type === "LEMONADE"
      ? "Thank You"
      : "Hi";
  // const showDownload = !isInApp && isLemonadeFinished;
  const showDownload = isLemonadeFinished;

  const insuranceText = getInsuranceText(user, insurance);

  return (
    <>
      {context && context.type === "in-app" ? (
        <LayoutHeader
          className={styles.header}
          showBackButton
          onBack={logout}
        />
      ) : null}
      <div className={styles.head}>
        <h1>
          {welcomeText}, {user.firstName}
        </h1>
      </div>
      <div className={styles.home}>
        {isLemonadeFinished && (
          <div className={styles.task}>
            <span>
              Lemonade will send you a confirmation email shortly with your
              policy details.
            </span>
            <hr className={styles.lineSmall} />
            <span>
              You can use the Lemonade app to make changes to your policy, ask
              questions or file claims.
            </span>
          </div>
        )}
        {showDownload && (
          <div className={styles.task}>
            <h3>Download the Lemonade App</h3>
            <div className={styles.badges}>
              <AppStoreBadge onClick={() => onDownloadClick("apple")} />
              <GooglePlayBadge onClick={() => onDownloadClick("google")} />
            </div>
          </div>
        )}
        <div className={styles.moveInInfo}>
          <hr className={styles.line} />
        </div>
        <div className={cx(styles.list)}>
          <InsuranceTask
            title="Renters insurance"
            description={insuranceText}
            isComplete={user.isInsuranceComplete}
            insurance={insurance}
            onClick={onInsuranceClick}
          />
        </div>
      </div>
    </>
  );
}

Home.displayName = "Home";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
    insurance: getInsurance(state),
  }),
  {
    fetchInsurance,
    fetchUser,
  }
)(Home);
