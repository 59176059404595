import { AnyAction } from "redux";
import { IFormState } from "./types";

import { SAVE_FORM, RESET_FORM } from "./form.actions";

const defaultState: IFormState = {};

export default function paymentsReducer(
  state: IFormState | undefined = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    case SAVE_FORM: {
      return {
        ...state,
        [action.payload.name]: action.payload.values,
      };
    }
    case RESET_FORM: {
      return {
        ...state,
        [action.payload.name]: undefined,
      };
    }
    default:
      return state;
  }
}
