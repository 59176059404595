import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import values from "lodash/values";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import Text from "../../../components/text";
import { SafeAreaContainer } from "../../../components/container";
import { setItem } from "../../../helpers/storage";
import {
  createQuote,
  CreateQuote,
} from "../../../resources/insurance/insurance.actions";
import DOBInput from "./dob";

import GTag from "../../../gtag";

import styles from "./lemonade.module.css";

interface IProps {
  createQuote: CreateQuote;
}

function Agreement({ createQuote }: IProps) {
  const [dob, setDOB] = React.useState<Date | undefined>();
  const history = useHistory();
  const [isAgree, setIsAgree] = React.useState(false);
  const [error, setError] = React.useState("");
  const [dobError, setDobError] = React.useState("");

  React.useEffect(() => {
    GTag.event("lemonade_open", "Page");
  });

  async function onClick() {
    if (!dob) {
      setDobError("Please check that the date format is valid.");
    } else {
      setDobError("");
    }
    if (!isAgree) {
      setError("Please check this box if you want to proceed.");
    } else {
      setError("");
    }

    if (!dob || !isAgree) {
      return;
    }

    GTag.event("lemonade_start", "Click");

    try {
      const quote = await createQuote({ dateOfBirth: dob });
      setItem("lemonade_payment_errors", "0");
      history.push(`/insurance/edit/${quote._id}`);
    } catch (err) {
      if (err.data) {
        const error = err.data.errors[0];
        const msg = error.lemonade ? error.lemonade : values(error)[0];
        const code = error.lemonadeCode;
        history.push("/insurance/error", { message: msg, code });
      } else {
        history.push("/insurance/error");
      }
    }
  }

  const onAgreeChange = () => {
    setIsAgree(!isAgree);
  };

  return (
    <SafeAreaContainer className={styles.container}>
      <Text bold className={styles.title}>
        We've partnered with Lemonade to provide you with quick and hassle free
        renters insurance.
      </Text>
      <Text bold className={styles.title}>
        To get you a quote we need some information from you. First up:
      </Text>
      <Text className={styles.dob}>Date of Birth</Text>
      <DOBInput onChange={setDOB} />
      {dobError && <div className={styles.error}>{dobError}</div>}
      <div className={styles.inputContainer}>
        <Checkbox
          data-id="agree"
          className={styles.checkbox}
          checkmarkClassName={styles.checkmark}
          name="authorization"
          LabelComponent={() => (
            <div className={styles.label}>
              I agree to the Lemonade{" "}
              <a
                href="https://www.lemonade.com/terms-of-service?show_header=false"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              .
            </div>
          )}
          checked={isAgree}
          onChange={onAgreeChange}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <Button
        className={styles.btnBlack}
        onClick={onClick}
        data-id="continueBtn"
      >
        Continue
      </Button>
    </SafeAreaContainer>
  );
}

Agreement.displayName = "Agreement";
export default connect(null, {
  createQuote,
})(Agreement);
