import * as React from "react";
import Header from "../../../components/header";
import { SafeAreaContainer } from "../../../components/container";

import { ReactComponent as ArrowDownIcon } from "../../../components/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../components/icons/arrow_up.svg";

import GTag from "../../../gtag";

import styles from "./info.module.css";

interface IQuestion {
  title: React.ReactChild;
  content: React.ReactChild;
  isOpen: boolean;
}

const QUESTIONS: IQuestion[] = [
  {
    title: (
      <span>What property does a Latch Renters Insurance policy cover?</span>
    ),
    content: (
      <div className={styles.content}>
        <div>
          Latch Renters Insurance policies generally cover 3 areas:
          <ul>
            <li>Your property (accidental damage or theft)</li>
            <li>
              Liability (bodily injury or damage to property of others you’re
              liable for)
            </li>
            <li>Additional living expenses (aka “Loss of Use”)</li>
          </ul>
          Your policy document will specify which perils are and aren’t covered,
          so make sure you review it to understand the coverage you have.
          Optional coverages can be added to the policy to cover specific
          valuables or add additional protections (optional coverages available
          vary by state).
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>What are some of the perils my policy covers?</span>,
    content: (
      <div className={styles.content}>
        <div>
          Accidental damage or loss caused by:
          <ul>
            <li>Fire or lightning</li>
            <li>Smoke</li>
            <li>Theft</li>
            <li>Vandalism</li>
            <li>Discharge or overflow of water or steam</li>
            <li>
              Wind or hail (some states have a seperate deductible for wind/hail
              damage)
            </li>
          </ul>
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: (
      <span>
        What does a policy <b>not</b> cover?
      </span>
    ),
    content: (
      <div className={styles.content}>
        <div>
          Your policy document will provide specifics on what isn’t covered but
          some notable areas are:
          <ul>
            <li>
              Earthquake damage (can be added in some states for an additional
              fee)
            </li>
            <li>Flood damage</li>
            <li>Unrelated roommate's property</li>
          </ul>
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>Who is covered under my policy?</span>,
    content: (
      <div className={styles.content}>
        <div>
          You plus anyone living in your household who is related to you by
          blood, marriage or adoption. Unrelated roommates are not covered and
          would need to purchase their own policy.
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>What determines my policy premium?</span>,
    content: (
      <div className={styles.content}>
        <div>
          Your premium is based on a variety of factors, including your
          deductible amount, coverage limits, and any optional coverages you
          choose to purchase. Information about your property - including age
          and construction quality - are also factored in, among other things.
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>What happens if I cancel my policy?</span>,
    content: (
      <div className={styles.content}>
        <div>
          You can cancel at any time. By law, most states require that you
          complete the current policy period, which is the time between the day
          you request cancellation and what would have been the next billing
          date.
        </div>
        <div className={styles.marginTop}>
          If your policy is in GA, MD, MI, MT, NY, or VA, your policy will be
          cancelled effective the following day and you’ll receive a pro-rated
          refund for the unused premium.
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>What happens to my policy if I move?</span>,
    content: (
      <div className={styles.content}>
        <div>
          If you move, we recommend closing your policy after you move out of
          your current residence and applying for a new policy under your new
          address.
        </div>
      </div>
    ),
    isOpen: false,
  },
  {
    title: <span>Who underwrites my Latch Renters Insurance policy?</span>,
    content: (
      <div className={styles.content}>
        <div>
          Latch Renters Insurance policies are underwritten Markel American
          Insurance Company, a US based Fortune 500 company with 70+ years in
          the insurance business.
        </div>
      </div>
    ),
    isOpen: false,
  },
];
function Faq() {
  const [questions, setQuesions] = React.useState<IQuestion[]>(QUESTIONS);

  React.useEffect(() => {
    GTag.event("faq", "Page");
  });

  const toggle = (q: IQuestion) => {
    setQuesions((prevQuestions) => {
      const prevQ = prevQuestions.find((x) => x.title === q.title);
      const index = prevQuestions.findIndex((x) => x.title === q.title);
      if (!prevQ) {
        return prevQuestions;
      }
      let newQuestion = prevQuestions;
      if (!prevQ.isOpen) {
        newQuestion = newQuestion.map((x) => ({ ...x, isOpen: false }));
      }
      return [
        ...newQuestion.slice(0, index),
        {
          ...prevQ,
          isOpen: !prevQ.isOpen,
        },
        ...newQuestion.slice(index + 1),
      ];
    });
  };

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.head}>FAQ</Header>
      </SafeAreaContainer>
      <div>
        {questions.map((q, index) => (
          <div
            key={index}
            className={styles.question}
            onClick={() => toggle(q)}
          >
            <div className={styles.row}>
              <div className={styles.title}>{q.title}</div>
              {q.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {q.isOpen && <div className={styles.text}>{q.content}</div>}
          </div>
        ))}
      </div>
    </>
  );
}

Faq.displayName = "FAQ";
export default Faq;
