import * as React from "react";
import { connect } from "react-redux";

import GlobalSpinner from "../components/spinner/global";
import {
  fetchQuoteBoost,
  FetchQuoteBoost,
} from "../resources/insurance/insurance.actions";
import { IBoostQuote } from "../resources/insurance/types";
import { getBoostQuote } from "../resources/insurance/insurance.selectors";
import { IStore } from "../resources/types";

interface InjectedProps {
  quote?: IBoostQuote;
}

interface HocProps {
  quoteFromState?: IBoostQuote;
  fetchQuoteBoost: FetchQuoteBoost;
}

const withConnectedBoostQuote = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  class Hoc extends React.Component<HocProps> {
    static displayName = `withConnectedBoostQuote(${BaseComponent.name})`;
    static readonly WrappedComponent = BaseComponent;

    componentDidMount() {
      if (!this.props.quoteFromState) {
        this.props.fetchQuoteBoost();
      }
    }

    render() {
      const { quoteFromState, fetchQuoteBoost, ...restProps } = this.props;

      if (!quoteFromState) {
        return <GlobalSpinner />;
      }

      return (
        <BaseComponent {...(restProps as BaseProps)} quote={quoteFromState} />
      );
    }
  }

  const ConnectedHoc = connect(
    (state: IStore) => ({
      quoteFromState: getBoostQuote(state),
    }),
    {
      fetchQuoteBoost,
    }
  )(Hoc);

  return ConnectedHoc;
};
export default withConnectedBoostQuote;
