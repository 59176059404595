import * as React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import { withStripe } from "../../components/credit-card/stripe";
import { IBoostPolicy } from "../../resources/insurance/types";
import { getPolicyByPaymentIntentId } from "../../resources/insurance/insurance.api";
import Spinner from "../../components/spinner";
import { messages } from "../../components/payment-methods/auth-payment";
import Button from "../../components/button";

import styles from "./payment-auth.module.css";

interface IProps extends RouteComponentProps {}

function PaymentAuth({ history }: IProps) {
  const stripe = useStripe();
  const { clientSecret } = useParams();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<undefined | string>();
  const [loading, setLoading] = React.useState(true);
  const [policy, setPolicy] = React.useState<IBoostPolicy>();
  const [message, setMessage] = React.useState(messages.default);

  React.useEffect(() => {
    (async () => {
      if (clientSecret && stripe) {
        try {
          const { paymentIntent } = await stripe.retrievePaymentIntent(
            clientSecret
          );

          if (paymentIntent && paymentIntent.status === "succeeded") {
            setMessage(messages.complete);
          } else if (
            paymentIntent &&
            paymentIntent.status === "requires_capture"
          ) {
            setMessage(messages.capture);
          } else if (paymentIntent && paymentIntent.id) {
            const p = await getPolicyByPaymentIntentId(paymentIntent.id);
            setPolicy(p);
          }
        } catch (err) {
          history.push("/404");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [clientSecret, stripe, history]);

  const initAuth = () => {
    setError(undefined);
    if (stripe && clientSecret && policy && policy.paymentMethod.id) {
      setMessage(messages.default);
      setIsSubmitting(true);

      setTimeout(authPayment, 2000);
    }
  };

  const authPayment = async () => {
    if (stripe && policy && clientSecret) {
      setMessage(messages.progress);
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: policy.paymentMethod.id
        }
      );

      if (confirmError) {
        setMessage(messages.fail);
        setError(confirmError.message);
      } else {
        setMessage(messages.complete);
      }

      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, clientSecret, policy]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      {policy && (
        <div className={styles.title}>
          Please complete payment for your policy
        </div>
      )}
      <div className={styles.description}>{message}</div>
      <div className={styles.error}>{error || ""}</div>
      <div>
        {error && (
          <div className={styles.buttons}>
            <Button
              onClick={initAuth}
              className={styles.submitButton}
              type="button"
              disabled={isSubmitting}
            >
              Try again
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default withStripe(withRouter(PaymentAuth));
