export default {
  url: process.env.REACT_APP_URL || "http://localhost:3002",
  domain: process.env.REACT_APP_DOMAIN || "localhost:3002",
  publicUrl: process.env.PUBLIC_URL,
  hummingbirdUrl:
    process.env.REACT_APP_HUMMINGBIRD_URL || "http://localhost:3000",
  apiUrl: process.env.REACT_APP_LEMONADE_API_URL || "http://localhost:5000",
  apiBasePath: process.env.REACT_APP_LEMONADE_BASE_PATH || "",
  umbrellaApiUrl:
    process.env.REACT_APP_UMBRELLA_API_URL || "http://localhost:4000",
  umbrellaBasePath: process.env.REACT_APP_UMBRELLA_BASE_PATH || "",
  chatLink: "latchapp://business-chat",
  returnLink: process.env.REACT_APP_RETURN_LINK || "latchapp://exit-services",
  supportEmail: "insurance@latch.com",
  plaid: {
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY || "",
    appName: process.env.REACT_APP_PLAID_CLIENT_NAME || "Latchable, Inc.",
    env: process.env.REACT_APP_PLAID_ENV || "sandbox",
  },
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || "G-Y72MR83Z8B",
  stripeLemonade: {
    key: process.env.REACT_APP_LEMONADE_STRIPE_KEY || "",
    libUrl: process.env.REACT_APP_LEMONADE_STRIPE_LIB_URL || "",
  },
  stripe: {
    key: process.env.REACT_APP_STRIPE_KEY || "",
    libUrl: process.env.REACT_APP_STRIPE_LIB_URL || "",
  },
  auth0: {
    domain:
      process.env.REACT_APP_AUTH0_DOMAIN || "insurance-service.us.auth0.com",
    clientId:
      process.env.REACT_APP_AUTH0_CLIENT_ID ||
      "HRQaScDluYSkewJDhu81nTVg9KQf7W4Z",
    audience:
      process.env.REACT_APP_AUTH0_AUDIENCE ||
      "https://lemonade-dev.latchtime.com/",
    namespace: process.env.REACT_APP_AUTH0_NAMESPACE || "https://latchtime.com",
  },
  heapId: process.env.REACT_APP_HEAP_ID || "709472028",
  defaultTimezone: "America/New_York",
} as const;
