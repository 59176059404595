import { connect } from "react-redux";

import { showModal, hideModal, removeModal } from "./action";
import BaseModalProviderComponent from "./baseModalProvider";

import { IStore } from "../../resources/types";

export default connect(
  ({ modalProvider }: IStore) => ({
    modalProvider
  }),
  {
    showModal,
    hideModal,
    removeModal
  }
)(BaseModalProviderComponent as any);
