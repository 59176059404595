import * as React from "react";
import cx from "classnames";
import { Formik, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../components/action-button";
import Input from "../../components/input";
import Header from "../../components/header";
import Text from "../../components/text";
import { getUser } from "../../resources/user/user.selectors";
import { IUser } from "../../resources/user/types";
import { formatZip, formatPhone } from "../../helpers/common";
import { getItem } from "../../helpers/storage";

import GTag from "../../gtag";

import { IStore } from "../../resources/types";
import {
  confirmInformation,
  ConfirmInformation,
  fetchUser,
  FetchUser,
} from "../../resources/user/user.actions";

import schema, { IFormValues } from "./validation";

import styles from "./confirm.module.css";

interface IProps {
  user: IUser;
  confirmInformation: ConfirmInformation;
  fetchUser: FetchUser;
}

function ConfirmInformationModal({
  user,
  confirmInformation,
  fetchUser,
}: IProps) {
  const history = useHistory();

  const [editable, setEditable] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: !user.phone,
    unit: !user.unit,
  });

  React.useEffect(() => {
    fetchUser({ skipLoading: !!user });

    GTag.event("confirm", "Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUser]);

  const getDescription = (): string => {
    const hasPhone = !!user.phone;
    const hasUnit = !!user.unit;
    if (!hasUnit && !hasPhone) {
      return "Please enter your unit and phone number.";
    }
    if (!hasUnit) {
      return "Please enter your unit number.";
    }
    if (!hasPhone) {
      return "Please enter your phone number.";
    }
    return "";
  };

  const editField = (field: string) => {
    setEditable({
      ...editable,
      [field]: true,
    });
  };

  const description = getDescription();

  async function onClickSubmit(formValues: IFormValues) {
    GTag.event("confirmed_information", "Click");

    await confirmInformation(formValues);
    fetchUser();

    const url = getItem("CONFIRM_NEXT_URL") || "/";
    history.push(url);
  }

  return (
    <div className={styles.container}>
      <Header
        singleLine
        hasDescription={!!description}
        className={styles.header}
      >
        Your information
      </Header>
      {description && <Text className={styles.description}>{description}</Text>}

      <hr className={styles.line} />

      <Formik<IFormValues>
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          unit: user.unit,
          email: user.email,
        }}
        validateOnBlur
        validateOnChange={false}
        validationSchema={schema}
        enableReinitialize
        onSubmit={onClickSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
        }: FormikProps<IFormValues>) => (
          <form onSubmit={handleSubmit} className={cx(styles.form)}>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>First Name</span>
                  {!editable.firstName && (
                    <button
                      data-id="editFirstNameBtn"
                      onClick={() => editField("firstName")}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {!editable.firstName && (
                  <div data-id="firstNameText" className={styles.text}>
                    {user.firstName}
                  </div>
                )}
                {editable.firstName && (
                  <Input
                    data-id="firstNameInput"
                    name="firstName"
                    value={values.firstName}
                    className={cx(styles.input, {
                      [styles.error]: touched.firstName && errors.firstName,
                    })}
                    placeholder="Enter First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                {errors.firstName && (
                  <div data-id="firstNameError" className={styles.errorMessage}>
                    {touched.firstName && errors.firstName}
                  </div>
                )}
              </div>

              <hr className={styles.line} />

              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Last Name</span>
                  {!editable.lastName && (
                    <button
                      data-id="editLastNameBtn"
                      onClick={() => editField("lastName")}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {!editable.lastName && (
                  <div data-id="lastNameText" className={styles.text}>
                    {user.lastName}
                  </div>
                )}
                {editable.lastName && (
                  <Input
                    data-id="lastNameInput"
                    name="lastName"
                    value={values.lastName}
                    className={cx(styles.input, {
                      [styles.error]: touched.lastName && errors.lastName,
                    })}
                    placeholder="Enter Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                {errors.lastName && (
                  <div data-id="lastNameError" className={styles.errorMessage}>
                    {touched.lastName && errors.lastName}
                  </div>
                )}
              </div>

              <hr className={styles.line} />

              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Email</span>
                  {!editable.email && (
                    <button
                      data-id="editEmailBtn"
                      onClick={() => editField("email")}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {!editable.email && (
                  <div data-id="emailText" className={styles.text}>
                    {values.email}
                  </div>
                )}
                {editable.email && (
                  <Input
                    data-id="emailInput"
                    name="email"
                    value={values.email}
                    className={cx(styles.input, {
                      [styles.error]: touched.email && errors.email,
                    })}
                    placeholder="Enter Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                <div className={styles.inputDescription}>
                  Email changes are only used for your insurance policy. They
                  will not change your Latch account email address.
                </div>
                {errors.email && (
                  <div data-id="emailError" className={styles.errorMessage}>
                    {touched.email && errors.email}
                  </div>
                )}
              </div>

              <hr className={styles.line} />

              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Phone</span>
                  {!editable.phone && (
                    <button
                      data-id="editPhoneBtn"
                      onClick={() => editField("phone")}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {!editable.phone && (
                  <div data-id="phoneText" className={styles.text}>
                    {formatPhone(user.phone)}
                  </div>
                )}
                {editable.phone && (
                  <Input
                    data-id="phoneInput"
                    type="tel"
                    className={cx(styles.input, {
                      [styles.error]: touched.phone && errors.phone,
                    })}
                    placeholder="123 456-7890"
                    name="phone"
                    mask="(999) 999-9999"
                    formatChars={{
                      "9": "[0-9]",
                      "*": "",
                    }}
                    autoComplete="off"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                {errors.phone && (
                  <div data-id="phoneError" className={styles.errorMessage}>
                    {touched.phone && errors.phone}
                  </div>
                )}
              </div>

              <hr className={styles.line} />

              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Apartment / Unit #</span>
                  {!editable.unit && (
                    <button
                      data-id="editUnitBtn"
                      onClick={() => editField("unit")}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {!editable.unit && (
                  <div data-id="unitText" className={styles.text}>
                    {user.unit}
                  </div>
                )}
                {editable.unit && (
                  <Input
                    data-id="unitInput"
                    name="unit"
                    value={values.unit}
                    className={cx(styles.input, {
                      [styles.error]: touched.unit && errors.unit,
                    })}
                    placeholder="Enter apartment / unit #"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>

              <hr className={styles.line} />

              <div className={styles.row}>
                <div className={styles.title}>Address</div>
                <div className={styles.text}>
                  <div data-id="addressText">{user.address}</div>
                  <div data-id="cityStateZipText">
                    {user.city}, {user.state}, {formatZip(user.zip)}
                  </div>
                </div>
              </div>

              <hr className={styles.line} />
            </div>

            <Button data-id="submit-info" type="submit">
              {!!user.phone && !!user.unit ? "Confirm information" : "Save"}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
}

ConfirmInformationModal.displayName = "ConfirmInformation";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {
    confirmInformation,
    fetchUser,
  }
)(ConfirmInformationModal);
