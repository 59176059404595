import { IStore } from "../types";
import { IUser, IPaymentMethod } from "./types";

const EMPTY_OBJECT: any = {};

export function getUser({ user }: IStore): IUser {
  return user ? user.user : EMPTY_OBJECT;
}

export function getUserPhoneNumber({ user }: IStore): string {
  return user ? user.user.phone : "";
}

export function getPaymentMethods({ user }: IStore): IPaymentMethod[] {
  return user ? user.paymentMethods : [];
}

export function getDefaultPaymentMethod({ user }: IStore): string {
  return user && user.user.defaultPaymentMethod
    ? user.user.defaultPaymentMethod
    : "";
}
