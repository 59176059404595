import config from "./config";

const GTag = {
  init: (data?: { userId: string }) => {
    window.gtag("config", config.gaTrackingId, data);
  },

  pageview: (url: string) => {
    window.gtag("config", config.gaTrackingId, {
      page_path: url
    });
  },

  event: (
    action: string,
    category?: string,
    value?: string,
    label?: string
  ) => {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value,
      send_to: config.gaTrackingId
    });
  }
};

export default GTag;
