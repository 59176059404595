import * as React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import { showModal } from "../../../../modules/modalProvider";
import {
  updateQuote,
  UpdateQuote,
} from "../../../../resources/insurance/insurance.actions";
import { IQuote } from "../../../../resources/insurance/types";

import withConnectedQuote from "../../../../hoc/quote.hoc";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Price from "../../components/pricing";
import Button from "../../../../components/action-button";
import CoverageInput from "../../components/coverage";

import styles from "./coverage.module.css";

type CoverageType =
  | "dwelling"
  | "personalProperty"
  | "personalLiability"
  | "lossOfUse"
  | "medicalPaymentsToOthers";
interface ICoverage {
  key: CoverageType;
  title: string;
  description: string;
}

const COVERAGES: ICoverage[] = [
  {
    key: "personalProperty",
    title: "Personal Property",
    description:
      "The total value of the stuff you own, such as electronics, clothing, furniture, etc.",
  },
  {
    key: "dwelling",
    title: "Dwelling",
    description:
      "Items like your phones, tablets, laptops and other portable electronics require seperate coverage. This is the max we'll pay in case of theft or covered damages to your electronics.",
  },
  {
    key: "medicalPaymentsToOthers",
    title: "Medical Payments to Others",
    description:
      "If someone gets hurt on your property and decides to take legal action, this is the max amount we'll pay to cover damages you're legally required to pay.",
  },
  {
    key: "lossOfUse",
    title: "Loss of Use",
    description:
      "We hope this never happens, but if your place is unlivable, we'll put you up in a nice hotel or similar rental home. We'll also include living expenses to get you back on your feet. This is the max that we'll pay in such an event.",
  },
  {
    key: "personalLiability",
    title: "Personal Liability",
    description:
      "We'll cover medical expenses up to this amount if a guest or neighbor is injured on your property.",
  },
];

interface IProps {
  quote: IQuote;
  updateQuote: UpdateQuote;
}

function Coverage({ updateQuote, quote }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [value, setValue] = React.useState({
    personalProperty: quote.coverages.personalProperty
      ? quote.coverages.personalProperty.value
      : 0,
    dwelling: quote.coverages.dwelling ? quote.coverages.dwelling.value : 0,
    medicalPaymentsToOthers: quote.coverages.medicalPaymentsToOthers
      ? quote.coverages.medicalPaymentsToOthers.value
      : 0,
    lossOfUse: quote.coverages.lossOfUse ? quote.coverages.lossOfUse.value : 0,
    personalLiability: quote.coverages.personalLiability
      ? quote.coverages.personalLiability.value
      : 0,
  });
  const [originalValue, setOriginalValue] = React.useState({
    personalProperty: quote.coverages.personalProperty
      ? quote.coverages.personalProperty.value
      : 0,
    dwelling: quote.coverages.dwelling ? quote.coverages.dwelling.value : 0,
    medicalPaymentsToOthers: quote.coverages.medicalPaymentsToOthers
      ? quote.coverages.medicalPaymentsToOthers.value
      : 0,
    lossOfUse: quote.coverages.lossOfUse ? quote.coverages.lossOfUse.value : 0,
    personalLiability: quote.coverages.personalLiability
      ? quote.coverages.personalLiability.value
      : 0,
  });
  const [serverError, setServerError] = React.useState("");

  async function onSubmit() {
    setServerError("");
    if (!value) {
      return;
    }
    setOriginalValue(value);
    history.push(`/insurance/edit/${id}`);
  }

  function setCoverage(coverage: CoverageType) {
    return async (newValue: number) => {
      setServerError("");
      const prevValue = value;
      try {
        setValue((v) => v && { ...v, [coverage]: newValue });
        await updateQuote({ [coverage]: newValue });
      } catch (err) {
        setValue(prevValue);
        if (err.data && err.data.errors) {
          setServerError(values(err.data.errors[0])[0]);
        }
      }
    };
  }

  const openRevert = () => {
    if (originalValue && value && !isEqual(value, originalValue)) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (originalValue && value && !isEqual(value, originalValue)) {
        const keys: CoverageType[] = [
          "personalProperty",
          "personalLiability",
          "lossOfUse",
          "medicalPaymentsToOthers",
          "dwelling",
        ];
        for (let i = 0; i < keys.length; i += 1) {
          if (value[keys[i]] !== originalValue[keys[i]]) {
            // eslint-disable-next-line no-await-in-loop
            await updateQuote({ [keys[i]]: originalValue[keys[i]] });
          }
        }
      }
      history.goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const items = COVERAGES.map((item) => {
    if (!quote.coverages[item.key]) {
      return null;
    }
    return (
      <CoverageInput
        data-id={item.key}
        cents
        key={item.key}
        title={item.title}
        description={item.description}
        value={value ? value[item.key] : 0}
        options={
          quote.coverages[item.key]
            ? quote.coverages[item.key]?.options || []
            : []
        }
        onChange={setCoverage(item.key)}
        readonly={quote.status === "PAID"}
      />
    );
  });

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <SafeAreaContainer className={styles.head}>
          <Header>Coverage amounts</Header>
        </SafeAreaContainer>
        <hr className={styles.hr} />
        <SafeAreaContainer>
          <Price amount={quote.monthlyPremiumInCents} period="month" />
          <div>{items}</div>
          <div className={styles.errorBlock}>{serverError}</div>
        </SafeAreaContainer>
        <Button onClick={onSubmit} data-id="setCoverageBtn">
          Set coverage
        </Button>
      </Container>
    </>
  );
}

Coverage.displayName = "Coverage";
export default withConnectedQuote(
  connect(() => ({}), {
    updateQuote,
  })(Coverage)
);
