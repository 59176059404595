import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import GlobalSpinner from "../../../components/spinner/global";
import {
  fetchQuote,
  FetchQuote,
} from "../../../resources/insurance/insurance.actions";

interface IProps {
  fetchQuote: FetchQuote;
}

function LemonadeHome({ fetchQuote }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    fetchQuote()
      .then((response) => {
        if (response && response._id) {
          history.replace(`/insurance/edit/${response._id}`);
        } else {
          history.replace("/insurance/lemonade/start");
        }
      })
      .catch(() => {
        history.replace("/insurance/lemonade/start");
      });
  });
  return <GlobalSpinner />;
}

LemonadeHome.displayName = "LemonadeHome";
export default connect(() => ({}), {
  fetchQuote,
})(LemonadeHome);
