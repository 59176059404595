import * as React from "react";
import cx from "classnames";
import moment from "moment-timezone";
import { Formik, FormikProps } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../../components/button";
import UploadFiles from "../../../../components/upload-files";
import Text from "../../../../components/text";
import Spinner from "../../../../components/spinner";
import Header from "../../../../components/header";
import DateComponent from "../../../../components/date/inline";
import { getBoostClaim } from "../../../../resources/insurance/insurance.selectors";
import { formatPhone } from "../../../../helpers/common";
import mimeTypes from "../../../../helpers/mimeTypes";

import GTag from "../../../../gtag";

import { IStore } from "../../../../resources/types";
import { IBoostClaim } from "../../../../resources/insurance/types";
import {
  addClaimDocuments,
  AddClaimDocuments,
  fetchClaimBoost,
  FetchClaimBoost,
} from "../../../../resources/insurance/insurance.actions";

import schema, { IFormValues } from "./claim-details.validation";

import styles from "./claim-details.module.css";

interface IProps {
  claim?: IBoostClaim;
  addClaimDocuments: AddClaimDocuments;
  fetchClaimBoost: FetchClaimBoost;
}

function ClaimDetails({ claim, addClaimDocuments, fetchClaimBoost }: IProps) {
  const history = useHistory();
  const { id, claimId } = useParams();

  const [isFetched, setIsFetched] = React.useState(false);

  React.useEffect(() => {
    GTag.event("claim_details", "Page");
  }, []);

  React.useEffect(() => {
    fetchClaimBoost({ policyId: id, claimId });
    setIsFetched(true);
  }, [fetchClaimBoost, id, claimId]);

  const downloadDocument = (url: string) => {
    window.open(url);
  };

  const onFaqClick = () => {
    history.push(`/insurance/boost/policy/${id}/claims/faq`);
  };

  async function onClickSubmit(formValues: IFormValues) {
    if (formValues.isDamaged !== undefined) {
      return;
    }

    GTag.event("submit_claim", "Click");

    await addClaimDocuments({
      ...formValues,
      policyId: id,
      claimId,
    });

    await fetchClaimBoost({ policyId: id, claimId });
  }

  if (!claim || !isFetched) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <Header className={styles.head}>
        Review <br /> claim
      </Header>

      <Formik<IFormValues>
        initialValues={{}}
        validateOnBlur
        validateOnChange={false}
        validationSchema={schema}
        enableReinitialize
        onSubmit={onClickSubmit}
      >
        {({
          handleSubmit,
          setFieldValue,
          setTouched,
          setValues,
          touched,
          errors,
          values,
        }: FormikProps<IFormValues>) => {
          return (
            <form onSubmit={handleSubmit} className={cx(styles.form)}>
              <div className={styles.content}>
                <div className={cx(styles.row, styles.panel)}>
                  <DateComponent
                    className={styles.date}
                    labelClassName={styles.dateLabel}
                    startDate={moment
                      .utc()
                      .subtract(1, "month")
                      .startOf("day")
                      .toDate()}
                    endDate={moment.utc().startOf("day").toDate()}
                    date={claim.date}
                    title="Date of loss"
                    readonly
                  />
                </div>

                <div className={styles.row}>
                  <div className={styles.textarea}>{claim.description}</div>
                </div>
                <div className={styles.files}>
                  {claim.documents
                    ? claim.documents.map((file, idx) => (
                        <div
                          key={`${file.name}_${idx}`}
                          className={cx(styles.upload, styles.done)}
                          onClick={() => downloadDocument(file.url)}
                        >
                          <Text
                            bold
                            className={cx(
                              styles.fileName,
                              styles.uploaded,
                              styles.done
                            )}
                          >
                            {file.name}
                          </Text>
                        </div>
                      ))
                    : null}
                </div>
                {claim.status !== "CLOSED" && (
                  <div className={styles.uploadConatiner}>
                    <UploadFiles
                      accept={[
                        ...mimeTypes.image,
                        ...mimeTypes.pdf,
                        ...mimeTypes.excel,
                        ...mimeTypes.csv,
                      ].join(",")}
                      text="Drag or click to add an image and document"
                      setIsDamanged={(d: boolean) =>
                        setFieldValue("isDamaged", d)
                      }
                      files={values.files || []}
                      allowedFileTypes={["image", "pdf", "csv", "excel"]}
                      rejectedFileTypes={["gif"]}
                      errorsFiles={errors.files}
                      touchedFiles={touched.files}
                      setTouched={() =>
                        setTouched({
                          files: true,
                          isDamaged: true,
                        })
                      }
                      setFiles={(files: File[]) => {
                        setValues({
                          ...values,
                          files,
                        });
                      }}
                    />
                  </div>
                )}

                {values.files &&
                  values.files.length > 0 &&
                  claim.status !== "CLOSED" && (
                    <Button className={styles.btnBlack} type="submit">
                      Upload files
                    </Button>
                  )}

                <hr className={styles.line} />

                <div className={styles.sectionTitle}>
                  Your contact information
                </div>

                <div className={cx(styles.row, styles.contactRow)}>
                  <div className={styles.text}>{formatPhone(claim.phone)}</div>
                </div>
                <div className={cx(styles.row, styles.contactRow)}>
                  <div className={styles.text}>{claim.email}</div>
                </div>
              </div>
              <div className={styles.linkBtn}>
                <button onClick={onFaqClick}>Tap here to view FAQ</button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

ClaimDetails.displayName = "ClaimDetails";
export default connect(
  (state: IStore) => ({
    claim: getBoostClaim(state),
  }),
  {
    addClaimDocuments,
    fetchClaimBoost,
  }
)(ClaimDetails);
