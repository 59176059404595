import * as React from "react";
import cx from "classnames";
import { Formik, FormikProps } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { showModal } from "../../../../modules/modalProvider";
import Confirm from "../../../../components/confirm";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import Header from "../../../../components/header";
import Select from "../../../../components/select";
import Spinner from "../../../../components/spinner";
import { getUser } from "../../../../resources/user/user.selectors";
import { IUser } from "../../../../resources/user/types";
import { statesList } from "../../../../helpers/state";

import GTag from "../../../../gtag";

import { IStore } from "../../../../resources/types";
import { fetchUser, FetchUser } from "../../../../resources/user/user.actions";
import {
  AddAdditionalInterested,
  addAdditionalInterested,
  UpdateAdditionalInterested,
  updateAdditionalInterested,
  FetchAdditionalInterested,
  fetchAdditionalInterested,
  removeAdditionalInterested,
  RemoveAdditionalInterested,
} from "../../../../resources/insurance/insurance.actions";
import { IAdditionalInterested } from "../../../../resources/insurance/types";

import schema, { IFormValues } from "./validation";

import styles from "./edit-additional-interested.module.css";

interface IProps {
  user: IUser;
  fetchUser: FetchUser;
  addAdditionalInterested: AddAdditionalInterested;
  updateAdditionalInterested: UpdateAdditionalInterested;
  fetchAdditionalInterested: FetchAdditionalInterested;
  removeAdditionalInterested: RemoveAdditionalInterested;
}

function EditAdditionalInterested({
  user,
  fetchUser,
  addAdditionalInterested,
  fetchAdditionalInterested,
  updateAdditionalInterested,
  removeAdditionalInterested,
}: IProps) {
  const history = useHistory();
  const { id, partyId } = useParams();
  const [party, setParty] = React.useState<IAdditionalInterested | null>(null);

  const isPolicy = history.location.pathname.includes("boost/policy");

  const isNew = !partyId;

  React.useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user, fetchUser]);

  React.useEffect(() => {
    if (isNew) {
      return;
    }
    fetchAdditionalInterested({ entityId: id, id: partyId, isPolicy }).then(
      (party) => {
        setParty(party);
      }
    );
  }, [fetchAdditionalInterested, setParty, partyId, id, isNew, isPolicy]);

  const download = () => {
    if (!party || !party.url) {
      return;
    }
    window.open(party.url);
  };

  const openDeleteParty = () => {
    if (!party) {
      return;
    }
    showModal(
      <Confirm
        action={async () => {
          GTag.event("delete_additional_interested", "Click");
          await removeAdditionalInterested({
            entityId: id,
            id: party._id,
            isPolicy,
          });
          history.goBack();
        }}
        title="Are you sure to remove additional interested from the list?"
        yes="Yes"
        no="No"
      />,
      undefined
    );
  };

  async function onClickSubmit(formValues: IFormValues) {
    GTag.event("save_additional_interested", "Click");

    if (isNew) {
      await addAdditionalInterested({
        ...formValues,
        entityId: id,
        isPolicy,
      });
    } else {
      await updateAdditionalInterested({
        ...formValues,
        entityId: id,
        isPolicy,
        id: partyId,
      });
    }
    history.goBack();
  }
  if (!isNew && !party) {
    return <Spinner />;
  }

  return (
    <>
      <Header className={styles.head}>
        {isNew ? "Add" : "Edit"} additional
        <br /> interested
      </Header>

      <Formik<IFormValues>
        initialValues={{
          type: party?.type || "business",
          name: party ? `${party.firstName} ${party.lastName}` : "",
          businessName: party?.businessName || "",
          address1: party?.address1 || "",
          address2: party?.address2 || "",
          city: party?.city || "",
          state: party?.state || "",
          zip: party?.zip || "",
          email: party?.email || "",
        }}
        validateOnBlur
        validateOnChange={false}
        validationSchema={schema}
        enableReinitialize
        onSubmit={onClickSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
          values,
        }: FormikProps<IFormValues>) => (
          <form onSubmit={handleSubmit} className={cx(styles.form)}>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.buttons}>
                  <button
                    type="button"
                    className={cx(
                      styles.btnType,
                      values.type === "individual" ? styles.typeSelected : null
                    )}
                    data-id="individualBtn"
                    onClick={() => {
                      setFieldValue("type", "individual");
                      setFieldValue("businessName", "");
                    }}
                  >
                    Individual
                  </button>
                  <button
                    type="button"
                    className={cx(
                      styles.btnType,
                      values.type === "business" ? styles.typeSelected : null
                    )}
                    data-id="businessBtn"
                    onClick={() => setFieldValue("type", "business")}
                  >
                    Business
                  </button>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Name</span>
                </div>
                <Input
                  data-id="nameInput"
                  name="name"
                  value={values.name}
                  className={cx(styles.input, {
                    [styles.error]: touched.name && errors.name,
                  })}
                  placeholder="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && (
                  <div data-id="nameError" className={styles.errorMessage}>
                    {touched.name && errors.name}
                  </div>
                )}
              </div>
              {values.type === "business" && (
                <div className={styles.row}>
                  <div className={styles.title}>
                    <span>Business name</span>
                  </div>
                  <Input
                    data-id="businessNameInput"
                    name="businessName"
                    value={values.businessName}
                    className={cx(styles.input, {
                      [styles.error]:
                        touched.businessName && errors.businessName,
                    })}
                    placeholder="Business name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.businessName && (
                    <div
                      data-id="businessNameError"
                      className={styles.errorMessage}
                    >
                      {touched.businessName && errors.businessName}
                    </div>
                  )}
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Email</span>
                </div>
                <Input
                  data-id="emailInput"
                  name="email"
                  value={values.email}
                  className={cx(styles.input, {
                    [styles.error]: touched.email && errors.email,
                  })}
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && (
                  <div data-id="emailError" className={styles.errorMessage}>
                    {touched.email && errors.email}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Address</span>
                </div>
                <Input
                  data-id="address1Input"
                  name="address1"
                  value={values.address1}
                  className={cx(styles.input, {
                    [styles.error]: touched.address1 && errors.address1,
                  })}
                  placeholder="Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address1 && (
                  <div data-id="address1Error" className={styles.errorMessage}>
                    {touched.address1 && errors.address1}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>Apartment / Unit #</span>
                </div>
                <Input
                  data-id="address2Input"
                  name="address2"
                  value={values.address2}
                  className={cx(styles.input, {
                    [styles.error]: touched.address2 && errors.address2,
                  })}
                  placeholder="Apartment / Unit #"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address2 && (
                  <div data-id="address2Error" className={styles.errorMessage}>
                    {touched.address2 && errors.address2}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.title}>
                  <span>City</span>
                </div>
                <Input
                  data-id="cityInput"
                  name="city"
                  value={values.city}
                  className={cx(styles.input, {
                    [styles.error]: touched.city && errors.city,
                  })}
                  placeholder="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.city && (
                  <div data-id="cityError" className={styles.errorMessage}>
                    {touched.city && errors.city}
                  </div>
                )}
              </div>
              <div className={styles.singleRow}>
                <div className={styles.row}>
                  <div className={styles.title}>
                    <span>State</span>
                  </div>
                  <Select
                    data-id="stateSeleect"
                    required
                    name="state"
                    value={values.state}
                    className={cx(styles.select, {
                      [styles.error]: touched.state && errors.state,
                    })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option disabled value="">
                      State
                    </option>
                    {statesList.map((s: { key: string; value: string }) => (
                      <option key={s.key} value={s.key}>
                        {s.value}
                      </option>
                    ))}
                  </Select>
                  {errors.state && (
                    <div data-id="stateError" className={styles.errorMessage}>
                      {touched.state && errors.state}
                    </div>
                  )}
                </div>
                <div className={styles.row}>
                  <div className={styles.title}>
                    <span>Postal code</span>
                  </div>
                  <Input
                    data-id="zipInput"
                    type="text"
                    autoComplete="off"
                    mask="99999-9999"
                    name="zip"
                    value={values.zip}
                    className={cx(styles.input, {
                      [styles.error]: touched.zip && errors.zip,
                    })}
                    placeholder="Postal code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.zip && (
                    <div data-id="zipError" className={styles.errorMessage}>
                      {touched.zip && errors.zip}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {party?.url && (
              <button type="button" className={styles.link} onClick={download}>
                Get Certificate of Insurance
              </button>
            )}

            <Button
              data-id="submitBtn"
              className={styles.btnBlack}
              type="submit"
            >
              {isNew ? "Add" : "Update"}
            </Button>

            {!isNew && (
              <Button
                data-id="removeBtn"
                className={styles.btnRed}
                type="button"
                onClick={openDeleteParty}
              >
                Remove
              </Button>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}

EditAdditionalInterested.displayName = "EditAdditionalInterested";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {
    fetchUser,
    addAdditionalInterested,
    updateAdditionalInterested,
    fetchAdditionalInterested,
    removeAdditionalInterested,
  }
)(EditAdditionalInterested);
