import * as React from "react";
import { useLocation } from "react-router-dom";
import ChatButton from "../../../../components/chat-button";
import Text from "../../../../components/text";
import { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";

import styles from "./error.module.css";

function GeneralError() {
  const location = useLocation();

  let msg: React.ReactNode = null;
  if (location.state && location.state.message) {
    msg = (
      <Text className={styles.description}>
        Lemonade Error: {location.state.message}
      </Text>
    );
  }

  return (
    <SafeAreaContainer>
      <Header className={styles.title}>Hang tight</Header>
      <Text className={styles.description}>
        There’s an issue we need to work out.
      </Text>
      {msg}
      <ChatButton className={styles.chatButton} />
    </SafeAreaContainer>
  );
}

GeneralError.displayName = "GeneralError";
export default GeneralError;
