import React from "react";
import { ActionType } from "./action";
import { IStackItem } from "./types";

interface IAction {
  type: string;
  id?: string;
  show: boolean;
  delayBeforeRemoveMsec: number;
  dependant: boolean;
  component: React.ReactElement;
}

interface IModalProviderState {
  stack: IStackItem[];
}

const reducer = (
  state: IModalProviderState = {
    stack: []
  },
  action: IAction
) => {
  let targetModal: IStackItem | undefined;
  switch (action.type) {
    case ActionType.SHOW:
      targetModal = state.stack.find(
        (modal: IStackItem) => modal.id === action.id
      );

      if (!targetModal) {
        return {
          ...state,
          stack: [
            ...state.stack,
            {
              id: action.id || new Date().valueOf().toString(),
              component: action.component,
              show: true,
              dependant: action.dependant,
              delayBeforeRemoveMsec: action.delayBeforeRemoveMsec
            }
          ]
        };
      }

      return {
        ...state,
        stack: [
          ...state.stack.filter(
            modal =>
              state.stack.indexOf(modal) <
              state.stack.indexOf(targetModal as IStackItem)
          ),
          {
            ...targetModal,
            component: action.component,
            show: true,
            dependant: action.dependant,
            delayBeforeRemoveMsec: action.delayBeforeRemoveMsec
          },
          ...state.stack.filter(
            modal =>
              state.stack.indexOf(modal) >
              state.stack.indexOf(targetModal as IStackItem)
          )
        ]
      };

    case ActionType.HIDE:
      targetModal = state.stack.find(
        (modal: IStackItem) => modal.id === action.id
      );

      if (!targetModal) {
        return state;
      }

      return {
        ...state,
        stack: [
          ...state.stack.filter(
            modal =>
              state.stack.indexOf(modal) <
              state.stack.indexOf(targetModal as IStackItem)
          ),
          {
            ...targetModal,
            show: false
          },
          ...state.stack.filter(
            modal =>
              state.stack.indexOf(modal) >
              state.stack.indexOf(targetModal as IStackItem)
          )
        ]
      };

    case ActionType.REMOVE:
      return {
        ...state,
        stack: state.stack.filter(
          modal => modal.id !== action.id && !modal.dependant
        )
      };

    default:
      return state;
  }
};

export default reducer;
