import * as React from "react";
import cx from "classnames";

import styles from "./header.module.css";

interface IProps {
  className?: string;
  singleLine?: boolean;
  hasDescription?: boolean;
  children: React.ReactChild | React.ReactChild[];
}

export default function Header({
  className,
  singleLine,
  hasDescription,
  children,
}: IProps) {
  return (
    <div
      className={cx(
        styles.header,
        className,
        singleLine && !hasDescription ? styles.singleLine : null
      )}
    >
      {children}
    </div>
  );
}
Header.displayName = "Header";
