import { AnyAction } from "redux";
import { IPaymentState } from "./types";

import { FETCH_PAYMENTS } from "./payment.actions";

const defaultState: IPaymentState = {
  payments: []
};

export default function paymentsReducer(
  state: IPaymentState | undefined = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_PAYMENTS: {
      return {
        ...state,
        payments: action.payload
      };
    }
    default:
      return state;
  }
}
