import * as React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import Header from "./header";
import Spinner from "../../components/spinner";
import Container from "../../components/container";
import { IStore } from "../../resources/types";

import styles from "./layout.module.css";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  layoutClass?: string;
  headerClass?: string;
  loading?: boolean;
  showBackButton?: boolean;
  isBlackButton?: boolean;
  isCustomHeader?: boolean;
}

function Layout({
  children,
  loading,
  layoutClass,
  headerClass,
  showBackButton = true,
  isBlackButton,
  isCustomHeader,
}: IProps) {
  if (isCustomHeader) {
    return (
      <div id="layout">
        {children}
        {loading && <Spinner />}
      </div>
    );
  }

  return (
    <div id="layout">
      {showBackButton && (
        <Header
          className={headerClass}
          showBackButton={showBackButton}
          isBlackButton={isBlackButton}
        />
      )}
      <Container
        className={cx(styles.container, layoutClass, {
          [styles.hideHeader]: !showBackButton,
        })}
      >
        {children}
      </Container>
      {loading && <Spinner />}
    </div>
  );
}

Layout.displayName = "Layout";
export default connect((state: IStore) => ({
  loading: state.loading,
}))(Layout);
