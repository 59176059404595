import client from "../../helpers/api";
import umbrella from "../../helpers/api/umbrella";
import mergePath from "../../helpers/mergePath";
import { IUser, IPaymentMethod } from "./types";

export function getUser(): Promise<IUser> {
  return client.get(mergePath("api", `/residents/me`), {});
}

export function confirmInformation(data: {
  firstName: string;
  lastName: string;
  phone: string;
  unit: string;
  email: string;
}): Promise<IUser> {
  return client.post(mergePath("api", `/residents/confirm`), data);
}

export function confirmAddress(): Promise<IUser> {
  return client.post(mergePath("api", `/residents/confirm/address`), {});
}

export function rejectAddress(): Promise<IUser> {
  return client.post(mergePath("api", `/residents/reject/address`), {});
}

export function addPaymentMethod({
  id,
  phone,
}: {
  id: string;
  phone: string;
}): Promise<void> {
  return client.post(mergePath("api", "/residents/payment-methods"), {
    paymentMethodId: id,
    phone,
  });
}

export function initSetupPaymentMethod(): Promise<{
  clientSecret: string | null;
}> {
  return client.post(mergePath("api", "/residents/payment-methods/init"), {});
}

export function getPaymentMethods(): Promise<IPaymentMethod[]> {
  return client.get(mergePath("api", "/residents/payment-methods"), {});
}

export function removePaymentMethod({ id }: { id: string }): Promise<void> {
  return client.del(
    mergePath("api", `/residents/payment-methods/update/${id}`),
    {}
  );
}

export function setDefaultPaymentMethod({
  id,
}: {
  id: string;
}): Promise<IUser> {
  return umbrella.post(
    mergePath("umbrella", "/users/me/payment-methods/set-default"),
    {
      paymentMethodId: id,
    }
  );
}
