import * as React from "react";
import { connect } from "react-redux";
import Header from "../../../../components/header";
import { SafeAreaContainer } from "../../../../components/container";

import {
  fetchPolicyBoost,
  FetchPolicyBoost,
} from "../../../../resources/insurance/insurance.actions";
import { getBoostPolicy } from "../../../../resources/insurance/insurance.selectors";
import { IBoostPolicy } from "../../../../resources/insurance/types";
import { IStore } from "../../../../resources/types";

import { ReactComponent as ArrowDownIcon } from "../../../../components/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../components/icons/arrow_up.svg";

import GTag from "../../../../gtag";

import styles from "./faq.module.css";

interface IQuestion {
  title: React.ReactChild;
  content: React.ReactChild;
  isOpen: boolean;
}

interface IProps {
  policy?: IBoostPolicy;
  fetchPolicyBoost: FetchPolicyBoost;
}

function Faq({ fetchPolicyBoost, policy }: IProps) {
  const QUESTIONS: IQuestion[] = [
    {
      title: <span>Who reviews and manages my claim?</span>,
      content: (
        <div className={styles.content}>
          <div>
            Markel Corporation is the policy underwriter and will review all
            claims you submit. Markel is a market leader in insurance solutions
            and has been in the insurance business for more than 70 years.
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: (
        <span>What information and documentation are needed for a claim?</span>
      ),
      content: (
        <div className={styles.content}>
          <div>
            You’ll need to provide the date the loss occurred along with a brief
            description of the damage and the peril that caused it (i.e. theft,
            fire, etc.). You can also submit additional documentation to support
            your claim, like photos or receipts.
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: <span>Do I have to submit my claim through the Latch app?</span>,
      content: (
        <div className={styles.content}>
          <div>
            No - you can also call Markel at (800) 236-3113 or email them at{" "}
            <a href="mailto:newclaims@markel.com">newclaims@markel.com</a>.
            Please be sure to include your policy number, phone number, and
            email address in the claim (Latch sends this information for you
            when you submit a claim through the app).
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: <span>What happens after I submit my claim?</span>,
      content: (
        <div className={styles.content}>
          <div>
            Once you submit a claim, you will receive an acknowledgment email
            from Markel within 24 hours. Markel will assign a claims adjuster to
            your case, who will reach out to you separately with a claim number
            and contact information.
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: <span>How will I get paid? How long will it take?</span>,
      content: (
        <div className={styles.content}>
          <div>
            Most claims are settled in about 2 weeks. If the claim is approved,
            you’ll receive a physical check in the mail to the address on your
            policy.
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: (
        <span>How do you determine the value of my personal property?</span>
      ),
      content: (
        <div className={styles.content}>
          <div>
            Refer to your <a href={policy?.url}>policy document</a> for
            information on how personal property losses are valued along with
            any relevant limits.
          </div>
        </div>
      ),
      isOpen: false,
    },
    {
      title: <span>What if I have other questions about my policy?</span>,
      content: (
        <div className={styles.content}>
          <div>
            You can contact Latch at{" "}
            <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
          </div>
        </div>
      ),
      isOpen: false,
    },
  ];
  const [questions, setQuesions] = React.useState<IQuestion[]>(QUESTIONS);

  React.useEffect(() => {
    GTag.event("claims_faq", "Page");
  });

  React.useEffect(() => {
    if (!policy) {
      fetchPolicyBoost();
    }
  }, [policy, fetchPolicyBoost]);

  const toggle = (q: IQuestion) => {
    setQuesions((prevQuestions) => {
      const prevQ = prevQuestions.find((x) => x.title === q.title);
      const index = prevQuestions.findIndex((x) => x.title === q.title);
      if (!prevQ) {
        return prevQuestions;
      }
      let newQuestion = prevQuestions;
      if (!prevQ.isOpen) {
        newQuestion = newQuestion.map((x) => ({ ...x, isOpen: false }));
      }
      return [
        ...newQuestion.slice(0, index),
        {
          ...prevQ,
          isOpen: !prevQ.isOpen,
        },
        ...newQuestion.slice(index + 1),
      ];
    });
  };

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.head}>Claims FAQ</Header>
      </SafeAreaContainer>
      <div>
        {questions.map((q) => (
          <div className={styles.question} onClick={() => toggle(q)}>
            <div className={styles.row}>
              <div className={styles.title}>{q.title}</div>
              {q.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {q.isOpen && <div className={styles.text}>{q.content}</div>}
          </div>
        ))}
      </div>
    </>
  );
}

Faq.displayName = "FAQ";
export default connect(
  (state: IStore) => ({
    policy: getBoostPolicy(state),
  }),
  {
    fetchPolicyBoost,
  }
)(Faq);
