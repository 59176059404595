import * as React from "react";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import values from "lodash/values";
import moment from "moment-timezone";
import { lowerFirst } from "lodash";
import { formatMoneyInCents } from "../../../../helpers/common";
import Price from "../../components/pricing";
import Header from "../../../../components/header";
import Checkbox from "../../../../components/checkbox";
import { withStripe } from "../../../../components/credit-card/stripe";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import Button from "../../../../components/action-button";
import CardIcon from "../../../../components/card-icon";
import AuthorizePayment from "../../../../components/payment-methods/auth-payment";
import { SafeAreaContainer } from "../../../../components/container";
import { loadingStart, loadingEnd } from "../../../../resources/loading";
import { getItem, setItem } from "../../../../helpers/storage";
import {
  payBoost,
  PayBoost,
} from "../../../../resources/insurance/insurance.actions";
import {
  fetchPaymentMethods,
  FetchPaymentMethods,
  fetchUser,
  FetchUser,
} from "../../../../resources/user/user.actions";

import {
  getPaymentMethods,
  getDefaultPaymentMethod,
} from "../../../../resources/user/user.selectors";
import { showModal } from "../../../../modules/modalProvider";
import { IPaymentMethod } from "../../../../resources/user/types";
import { IStore } from "../../../../resources/types";
import { IBoostQuote } from "../../../../resources/insurance/types";

import { getQuoteSummary } from "../boost.config";

import { ReactComponent as PlusIcon } from "../../../../components/icons/plus.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../components/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../components/icons/arrow_up.svg";

import GTag from "../../../../gtag";

import styles from "./pay.module.css";

interface IProps {
  paymentMethods: IPaymentMethod[];
  fetchPaymentMethods: FetchPaymentMethods;
  fetchUser: FetchUser;
  quote: IBoostQuote;
  payBoost: PayBoost;
  defaultPaymentMethod: string;
}

const STORAGE_PAYMENT_ERROR = "boost_payment_errors";

function Payment({
  quote,
  defaultPaymentMethod,
  paymentMethods,
  fetchPaymentMethods,
  fetchUser,
  payBoost,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isAgree, setIsAgree] = React.useState(false);
  const [isSummaryOpened, setIsSummaryOpened] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState(
    defaultPaymentMethod
  );

  React.useEffect(() => {
    GTag.event("boost_payment", "Page");
  });

  const onAddNewCard = () => {
    history.push(`/insurance/boost/edit/${id}/payment/add`);
  };

  const onTermsClick = () => {
    history.push("/insurance/terms");
  };

  const onChange = (id: string) => {
    setSelectedPaymentMethodId(id);
  };

  const toggleSummaryDetails = () => {
    setIsSummaryOpened(!isSummaryOpened);
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  React.useEffect(() => {
    setSelectedPaymentMethodId(defaultPaymentMethod);
  }, [defaultPaymentMethod]);

  React.useEffect(() => {
    fetchUser({ skipLoading: true });
  }, [paymentMethods, fetchUser]);

  async function onSubmit(e: React.FormEvent) {
    setServerError("");
    e.preventDefault();
    e.stopPropagation();
    if (paymentMethods.length === 0) {
      setServerError("Please, add your payment method to continue.");
      return;
    }
    if (!isAgree) {
      setServerError("You must agree to the Terms and Conditions to continue.");
      return;
    }
    setIsSubmitting(true);

    try {
      GTag.event("boost_submit", "Click");
      const pm = paymentMethods.find((x) => x.id === selectedPaymentMethodId);
      await payBoost({ id: selectedPaymentMethodId || "", paymentMethod: pm });
      history.push(`/insurance/boost/edit/${id}/success`);
    } catch (err) {
      if (err.data && err.data.code === "authentication_required") {
        showModal(
          <AuthorizePayment
            clientSecret={err.data.clientSecret}
            paymentMethodId={err.data.paymentMethodId}
            onComplete={async () => {
              const pm = paymentMethods.find(
                (x) => x.id === selectedPaymentMethodId
              );
              await payBoost({
                id: selectedPaymentMethodId || "",
                paymentMethod: pm,
                confirmed: true,
              });
              history.push(`/insurance/boost/edit/${id}/success`);
            }}
            currentPathname={history.location.pathname}
          />
        );
        return;
      }

      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
      setIsSubmitting(false);
      const numberOfErrors = (+getItem(STORAGE_PAYMENT_ERROR) || 0) + 1;
      setItem(STORAGE_PAYMENT_ERROR, numberOfErrors.toString());
      if (numberOfErrors < 3) {
        history.push("/insurance/boost/error/check");
      } else {
        history.push("/insurance/boost/error/payment");
      }
    }
  }

  const { primary, details } = getQuoteSummary(quote.summary);
  const summaryDetails = [...primary, ...details];

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>Your coverage summary</Header>
      </SafeAreaContainer>
      <div className={styles.container}>
        <div className={styles.summaryContainer}>
          <div className={styles.summary}>
            <Price
              amount={quote.summary.paymentAmount}
              period="month"
              className={styles.pricing}
            />
            {isSummaryOpened && (
              <div className={styles.summaryDetails}>
                {summaryDetails.map((s, index) => (
                  <div>
                    {index === 0 && (
                      <div className={styles.summaryDetailsRowFirst}>
                        <div>{s.name}</div>
                        <div>{s.value}</div>
                      </div>
                    )}
                    {index > 0 && (
                      <div className={styles.summaryDetailsRow}>
                        <div>{s.name}</div>
                        <div>{s.value}</div>
                      </div>
                    )}
                  </div>
                ))}
                <div className={styles.summaryDetailsDescription}>
                  * Coverage is automatic and not adjustable.
                </div>
              </div>
            )}
            <div className={styles.summaryFooter}>
              <div className={styles.summaryDate}>
                Effective{" "}
                {moment.utc(quote.summary.startDate).format("MMMM D, YYYY")}
              </div>
              <div
                className={styles.summaryToggle}
                onClick={toggleSummaryDetails}
                data-id="detailsBtn"
              >
                {isSummaryOpened ? "Hide details" : "View details"}
                {isSummaryOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.paymentMethods}>
          {paymentMethods.map((paymentMethod, index) => (
            <Checkbox
              data-id={`paymentMethod${index}`}
              className={styles.checkbox}
              checkmarkClassName={styles.checkmark}
              key={paymentMethod.id}
              name={paymentMethod.id}
              LabelComponent={() => (
                <div className={styles.paymentMethod}>
                  <div className={styles.cardNumber}>
                    {paymentMethod && paymentMethod.card ? (
                      <CardIcon
                        cardBrand={lowerFirst(paymentMethod.card.brand)}
                        className={styles.cardIcon}
                      />
                    ) : null}
                    {paymentMethod && paymentMethod.card ? (
                      <span className={styles.card}>
                        {capitalize(paymentMethod.card.brand)} ending in{" "}
                        {paymentMethod.card.last4}
                      </span>
                    ) : (
                      "Credit card"
                    )}
                  </div>
                  {!paymentMethod || !paymentMethod.card ? (
                    <div className={styles.icons}>
                      <CardIcon allBrands className={styles.cardIcon} />
                    </div>
                  ) : null}
                </div>
              )}
              checked={paymentMethod.id === selectedPaymentMethodId}
              onChange={() => onChange(paymentMethod.id)}
            />
          ))}
          <div
            className={cx(styles.addNew, {
              [styles.noMethods]: paymentMethods.length === 0,
            })}
            role="button"
            data-id="addCardBtn"
            onClick={onAddNewCard}
          >
            <PlusIcon className={styles.plusIcon} />
            <div className={styles.new}>Add a new card...</div>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              data-id="terms"
              className={styles.checkbox}
              checkmarkClassName={styles.checkmark}
              name="authorization"
              LabelComponent={() => (
                <div className={styles.checkboxLabel}>
                  I agree to the{" "}
                  <button className={styles.link} onClick={onTermsClick}>
                    Terms & Conditions
                  </button>
                  .
                </div>
              )}
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
          </div>
          {serverError && (
            <div className={styles.errorBlock}>{serverError}</div>
          )}
        </div>
      </div>
      <Button onClick={onSubmit} disabled={isSubmitting} data-id="submitBtn">
        {quote.type === "UPDATE"
          ? "Modify policy"
          : `Pay ${formatMoneyInCents(quote.summary.paymentAmount)} a month`}
      </Button>
    </>
  );
}

Payment.displayName = "Payment";
export default withBoostQuote(
  connect(
    (state: IStore) => ({
      paymentMethods: getPaymentMethods(state),
      defaultPaymentMethod: getDefaultPaymentMethod(state),
    }),
    {
      loadingStart,
      loadingEnd,
      payBoost,
      fetchUser,
      fetchPaymentMethods,
    }
  )(withStripe(Payment))
);
