import * as React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

interface IProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  "data-id"?: string;
}

export default function TextArea({
  className,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  readOnly,
  maxLength = 550,
  rows = 5,
  disabled = false,
  ...props
}: IProps) {
  return (
    <div className={cx(styles.inputContainer, className)}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        data-id={props["data-id"]}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={!!readOnly}
        maxLength={maxLength}
        rows={rows}
        disabled={disabled}
      />
    </div>
  );
}
TextArea.displayName = "TextArea";
