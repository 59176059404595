import * as React from "react";
import cx from "classnames";
import { formatMoneyInCents } from "../../../../helpers/common";

import styles from "./pricing.module.css";

interface IProps {
  className?: string;
  amount: number;
  period: string;
}

export default function Pricing({ amount, period, className }: IProps) {
  return (
    <div className={cx(styles.pricing, className)}>
      <div className={styles.price}>
        <div className={styles.dollar}>$</div>
        <div className={styles.amount} data-id="pricingAmount">
          {formatMoneyInCents(amount, true, true)}
        </div>
        <div className={styles.text}>per {period}</div>
      </div>
    </div>
  );
}
Pricing.displayName = "Pricing";
