import * as React from "react";
import cx from "classnames";
import Button from "../button";
import Modal from "../modal";
import { modalComponent } from "../../modules/modalProvider";

import styles from "./confirm.module.css";

interface IProps {
  title: string;
  description?: string;
  yes: string;
  no?: string;
  blockClosing?: boolean;
  hideModal: () => void;
  action: () => Promise<any>;
}

function Confirm({
  hideModal,
  action,
  title,
  description,
  yes,
  no,
  blockClosing,
}: IProps) {
  const yesAction = async () => {
    action();
    hideModal();
  };

  const noAction = () => {
    if (blockClosing) {
      return;
    }
    hideModal();
  };

  return (
    <Modal
      className={styles.modal}
      onClose={noAction}
      showCloseIcon={!blockClosing}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.actions}>
        <Button
          className={cx(
            styles.button,
            styles.yes,
            !no ? styles.single : undefined
          )}
          onClick={yesAction}
          data-id="confirmYesBtn"
        >
          {yes}
        </Button>
        {no && (
          <Button
            className={cx(styles.button, styles.no)}
            onClick={noAction}
            data-id="confirmNoBtn"
          >
            {no}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default modalComponent(Confirm);
