import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import values from "lodash/values";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";
import Header from "../../../components/header";
import { SafeAreaContainer } from "../../../components/container";
import { withStripe } from "../../../components/credit-card/stripe.lemonade";
import CardElement from "../../../components/credit-card/card-element";
import Button from "../../../components/action-button";
import { loadingStart, loadingEnd } from "../../../resources/loading";
import {
  setQuoteToken,
  SetQuoteToken,
} from "../../../resources/insurance/insurance.actions";

import GTag from "../../../gtag";

import styles from "./pay.module.css";

interface IStripeFields<T> {
  cardNumber?: T;
  cardExpiry?: T;
  cardCvc?: T;
}
type CardErrorType = "cardNumber" | "cardExpiry" | "cardCvc";

const stripeMessages: IStripeFields<string> = {
  cardNumber: "Please enter valid Credit Card Number",
  cardExpiry: "Please enter valid Expiration Date",
  cardCvc: "Please enter valid CVC",
};

interface IProps {
  setQuoteToken: SetQuoteToken;
  loadingStart: () => void;
  loadingEnd: () => void;
}

function AddCard({ setQuoteToken, loadingStart, loadingEnd }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [errors, setErrors] = React.useState<{
    [key: string]: string | undefined;
  }>({});
  const [cardBrand, setCardBrand] = React.useState("unknown");
  const [isStripeReady, setStripeReady] = React.useState<
    IStripeFields<boolean>
  >({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });
  const [stripeErrors, setStripeErrors] = React.useState<IStripeFields<string>>(
    {
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
    }
  );
  // To Solve Stripe iFrame Focus Issues https://github.com/stripe/react-stripe-elements/issues/326
  const [stripeFocusTimestamp, setStripeFocusTimestamp] = React.useState<
    IStripeFields<Date>
  >({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  });
  const [stripeCompletion, setStripeCompletion] = React.useState<
    IStripeFields<boolean>
  >({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [stripeErrorsCurrent, setStripeErrorsCurrent] = React.useState<
    IStripeFields<string>
  >(stripeMessages);

  React.useEffect(() => {
    GTag.event("lemonade_card", "Page");
  });

  function onStripeFocus(type: CardErrorType) {
    return () => {
      setStripeFocusTimestamp({
        ...stripeFocusTimestamp,
        [type]: new Date(),
      });
      if (type === "cardCvc") {
        setTimeout(() => {
          setStripeErrors(stripeErrors);
        }, 300);
      }
    };
  }

  function onStripeReady(type: CardErrorType) {
    return () => {
      setStripeReady((v) => ({ ...v, [type]: true }));
    };
  }

  function onStripeBlur(type: CardErrorType) {
    return () => {
      const focusTime = stripeFocusTimestamp[type];
      // focus - blur - focus
      if (focusTime && new Date().getTime() - focusTime.getTime() < 500) {
        return;
      }

      const newStripeErrors = {
        ...stripeErrors,
        [type]: stripeErrorsCurrent[type],
      };
      setStripeErrors(newStripeErrors);
      if (
        !newStripeErrors.cardNumber &&
        !newStripeErrors.cardExpiry &&
        !newStripeErrors.cardCvc
      ) {
        setErrors({
          ...errors,
          stripe: undefined,
        });
      }
    };
  }

  async function onStripeChange(
    value:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) {
    const newErrors: IStripeFields<string> = { ...stripeErrorsCurrent };
    const newStripeCompletion: IStripeFields<boolean> = { ...stripeCompletion };
    switch (value.elementType) {
      case "cardNumber":
        newStripeCompletion.cardNumber = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardNumber = stripeMessages.cardNumber;
        } else {
          newErrors.cardNumber = undefined;
        }
        break;
      case "cardExpiry":
        newStripeCompletion.cardExpiry = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardExpiry = stripeMessages.cardExpiry;
        } else {
          newErrors.cardExpiry = undefined;
        }
        break;
      case "cardCvc":
        newStripeCompletion.cardCvc = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardCvc = stripeMessages.cardCvc;
        } else {
          newErrors.cardCvc = undefined;
        }
        break;
      default:
        break;
    }
    setStripeErrorsCurrent(newErrors);
    setStripeCompletion(newStripeCompletion);

    if (value.elementType === "cardNumber") {
      setCardBrand(value.brand);
    }
  }

  const isReady =
    isStripeReady.cardNumber &&
    isStripeReady.cardCvc &&
    isStripeReady.cardExpiry;

  React.useEffect(() => {
    const finished =
      isStripeReady.cardNumber &&
      isStripeReady.cardCvc &&
      isStripeReady.cardExpiry;
    if (!finished) {
      loadingStart();
    } else {
      loadingEnd();
    }
  }, [isStripeReady, loadingEnd, loadingStart]);

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    setStripeErrors(stripeErrorsCurrent);
    setServerError("");
    if (stripe && elements && !isSubmitting) {
      setIsSubmitting(true);
      const card = elements.getElement("cardNumber");
      if (!card) {
        setIsSubmitting(false);
        return;
      }

      const { token, error } = await stripe.createToken(card);

      if (error || !token || !token.card) {
        if (error) {
          const messages: IStripeFields<string> = {
            cardNumber: !stripeCompletion.cardNumber
              ? stripeMessages.cardNumber
              : undefined,
            cardExpiry: !stripeCompletion.cardExpiry
              ? stripeMessages.cardExpiry
              : undefined,
            cardCvc: !stripeCompletion.cardCvc
              ? stripeMessages.cardCvc
              : undefined,
          };
          setStripeErrorsCurrent(messages);
          setStripeErrors(messages);
          if (error.message) {
            setServerError(error.message);
          }
        }
        setIsSubmitting(false);
        return;
      }

      try {
        await setQuoteToken({
          token: token.id,
          last4: token.card.last4,
          cardBrand: token.card.brand,
        });
        history.push(`/insurance/edit/${id}/payment`);
      } catch (err) {
        if (err.data) {
          setServerError(values(err.data.errors[0])[0]);
        }
        setIsSubmitting(false);
      }
    }
  }

  return (
    <div className={styles.page}>
      <SafeAreaContainer className={styles.head}>
        <Header>Card details</Header>
      </SafeAreaContainer>
      <form
        onSubmit={onSubmit}
        className={cx(styles.form, { [styles.hidden]: !isReady })}
      >
        <SafeAreaContainer className={styles.main}>
          <div className={cx(styles.formGroup, "fs-block")}>
            <CardElement
              cardBrand={cardBrand}
              error={stripeErrors.cardNumber}
              type="number"
              placeholder="Credit Card Number"
              onChange={onStripeChange}
              onFocus={onStripeFocus("cardNumber")}
              onBlur={onStripeBlur("cardNumber")}
              onReady={onStripeReady("cardNumber")}
              hideIcon
            />
          </div>
          <div className={cx(styles.formGroup, "fs-block")}>
            <CardElement
              className={styles.expiryInput}
              error={stripeErrors.cardExpiry}
              type="expiry"
              placeholder="Expiration Date"
              onChange={onStripeChange}
              onFocus={onStripeFocus("cardExpiry")}
              onBlur={onStripeBlur("cardExpiry")}
              onReady={onStripeReady("cardExpiry")}
              hideIcon
            />
          </div>
          <div
            className={cx(styles.formGroup, "fs-block", styles.noMarginBottom)}
          >
            <CardElement
              className={styles.cvvInput}
              error={stripeErrors.cardCvc}
              type="cvc"
              placeholder="CVC"
              onChange={onStripeChange}
              onFocus={onStripeFocus("cardCvc")}
              onBlur={onStripeBlur("cardCvc")}
              onReady={onStripeReady("cardCvc")}
              hideIcon
            />
          </div>
          <div className={styles.errorBlock}>
            {serverError || errors.stripe}
          </div>
        </SafeAreaContainer>
        <Button onClick={onSubmit} disabled={isSubmitting} data-id="addCardBtn">
          Add card
        </Button>
      </form>
    </div>
  );
}

AddCard.displayName = "AddCard";
export default connect(() => ({}), {
  loadingStart,
  loadingEnd,
  setQuoteToken,
})(withStripe(AddCard));
