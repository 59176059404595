import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import config from "./config";

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.location.hash = window.location.hash; // eslint-disable-line no-self-assign
  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}`
  );
};

interface Auth0ContextInterface {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: any;
  handleRedirectCallback: any;
  getIdTokenClaims: any;
  loginWithRedirect: any;
  getTokenSilently: any;
  getTokenWithPopup: any;
  logout: any;
}

export const Auth0Context = React.createContext<Auth0ContextInterface | null>(
  null
);
export const useAuth0 = () => useContext(Auth0Context) as Auth0ContextInterface;

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  function initUser(user: any) {
    const meta = user[`${config.auth0.namespace}/user`];
    const updatedUser = {
      ...user,
      ...meta
    };
    setUser(updatedUser);
  }

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      const query = window.location.search;
      if (query.includes("code=")) {
        try {
          await auth0FromHook.handleRedirectCallback();
        } catch (err) {
          /* eslint-disable-next-line no-console */
          console.error(err);
        }
        onRedirectCallback();
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();

        initUser(user);
        setLoading(false);
        return;
      }

      if (query.includes("code=") && query.includes("state=")) {
        try {
          await auth0FromHook.handleRedirectCallback();
        } catch (err) {
          /* eslint-disable-next-line no-console */
          console.error(err);
        }
        onRedirectCallback();

        setLoading(false);
        return;
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithPopup = async (params = {}) => {
    if (auth0Client) {
      setPopupOpen(true);
      try {
        await auth0Client.loginWithPopup(params);
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error(error);
      } finally {
        setPopupOpen(false);
      }
      const user = await auth0Client.getUser();
      initUser(user);
      setIsAuthenticated(true);
    }
  };

  const handleRedirectCallback = async () => {
    if (auth0Client) {
      setLoading(true);
      await auth0Client.handleRedirectCallback();
      const user = await auth0Client.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      initUser(user);
    }
  };
  const value: Auth0ContextInterface = {
    isAuthenticated,
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    getIdTokenClaims: (...p: any) =>
      auth0Client && auth0Client.getIdTokenClaims(...p),
    loginWithRedirect: (...p: any) =>
      auth0Client && auth0Client.loginWithRedirect(...p),
    getTokenSilently: (...p: any) =>
      auth0Client && auth0Client.getTokenSilently(...p),
    getTokenWithPopup: (...p: any) =>
      auth0Client && auth0Client.getTokenWithPopup(...p),
    logout: (...p: any) => auth0Client && auth0Client.logout(...p)
  };

  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};
Auth0Provider.displayName = "Auth0Provider";
