import * as React from "react";
import cx from "classnames";
import moment from "moment-timezone";

import { formatMoneyInCents } from "../../helpers/common";
import { IPayment } from "../../resources/payment/types";

import styles from "./payment-item.module.css";

interface IProps {
  payment: IPayment;
}

function PaymentItem({ payment }: IProps) {
  return (
    <div className={styles.setting} role="button">
      {payment.type === "REFUND" && (
        <>
          <div className={styles.title}>
            <div>
              {payment.status === "Scheduled"
                ? "Pending refund"
                : formatMoneyInCents(payment.amount)}
            </div>
            <div className={styles.period}>
              {moment(payment.date).format("MMM D, YYYY")}
            </div>
          </div>
          {payment.periodStart && payment.periodEnd && (
            <div className={styles.period}>
              {moment(payment.periodStart).format("MMM D")}
              {" — "}
              {moment(payment.periodEnd).format("MMM D")}
            </div>
          )}
          <div
            className={cx(
              styles.status,
              payment.status === "Refunded" ? styles.blue : ""
            )}
          >
            {payment.status}
          </div>
        </>
      )}
      {payment.type === "PAYMENT" && (
        <>
          <div className={styles.title}>
            <div>{formatMoneyInCents(-1 * payment.amount)}</div>
            <div className={styles.period}>
              {moment(payment.date).format("MMM D, YYYY")}
            </div>
          </div>
          {payment.periodStart && payment.periodEnd && (
            <div className={styles.period}>
              {moment(payment.periodStart).format("MMM D")}
              {" — "}
              {moment(payment.periodEnd).format("MMM D")}
            </div>
          )}
          <div
            className={cx(
              styles.status,
              payment.status === "Paid" ? styles.green : "",
              payment.status === "Failed" ? styles.red : ""
            )}
          >
            {payment.status}
          </div>
        </>
      )}
    </div>
  );
}

PaymentItem.displayName = "PaymentItem";
export default PaymentItem;
