import * as React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../components/button";
import Header from "../../../components/header";
import Text from "../../../components/text";
import { SafeAreaContainer } from "../../../components/container";
import { getUser } from "../../../resources/user/user.selectors";
import { IUser } from "../../../resources/user/types";

import { IStore } from "../../../resources/types";

import styles from "./completed.module.css";

interface IProps {
  user: IUser;
}

function UploadInsuranceCompleted({ user }: IProps) {
  const history = useHistory();

  const onHome = () => {
    history.push("/");
  };

  const header = !user.isD2R ? "Hang tight" : "Thank you";
  const body = !user.isD2R
    ? `
        We’re checking to see if the policy meets the requirements of${" "}
        ${user.building}. We’ll contact you if there are any issues or
        questions.
      `
    : "Thank you for uploading your policy details!";
  const buttonText = !user.isD2R ? "Submitted, we’ll be in touch." : "All done";

  return (
    <>
      <SafeAreaContainer>
        <Header className={styles.headerCompleted}>{header}</Header>
        <Text className={styles.textCompleted}>{body}</Text>
      </SafeAreaContainer>
      <Button className={styles.btnBlack} onClick={onHome}>
        {buttonText}
      </Button>
    </>
  );
}

UploadInsuranceCompleted.displayName = "UploadInsuranceCompleted";
export default connect((state: IStore) => ({
  user: getUser(state)
}))(UploadInsuranceCompleted);
