import * as React from "react";
import Cookies from "js-cookie";
import { getQueryObject } from "./helpers/querystring";
import * as api from "./resources/auth/auth.api";
import { Auth0Provider } from "./react-auth0-wrapper";
import config from "./config";

const IS_IN_APP = "__UMBRELLA_IS_IN_APP";
const IS_BOOST = "__UMBRELLA_IS_BOOST";

function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

interface IAuthContext {
  loading: boolean;
  getRawToken: () => string | undefined;
  getToken: () => Promise<{
    token?: { token: string; type: "access" | "invite" };
    loggedIn: boolean;
  }>;
  login: () => Promise<{ token: string; type: "access" | "invite" }>;
  logout: () => void;
  refreshToken: () => Promise<{ token: string; type: "access" | "invite" }>;
  type?: "web" | "in-app";
}

const AuthContext = React.createContext<IAuthContext | null>(null);

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

function AuthProvider({ children }: IProps) {
  const [initialized, setInitialized] = React.useState(false);
  const [type, setType] = React.useState<"web" | "in-app">("web");
  const [loggedIn, setIsLoggedIn] = React.useState(false);
  const [rawToken, setRawToken] = React.useState();
  const [token, setToken] = React.useState<{
    token: string;
    type: "invite" | "access";
  }>();
  const [returnLink, setReturnLink] = React.useState(config.returnLink);

  React.useEffect(() => {
    if (window.location.search.includes("isBoost=")) {
      Cookies.set(IS_BOOST, "true");
    } else {
      Cookies.remove(IS_BOOST);
    }
    if (
      window.location.search.includes("token=") ||
      window.location.search.includes("inviteToken=")
    ) {
      const q = getQueryObject();

      if (q.token) {
        setRawToken(q.token);
        saveToken({ token: q.token, type: "access" });
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
      if (q.inviteToken) {
        saveToken({ token: q.inviteToken, type: "invite" });
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
      if (q.returnTo) {
        setReturnLink(q.returnTo);
      }
    }
    setInitialized(true);
  }, [setToken, setInitialized]);

  async function waitForInit() {
    while (!initialized) {
      /* eslint-disable-next-line */
      await sleep(100);
    }

    return true;
  }

  async function getToken() {
    await waitForInit();

    return { token, loggedIn };
  }

  function getRawToken() {
    return rawToken;
  }

  function saveToken(newToken: { token: string; type: "invite" | "access" }) {
    setToken(newToken);
    return newToken;
  }

  function login() {
    return getToken()
      .then(({ token: newToken }) => {
        if (!newToken) {
          Cookies.remove(IS_IN_APP);
          throw new Error();
        }
        if (newToken.type === "invite") {
          return api
            .login({ token: newToken.token })
            .then(({ token }) => ({ token, type: "access" as const }));
        }
        setType("in-app");
        return api
          .exchangeToken({ token: newToken.token })
          .then(({ token }) => ({ token, type: "access" as const }));
      })
      .then(result => {
        setIsLoggedIn(true);
        return saveToken(result);
      });
  }

  function logout() {
    window.location.replace(
      `${returnLink}${token ? `?token=${rawToken}` : ""}`
    );
  }

  function refreshToken() {
    return getToken()
      .then(({ token: newToken }) => {
        if (!newToken) {
          Cookies.remove(IS_IN_APP);
          return { token: "" };
        }
        return api.refreshToken({ token: newToken.token });
      })
      .then(({ token: newToken }) =>
        saveToken({ token: newToken, type: "access" })
      );
  }

  return (
    <AuthContext.Provider
      value={{
        getToken,
        getRawToken,
        login,
        logout,
        loading: !initialized,
        refreshToken,
        type
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const onRedirectCallback = (appState: { targetUrl: string }) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

function Auth0AuthProvider({ children }: IProps) {
  return (
    <Auth0Provider
      domain={config.auth0.domain}
      client_id={config.auth0.clientId}
      redirect_uri={window.location.origin}
      audience={config.auth0.audience}
      onRedirectCallback={onRedirectCallback}
      returnTo={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
}

function AuthProviderWrap({ children }: IProps) {
  const [isToken, setIsToken] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    if (
      !window.location.search.includes("token=") &&
      !window.location.search.includes("inviteToken=")
    ) {
      setIsToken(false);
    } else {
      if (window.location.search.includes("token=")) {
        Cookies.set(IS_IN_APP, "true");
      }
      setIsToken(true);
    }
  }, [setIsToken]);
  if (isToken === null) {
    return null;
  }

  if (isToken) {
    return <AuthProvider>{children}</AuthProvider>;
  }
  return <Auth0AuthProvider>{children}</Auth0AuthProvider>;
}

const useAuth = () => React.useContext(AuthContext) as IAuthContext;

AuthProvider.displayName = "AuthProvider";
AuthProviderWrap.displayName = "AuthProviderWrap";

export { useAuth, AuthProviderWrap as default };
