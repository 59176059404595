import get from "lodash/get";
import { Moment } from "moment";

function proxyDefaults(proxyObject: { default: any; [key: string]: any }) {
  return new Proxy(proxyObject, {
    get(target, prop) {
      return get(target, prop, target.default);
    },
  });
}

function getFirstName(name: string) {
  if (!name) {
    return name;
  }
  const nameParts = name.split(" ").filter((s: string) => !!s.trim());
  if (nameParts.length > 0) {
    return nameParts[0];
  }
  return name;
}

function getLastName(name: string) {
  if (!name) {
    return name;
  }
  const nameParts = name.split(" ").filter((s: string) => !!s.trim());
  if (nameParts.length > 1) {
    return nameParts[1];
  }
  return name;
}

function centsToDollars(cents: number, skipDollarSign?: boolean): string {
  return `${skipDollarSign ? "" : "$"}${(cents / 100).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  })}`;
}

const isWeekend = (date: Moment) => {
  const day = date.day();
  return day === 6 || day === 0;
};

function getFormatter(decimal = true, skipDollarSign?: boolean) {
  const dollarSignProps = skipDollarSign
    ? {}
    : {
        style: "currency",
        currency: "USD",
      };

  const moneyFormatterInteger = new Intl.NumberFormat("en-US", {
    ...dollarSignProps,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const moneyFormatterDecimal = new Intl.NumberFormat("en-US", {
    ...dollarSignProps,
    minimumFractionDigits: 2,
  });

  return decimal ? moneyFormatterDecimal : moneyFormatterInteger;
}

function formatMoney(
  amount: number,
  decimal = true,
  skipDollarSign?: boolean
): string {
  const formatter = getFormatter(decimal, skipDollarSign);
  return formatter.format(amount);
}

function formatMoneyInCents(
  amountInCents: number,
  decimal = true,
  skipDollarSign?: boolean
): string {
  const formatter = getFormatter(decimal, skipDollarSign);
  return formatter.format(amountInCents / 100);
}

function range(start: number, stop: number, step: number): number[] {
  return Array(Math.ceil((stop - start) / step) + 1)
    .fill(start)
    .map((x, y) => x + y * step);
}

function rangeToCents(start: number, stop: number, step: number): number[] {
  const startCents = start * 100;
  const stopCents = stop * 100;
  const stepCents = step * 100;
  return Array(Math.ceil((stopCents - startCents) / stepCents) + 1)
    .fill(startCents)
    .map((x, y) => x + y * stepCents);
}

function formatZip(zip: string) {
  if (!zip) {
    return "";
  }
  if (zip.length === 5) {
    return zip;
  }
  return zip.slice(0, 5);
}

function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / k ** i).toFixed(decimals)) + " " + sizes[i];
}

function formatPhone(phone?: string) {
  if (!phone || phone.length < 10) {
    return undefined;
  }

  const p = phone.toString().replace(/^(\+1|1)|\(|\)|-|\s+/g, "");
  return `(${p.slice(0, 3)}) ${p.slice(3, 6)}-${p.slice(6)}`;
}

export {
  proxyDefaults,
  getFirstName,
  getLastName,
  centsToDollars,
  isWeekend,
  formatMoney,
  formatMoneyInCents,
  range,
  rangeToCents,
  formatZip,
  formatBytes,
  formatPhone,
};
