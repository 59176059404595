import { Dispatch } from "../resources/types";
import { loadingStart, loadingEnd, loadingFail } from "../resources/loading";

function requestStart(type: string, data: any) {
  return { type: `${type}_START`, asyncAction: type, requestData: data };
}

function requestFinish(type: string, data: any, payload: any) {
  return { type, asyncAction: type, requestData: data, payload };
}

function requestFail(type: string, data: any, error: any) {
  return { type: `${type}_FAIL`, asyncAction: type, requestData: data, error };
}

type APIHandler<T, P, S> = (
  data: T,
  params?: P,
  skipLoading?: boolean
) => Promise<S>;
export default function createApiAction<T, P, S>(
  type: string,
  apiHandler: APIHandler<T, P, S>
) {
  return (data: T, params?: P, skipLoading?: boolean) => async (
    dispatch: Dispatch
  ) => {
    if (!skipLoading) {
      dispatch(loadingStart());
    }
    dispatch(requestStart(type, data));

    let payload: S;
    try {
      payload = await apiHandler(data, params);
      if (!skipLoading) {
        dispatch(loadingEnd());
      }
      dispatch(requestFinish(type, data, payload));
    } catch (err) {
      dispatch(loadingFail());
      dispatch(requestFail(type, data, err));
      throw err;
    }

    return payload;
  };
}
