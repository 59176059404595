import * as React from "react";
import cx from "classnames";

import styles from "./index.module.css";

interface IProps extends React.HTMLProps<HTMLSelectElement> {
  className?: string;
}

export default function Select({
  className = "",
  children,
  name,
  label,
  placeholder,
  onChange,
  value,
  ...props
}: IProps) {
  return (
    <div className={cx(styles.inputContainer, className)}>
      {label && <label htmlFor={name}>{label}</label>}
      <i />
      <select
        id={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      >
        {children}
      </select>
    </div>
  );
}
Select.displayName = "Select";
