import * as React from "react";
import ErrorPage from "../../components/error-page";
import { getQueryObject } from "../../helpers/querystring";

function GeneralErrorPage() {
  const q = getQueryObject();
  const message = q.message || "Something went wrong";
  const showContact = q.contact === "true";

  return (
    <ErrorPage
      status={500}
      description={message}
      showContact={showContact}
      action={() => {
        window.location.href = "/";
      }}
      actionText="Back"
    />
  );
}

GeneralErrorPage.displayName = "GeneralErrorPage";
export default GeneralErrorPage;
