import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import GlobalSpinner from "../../../components/spinner/global";
import {
  createQuoteBoost,
  CreateQuoteBoost,
} from "../../../resources/insurance/insurance.actions";

interface IProps {
  createQuoteBoost: CreateQuoteBoost;
}

function BoostHome({ createQuoteBoost }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    createQuoteBoost()
      .then((response) => {
        if (response && response._id) {
          history.replace(`/insurance/boost/edit/${response._id}`);
        }
      })
      .catch(() => {
        history.replace("/insurance/boost/error");
      });
  });
  return <GlobalSpinner />;
}

BoostHome.displayName = "BoostHome";
export default connect(() => ({}), {
  createQuoteBoost,
})(BoostHome);
