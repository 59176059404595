import * as React from "react";
import { Formik, FormikProps } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import values from "lodash/values";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";

import { formatPhone } from "../../../../helpers/common";
import Header from "../../../../components/header";
import { SafeAreaContainer } from "../../../../components/container";
import { withStripe } from "../../../../components/credit-card/stripe";
import CardElement from "../../../../components/credit-card/card-element";
import Input from "../../../../components/input";
import Button from "../../../../components/action-button";
import Spinner from "../../../../components/spinner";
import { mapStripeCodeToMessage } from "../../../../components/payment-methods/auth-payment";
import { loadingStart, loadingEnd } from "../../../../resources/loading";
import {
  addPaymentMethod,
  AddPaymentMethod,
} from "../../../../resources/user/user.actions";
import { getUser } from "../../../../resources/user/user.selectors";
import { IUser } from "../../../../resources/user/types";
import { initSetupPaymentMethod } from "../../../../resources/user/user.api";
import validate, { IFormValues } from "./validation";

import GTag from "../../../../gtag";

import styles from "./pay.module.css";

const PHONE_MASK = "(999) 999-9999";
const ZIP_MASK = "99999-9999";

interface IStripeFields<T> {
  cardNumber?: T;
  cardExpiry?: T;
  cardCvc?: T;
  cardExpiryCvc?: T;
}
type CardErrorType = "cardNumber" | "cardExpiry" | "cardCvc" | "cardExpiryCvc";

const stripeMessages: IStripeFields<string> = {
  cardNumber: "Please enter valid Credit Card Number",
  cardExpiry: "Please enter valid Expiration Date",
  cardCvc: "Please enter valid CVC",
  cardExpiryCvc: "Please enter valid Expiration Date",
};

interface IProps {
  user: IUser;
  addPaymentMethod: AddPaymentMethod;
}

function AddCard({ addPaymentMethod, user }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [errors, setErrors] = React.useState<{
    [key: string]: string | undefined;
  }>({});
  const [cardBrand, setCardBrand] = React.useState("unknown");
  const [isStripeReady, setStripeReady] = React.useState<
    IStripeFields<boolean>
  >({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });
  const [stripeErrors, setStripeErrors] = React.useState<IStripeFields<string>>(
    {
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
      cardExpiryCvc: undefined,
    }
  );
  // To Solve Stripe iFrame Focus Issues https://github.com/stripe/react-stripe-elements/issues/326
  const [stripeFocusTimestamp, setStripeFocusTimestamp] = React.useState<
    IStripeFields<Date>
  >({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
    cardExpiryCvc: undefined,
  });
  const [stripeCompletion, setStripeCompletion] = React.useState<
    IStripeFields<boolean>
  >({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    cardExpiryCvc: false,
  });
  const [stripeErrorsCurrent, setStripeErrorsCurrent] = React.useState<
    IStripeFields<string>
  >(stripeMessages);
  const [clientSecret, setclientSecret] = React.useState<string | null>(null);

  function onStripeReady(type: CardErrorType) {
    return () => {
      setStripeReady((v) => ({ ...v, [type]: true }));
    };
  }

  function onStripeFocus(type: string) {
    return () => {
      setStripeFocusTimestamp({
        ...stripeFocusTimestamp,
        [type]: new Date(),
      });
      if (type === "cardCvc") {
        setTimeout(() => {
          setStripeErrors((prevErrors) => ({
            ...prevErrors,
            cardExpiryCvc: prevErrors.cardCvc || prevErrors.cardExpiry,
          }));
        }, 300);
      }
    };
  }

  function onStripeBlur(type: CardErrorType) {
    return () => {
      const focusTime = stripeFocusTimestamp[type];
      // focus - blur - focus
      if (focusTime && new Date().getTime() - focusTime.getTime() < 500) {
        return;
      }

      setStripeErrors((prevErrors) => {
        const newErrors = {
          ...prevErrors,
          [type]: stripeErrorsCurrent[type],
        };
        return {
          ...newErrors,
          cardExpiryCvc: newErrors.cardExpiry || newErrors.cardCvc,
        };
      });
      const newStripeErrors = {
        ...stripeErrors,
        [type]: stripeErrorsCurrent[type],
      };
      if (
        !newStripeErrors.cardNumber &&
        !newStripeErrors.cardExpiry &&
        !newStripeErrors.cardCvc
      ) {
        setErrors({
          ...errors,
          stripe: undefined,
        });
      }
    };
  }

  async function onStripeChange(
    value:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) {
    const newErrors: IStripeFields<string> = { ...stripeErrorsCurrent };
    const newStripeCompletion: IStripeFields<boolean> = { ...stripeCompletion };
    switch (value.elementType) {
      case "cardNumber":
        newStripeCompletion.cardNumber = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardNumber = stripeMessages.cardNumber;
        } else {
          newErrors.cardNumber = undefined;
        }
        break;
      case "cardExpiry":
        newStripeCompletion.cardExpiry = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardExpiry = stripeMessages.cardExpiry;
        } else {
          newErrors.cardExpiry = undefined;
        }
        break;
      case "cardCvc":
        newStripeCompletion.cardCvc = value.complete;
        if (!value.complete || value.error) {
          newErrors.cardCvc = stripeMessages.cardCvc;
        } else {
          newErrors.cardCvc = undefined;
        }
        break;
      default:
        break;
    }
    setStripeErrorsCurrent({
      ...newErrors,
      cardExpiryCvc: newErrors.cardExpiry || newErrors.cardCvc,
    });
    setStripeCompletion(newStripeCompletion);

    if (value.elementType === "cardNumber") {
      setCardBrand(value.brand);
    }
  }

  React.useEffect(() => {
    initSetupPaymentMethod().then(({ clientSecret }) =>
      setclientSecret(clientSecret)
    );
  }, []);

  async function onClickSubmit(formValues: IFormValues) {
    setStripeErrors(stripeErrorsCurrent);
    if (stripe && elements && !isSubmitting && clientSecret) {
      setIsSubmitting(true);
      const card = elements.getElement("cardNumber");
      if (!card) {
        setErrors({ stripe: "Please enter valid card" });
        setIsSubmitting(false);
        return;
      }

      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card,
            billing_details: {
              address: {
                postal_code: formValues.zip,
              },
            },
          },
        }
      );

      const fields = validate(formValues);
      if (error || Object.keys(fields).length > 0) {
        const customMessage =
          error && error.code && mapStripeCodeToMessage[error.code];
        setErrors({
          ...fields,
          stripe: error ? customMessage || error.message : undefined,
        });
        setIsSubmitting(false);
        return;
      }

      try {
        if (setupIntent && setupIntent.payment_method) {
          GTag.event("boost_add_card", "Click");
          await addPaymentMethod({
            id: setupIntent.payment_method,
            phone: formValues.phone.replace(/\(|\)|-/g, ""),
          });
          history.push(`/insurance/boost/edit/${id}/payment`);
        }
      } catch (err) {
        if (err.data) {
          setServerError(values(err.data.errors[0])[0]);
        }
        setIsSubmitting(false);
      }
    }
  }

  function onValidate(values: IFormValues) {
    const validateErrors = validate(values);
    setErrors(validateErrors);
    return validateErrors;
  }

  function onSubmit(
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  ) {
    return (event: React.FormEvent<HTMLFormElement>) => {
      if (
        !stripeErrors.cardNumber &&
        !stripeErrors.cardExpiryCvc &&
        stripe &&
        elements
      ) {
        const card = elements.getElement("cardNumber");
        if (!card) {
          return;
        }
        stripe
          .createPaymentMethod({
            type: "card",
            card,
          })
          .then(({ error }) => {
            if (error) {
              const messages: IStripeFields<string> = {
                cardNumber: !stripeCompletion.cardNumber
                  ? stripeMessages.cardNumber
                  : undefined,
                cardExpiry: !stripeCompletion.cardExpiry
                  ? stripeMessages.cardExpiry
                  : undefined,
                cardCvc: !stripeCompletion.cardCvc
                  ? stripeMessages.cardCvc
                  : undefined,
                cardExpiryCvc:
                  !stripeCompletion.cardExpiry || !stripeCompletion.cardCvc
                    ? stripeMessages.cardExpiryCvc
                    : undefined,
              };
              setStripeErrorsCurrent(messages);
              setStripeErrors(messages);
            }
          });
        handleSubmit(event);
      } else {
        handleSubmit(event);
      }
    };
  }

  const isReady =
    isStripeReady.cardNumber &&
    isStripeReady.cardCvc &&
    isStripeReady.cardExpiry;

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>Card details</Header>
        <div className={styles.subHeader}>
          Please enter your payment information.
        </div>
      </SafeAreaContainer>
      {!isReady ? <Spinner /> : null}
      <Formik<IFormValues>
        initialValues={{
          firstName: user ? user.firstName : "",
          lastName: user ? user.lastName : "",
          zip: user ? user.zip : "",
          phone: user ? formatPhone(user.phone) || "" : "",
        }}
        validateOnBlur
        validateOnChange={false}
        validate={onValidate}
        enableReinitialize
        onSubmit={onClickSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
        }: FormikProps<IFormValues>) => (
          <form onSubmit={onSubmit(handleSubmit)} className={cx(styles.form)}>
            <SafeAreaContainer
              className={cx(styles.container, { [styles.hidden]: !isReady })}
            >
              <div className={styles.formGroup}>
                <div className={styles.inputContainer}>
                  <Input
                    type="text"
                    className={cx(styles.input, {
                      [styles.error]: touched.firstName && errors.firstName,
                      [styles.errorText]:
                        touched.firstName &&
                        errors.firstName &&
                        values.firstName,
                    })}
                    placeholder="First name"
                    name="firstName"
                    autoComplete="off"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.firstName && (
                    <div className={styles.errorMessage}>
                      {touched.firstName && errors.firstName}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.inputContainer}>
                  <Input
                    type="text"
                    className={cx(styles.input, {
                      [styles.error]: touched.lastName && errors.lastName,
                      [styles.errorText]:
                        touched.lastName && errors.lastName && values.lastName,
                    })}
                    placeholder="Last name"
                    name="lastName"
                    autoComplete="off"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lastName && (
                    <div className={styles.errorMessage}>
                      {touched.lastName && errors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className={cx(styles.formGroup, "fs-block")}>
                <CardElement
                  iconOnRight
                  cardBrand={cardBrand}
                  error={stripeErrors.cardNumber}
                  type="number"
                  placeholder="Credit Card Number"
                  onChange={onStripeChange}
                  onFocus={onStripeFocus("cardNumber")}
                  onBlur={onStripeBlur("cardNumber")}
                  onReady={onStripeReady("cardNumber")}
                />
              </div>
              <div
                className={cx(
                  styles.formGroup,
                  "fs-block",
                  styles.noMarginBottom
                )}
              >
                <CardElement
                  hideIcon
                  hideErrorMessage
                  className={styles.expiryInput}
                  error={stripeErrors.cardExpiry}
                  type="expiry"
                  placeholder="Expiration Date"
                  onChange={onStripeChange}
                  onFocus={onStripeFocus("cardExpiry")}
                  onBlur={onStripeBlur("cardExpiry")}
                  onReady={onStripeReady("cardExpiry")}
                />
                <CardElement
                  hideIcon
                  hideErrorMessage
                  className={styles.cvvInput}
                  error={stripeErrors.cardCvc}
                  type="cvc"
                  placeholder="CVC"
                  onChange={onStripeChange}
                  onFocus={onStripeFocus("cardCvc")}
                  onBlur={onStripeBlur("cardCvc")}
                  onReady={onStripeReady("cardCvc")}
                />
                <Input
                  type="text"
                  className={cx(styles.input, styles.zipInput, {
                    [styles.error]: touched.zip && errors.zip,
                    [styles.errorText]: touched.zip && errors.zip && values.zip,
                  })}
                  placeholder="Zip Code"
                  name="zip"
                  autoComplete="off"
                  mask={ZIP_MASK}
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {(stripeErrors.cardExpiryCvc || errors.zip) && (
                <div className={styles.errorMessage}>
                  {stripeErrors.cardExpiryCvc || errors.zip}
                </div>
              )}
              <div className={styles.formGroup}>
                <div className={styles.inputContainer}>
                  <Input
                    type="tel"
                    className={cx(styles.input, {
                      [styles.error]: touched.phone && errors.phone,
                      [styles.errorText]:
                        touched.phone && errors.phone && values.phone,
                    })}
                    placeholder="Mobile Number"
                    name="phone"
                    mask={PHONE_MASK}
                    formatChars={{
                      "9": "[0-9]",
                      "*": "",
                    }}
                    autoComplete="off"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phone && errors.phone && (
                    <div className={styles.errorMessage}>
                      {touched.phone && errors.phone}
                    </div>
                  )}
                </div>
              </div>
              {serverError || errors.stripe ? (
                <div className={styles.errorBlock}>
                  {serverError || errors.stripe}
                </div>
              ) : null}
            </SafeAreaContainer>
            <Button
              type="submit"
              className={styles.btn}
              disabled={isSubmitting}
            >
              Save payment method
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

AddCard.displayName = "AddCard";
export default connect(
  (state) => ({
    user: getUser(state),
  }),
  {
    loadingStart,
    loadingEnd,
    addPaymentMethod,
  }
)(withStripe(AddCard));
