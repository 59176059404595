import client from "../../helpers/api/umbrella";
import lemonade from "../../helpers/api";
import mergePath from "../../helpers/mergePath";

export function exchangeToken(data: {
  token: string;
}): Promise<{ token: string }> {
  return client.post(mergePath("umbrella", "/auth/token/exchange"), data, {
    credentials: true
  });
}

export function refreshToken(data: {
  token: string;
}): Promise<{ token: string }> {
  return client.post(mergePath("umbrella", "/auth/token/refresh"), data, {
    credentials: true
  });
}

export function login(data: { token: string }): Promise<{ token: string }> {
  return lemonade.post(mergePath("api", "/residents/login"), data);
}
