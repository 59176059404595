import createApiAction from "../../helpers/apiActionCreator";
import * as api from "./user.api";
import { IUser, IPaymentMethod } from "./types";

export const FETCH_USER = "FETCH_USER";
export const CONFIRM_INFORMATION = "CONFIRM_INFORMATION";
export const CONFIRM_ADDRESS = "CONFIRM_ADDRESS";
export const REJECT_ADDRESS = "REJECT_ADDRESS";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD ";
export const PAYMENT_METHODS_FETCH = "PAYMENT_METHODS_FETCH";
export const PAYMENT_METHOD_REMOVE = "PAYMENT_METHOD_REMOVE";
export const PAYMENT_METHOD_SET_DEFAULT = "PAYMENT_METHOD_SET_DEFAULT";

export type FetchUser = (data?: { skipLoading: boolean }) => Promise<IUser>;
export function fetchUser(data?: { skipLoading: boolean }) {
  return createApiAction(FETCH_USER, api.getUser)({}, {}, data?.skipLoading);
}

export type ConfirmInformation = (data: {
  firstName: string;
  lastName: string;
  phone: string;
  unit: string;
  email: string;
}) => Promise<IUser>;
export function confirmInformation(data: {
  firstName: string;
  lastName: string;
  phone: string;
  unit: string;
  email: string;
}) {
  return createApiAction(CONFIRM_INFORMATION, api.confirmInformation)(data);
}

export type ConfirmAddress = () => Promise<IUser>;
export function confirmAddress() {
  return createApiAction(CONFIRM_ADDRESS, api.confirmAddress)({});
}

export type RejectAddress = () => Promise<IUser>;
export function rejectAddress() {
  return createApiAction(REJECT_ADDRESS, api.rejectAddress)({});
}

export type AddPaymentMethod = ({
  id,
  phone,
}: {
  id: string;
  phone: string;
}) => Promise<void>;
export function addPaymentMethod({ id, phone }: { id: string; phone: string }) {
  return createApiAction(
    ADD_PAYMENT_METHOD,
    api.addPaymentMethod
  )({ id, phone });
}

export type FetchPaymentMethods = () => Promise<IPaymentMethod[]>;
export function fetchPaymentMethods() {
  return createApiAction(PAYMENT_METHODS_FETCH, api.getPaymentMethods)({});
}

export type RemovePaymentMethod = (id: string) => Promise<void>;
export function removePaymentMethod(id: string) {
  return createApiAction(
    PAYMENT_METHOD_REMOVE,
    api.removePaymentMethod
  )({ id });
}

export type SetDefaultPaymentMethod = (id: string) => Promise<IUser>;
export function setDefaultPaymentMethod(id: string) {
  return createApiAction(
    PAYMENT_METHOD_SET_DEFAULT,
    api.setDefaultPaymentMethod
  )({ id }, {}, true);
}
