import React from "react";
import { RouteComponentProps } from "react-router-dom";

import eventEmitter from "./eventEmitter";
import { DELAY_BEFORE_REMOVE_MSEC } from "./constants";
import { IStackItem, IModalProviderState } from "./types";

interface IProps extends RouteComponentProps {
  showModal: (...args: any[]) => {};
  hideModal: (id: string) => {};
  removeModal: (id: string) => {};
  modalProvider?: IModalProviderState;
}
export default function BaseModalProvider({
  showModal,
  hideModal,
  removeModal,
  modalProvider
}: IProps) {
  React.useEffect(() => {
    eventEmitter.on("showModal", (args: any[]) => showModal(...args));
    return () => {
      eventEmitter.removeListener("showModal");
    };
  });

  function onHideModal(
    id: string,
    delayBeforeRemoveMsec: number = DELAY_BEFORE_REMOVE_MSEC
  ) {
    hideModal(id);
    setTimeout(() => {
      removeModal(id);
    }, delayBeforeRemoveMsec);
  }

  if (!modalProvider) {
    return null;
  }

  return (
    <div>
      {modalProvider.stack.map((modal: IStackItem) =>
        React.cloneElement(modal.component, {
          key: `MODAL_${modal.id}`,
          show: modal.show,
          hideModal: () => onHideModal(modal.id, modal.delayBeforeRemoveMsec),
          ...modal.component.props
        })
      )}
    </div>
  );
}
