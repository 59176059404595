import * as yup from "yup";

export default yup.object().shape({
  isDamaged: yup.boolean().notRequired(),
  policy: yup
    .array()
    .of(
      yup
        .mixed()
        .required("Policy is required.")
        .test(
          "fileSize",
          "Sorry, file size is too large. The maximum file size allowed is set to 10Mb.",
          (value: File) => value && value.size <= 10400000
        )
        .test(
          "fileType",
          "Sorry, this file type is not allowed.",
          (value: File) =>
            value &&
            (value.type.includes("image/") ||
              value.type.includes("application/pdf"))
        )
        .test(
          "fileName",
          "Sorry, file name is too long. The maximum file name is 250 characters.",
          (value: File) => value && value.name.length <= 250
        )
    )
    .min(1, "You must upload at least 1 file.")
    .max(15, "Maximum 15 files are allowed.")
    .required("Policy is required."),
  companyName: yup.string().min(2).max(50).required(),
  companyNameCustom: yup
    .string()
    .when("companyName", (val: string, schema: yup.StringSchema) =>
      val === "Other" ? schema.required() : schema
    ),

  coverageAmount: yup
    .number()
    .transform((o, v) => parseFloat(v.replace(/,/g, "")))
    .min(1)
    .max(Number.MAX_SAFE_INTEGER)
    .required(),

  isContinuous: yup.boolean(),

  expiresAt: yup
    .date()
    .when("isContinuous", (isContinuous: boolean, schema: yup.DateSchema) =>
      !isContinuous ? schema.min(new Date()).required() : schema
    ),

  startDate: yup
    .date()
    .when(
      "expiresAt",
      (expiresAt: Date, schema: yup.DateSchema) =>
        expiresAt && schema.max(expiresAt)
    )
    .required(),
});
