import * as React from "react";
import capitalize from "lodash/capitalize";
import { rangeToCents, formatMoneyInCents } from "../../../helpers/common";
import { IBoostSummary } from "../../../resources/insurance/types";

import { ReactComponent as GolfIcon } from "../../../components/icons/golf_icon.svg";
import { ReactComponent as EarthquakeIcon } from "../../../components/icons/earthquake_icon.svg";
import { ReactComponent as IdentityFraudIcon } from "../../../components/icons/identity_fraud_icon.svg";
import { ReactComponent as MoldRemediationIcon } from "../../../components/icons/mold_remediation_icon.svg";
import { ReactComponent as ReplacementCostIcon } from "../../../components/icons/replacement_cost_icon.svg";
import { ReactComponent as WindStormIcon } from "../../../components/icons/wind_storm_icon.svg";
import { ReactComponent as TheftIcon } from "../../../components/icons/theft_icon.svg";
import { ReactComponent as PersonalPropertyIcon } from "../../../components/icons/personal_property_icon.svg";
import { ReactComponent as EnhancementIcon } from "../../../components/icons/enhancement_icon.svg";

export interface IExtraCoverage {
  title: string;
  title2?: string;
  value: string;
  description?: React.ReactElement;
  defaultLimit?: { [key: string]: number };
  isSelect?: boolean;
  top?: boolean;
  options?: { [key: string]: number[] };
  values?: { key: string; value: string }[];
  factors?: {
    options: { [key: string]: number[] };
    description: React.ReactElement;
    title: string;
    value: string;
  }[];
  availableStates?: string[];
  notAvailableStates?: string[];
  requiredStates?: string[];
  icon?: React.ReactElement;
}

export const EXTRA_COVERAGES: IExtraCoverage[] = [
  {
    title: "Personal Property - Extra Coverage",
    value: "scheduledProperty",
    top: true,
    icon: <PersonalPropertyIcon />,
    description: <div>Protect your valuables from damage or loss.</div>,
    factors: [
      {
        title: "Jewelry",
        value: "jewelry",
        description: (
          <div>
            Set the coverage amount for stolen or damaged jewelry items.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Fine Arts",
        value: "fine_arts",
        description: (
          <div>
            Set the coverage amount for stolen or damaged fine art items at your{" "}
            <b>residence</b>.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Bicycles",
        value: "bicycles",
        description: (
          <div>
            Set the coverage amount for stolen or damaged bicycles (covers
            multiple bikes).
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Cameras and equipment",
        value: "cameras",
        description: (
          <div>
            Set the coverage amount for stolen or damaged cameras and related
            equipment (like lenses).
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Musical Instruments",
        value: "musical_instruments",
        description: (
          <div>
            Set the coverage amount for stolen or damaged musical instruments.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Silverware",
        value: "silverware_and_goldware",
        description: (
          <div>
            Set the coverage amount for stolen or damaged silverware (includes
            goldware, platinumware, pewterware, and items plated w/ silver,
            gold, or platinum).
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Stamps",
        value: "stamp_collection",
        description: (
          <div>
            Set the coverage amount for stolen or damaged postage stamps.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Rare and Current Coins",
        value: "coin_collection",
        description: (
          <div>
            Set the coverage amount for stolen or damaged rare and current coins
            (includes medals, money, and bank notes).
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Trading Cards",
        value: "trading_cards",
        description: (
          <div>
            Set the coverage amount for stolen or damaged trading cards.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Furs",
        value: "furs",
        description: (
          <div>
            Set the coverage amount for stolen or damaged furs and garments
            trimmed with fur.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Firearms and accessories",
        value: "guns",
        description: (
          <div>
            Set the coverage amount for stolen or damaged firearms and related
            accessories.
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
      {
        title: "Golf Equipment",
        value: "golf_equipment",
        description: (
          <div>
            Set the coverage amount for stolen or damaged golf equipment
            (includes clubs, clothing, and equipment; excludes golf carts).
          </div>
        ),
        options: {
          default: rangeToCents(100, 10000, 100),
        },
      },
    ],
  },
  {
    title: "Policy Enhancements",
    value: "enhancementType",
    top: true,
    description: (
      <div>
        Increase coverage limits for personal property covered under the Base
        policy. See{" "}
        <a
          href="/enhancements"
          target="_blank"
          style={{
            fontWeight: "bold",
            color: "#0088FF",
            textDecoration: "none",
          }}
        >
          Coverage Comparison
        </a>{" "}
        for more information.
      </div>
    ),
    icon: <EnhancementIcon />,
    values: [
      {
        key: "",
        value: "Base (included)",
      },
      {
        key: "silver",
        value: "Silver",
      },
      {
        key: "gold",
        value: "Gold",
      },
      {
        key: "platinum",
        value: "Platinum",
      },
    ],
  },
  {
    title: "Identity Fraud",
    value: "identityFraud",
    description: (
      <div>
        Covers necessary & reasonable fees (including legal fees) and lost
        earnings due to time away from work related to identity fraud.
      </div>
    ),
    top: true,
    icon: <IdentityFraudIcon />,
    isSelect: true,
    options: {
      default: [5000 * 100, 10000 * 100, 15000 * 100],
    },
    defaultLimit: {
      default: 5000 * 100,
    },
  },
  {
    title: "Theft Coverage",
    value: "theft",
    description: (
      <div>
        Your policy will now cover personal property <b>theft</b> (your base
        policy only covers <b>burglary</b>, which is limited to property stolen
        from breaking & entering into your insured residence).
      </div>
    ),
    top: true,
    icon: <TheftIcon />,
    requiredStates: ["NE", "NH", "CT"],
  },
  {
    title: "Earthquake Coverage",
    value: "earthquake",
    description: (
      <div>Personal property damaged by an earthquake will now be covered.</div>
    ),
    icon: <EarthquakeIcon />,
  },
  {
    title: "Windstorm & Hail Exclusion",
    value: "windstorm",
    description: (
      <div>
        Removes windstorm and hail as a covered peril from your base policy.
      </div>
    ),
    icon: <WindStormIcon />,
    availableStates: ["LA", "MS", "NC", "SC", "TX"],
  },
  {
    title: "Replacement Cost Coverage",
    value: "replacementCost",
    description: (
      <div>
        Instead of actual cash value, your policy will now pay the replacement
        cost to a damage, destroyed, or stolen item.
      </div>
    ),
    icon: <ReplacementCostIcon />,
    requiredStates: ["MN"],
  },
  {
    title: "Increased Mold Remediation",
    value: "increasedMoldRemediation",
    description: (
      <div>
        Increase coverage for the cost of removing mold and any related repairs.
      </div>
    ),
    icon: <MoldRemediationIcon />,
    availableStates: ["GA", "NY"],
    options: {
      default: rangeToCents(20100, 50000, 100),
      GA: rangeToCents(5100, 40000, 100),
    },
    defaultLimit: {
      default: 20100 * 100,
      GA: 5100 * 100,
    },
  },
  {
    title: "Golf Cart Coverage",
    value: "golfcart",
    description: (
      <div>Cover damage or loss to a golf cart you own for personal use.</div>
    ),
    icon: <GolfIcon />,
    availableStates: ["FL"],
  },
];

export const deductibleOptions = [250 * 100, 500 * 100, 1000 * 100];

export const notCoverWindHailHurricane = ["AR", "CT", "GA", "NY", "NJ", "OK"];
export const refundStates = ["GA", "MD", "MI", "MT", "NY", "VA"];

export type CoverageType =
  | "personalProperty"
  | "personalLiability"
  | "lossOfUse";

export interface ICoverage {
  key: CoverageType;
  title: string;
  description: string;
  options: number[];
}

export const COVERAGES: ICoverage[] = [
  {
    key: "personalProperty",
    title: "Personal Property",
    description:
      "We’ll cover damages to or loss of your personal property caused by fire, theft, smoke, and other named perils.",
    options: rangeToCents(10000, 40000, 5000),
  },
  {
    key: "personalLiability",
    title: "Personal Liability",
    description:
      "We’ll protect you against liability claims if a guest is injured on your property or if you damage the property of others.",
    options: [25000 * 100, 50000 * 100, 100000 * 100, 300000 * 100],
  },
  {
    key: "lossOfUse",
    title: "Loss of Use",
    description:
      "If your place becomes uninhabitable, we’ll pay to put you up in a hotel or rental home, and cover living expenses necessary to maintain your normal standard of living. This coverage limit is 20% of your Personal Property coverage.",
    options: [],
  },
];

export const getQuoteSummary = (summary: IBoostSummary) => {
  const primary = [
    {
      name: "Deductible",
      value: formatMoneyInCents(summary.baseDeductible, false),
    },
    {
      name: "Contents",
      value: formatMoneyInCents(summary.personalProperty, false),
    },
    {
      name: "Personal Liability",
      value: formatMoneyInCents(summary.personalLiability, false),
    },
  ];
  const details = [
    {
      name: "Loss of Use",
      value: formatMoneyInCents(summary.lossOfUse, false),
    },
    {
      name: "Animal Liability *",
      value: formatMoneyInCents(summary.animalLiability, false),
    },
    {
      name: "Medical Payments *",
      value: formatMoneyInCents(summary.medicalPayments, false),
    },
    {
      name: "Damage to Property of Others *",
      value: formatMoneyInCents(summary.damangeToProperty, false),
    },
  ];
  if (summary.enhancementType) {
    details.push({
      name: "Enhancement Coverage",
      value: capitalize(summary.enhancementType),
    });
  }
  if (summary.extraCoverages && summary.extraCoverages.length > 0) {
    EXTRA_COVERAGES.forEach((extraCov) => {
      const cov = summary.extraCoverages.find((x) => x.id === extraCov.value);
      if (!cov) {
        return;
      }
      if (cov.factors) {
        const amount = Object.keys(cov.factors).reduce(
          (prev, cur) =>
            prev + (cov && cov.factors ? cov.factors[cur] || 0 : 0),
          0
        );
        details.push({
          name: extraCov.title,
          value: formatMoneyInCents(amount, false),
        });
        return;
      }
      if (cov.limit) {
        details.push({
          name: extraCov.title,
          value: formatMoneyInCents(cov.limit, false),
        });
        return;
      }
      details.push({
        name: extraCov.title,
        value: "N/A",
      });
    });
  }
  return { primary, details };
};
