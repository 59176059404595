import * as React from "react";
import cx from "classnames";
import { formatMoney, formatMoneyInCents } from "../../../../helpers/common";
import Button from "../../../../components/button";
import Input from "../../../../components/input";

import { ReactComponent as MinusIcon } from "../../../../components/icons/minus_icon.svg";
import { ReactComponent as PlusIcon } from "../../../../components/icons/plus_icon.svg";

import styles from "./coverage.module.css";

interface IProps {
  "data-id"?: string;
  cents?: boolean;
  className?: string;
  title?: string;
  description?: string | React.ReactElement;
  value: number;
  options: number[];
  onChange: (value: number) => void;
  onOutOfRange?: () => void;
  readonly?: boolean;
  editable?: boolean;
  disabled?: boolean;
}

export default function Coverage({
  className,
  cents,
  title,
  description,
  value,
  options,
  onChange,
  onOutOfRange,
  readonly,
  editable,
  disabled,
  ...props
}: IProps) {
  const [minusDisabled, setMinusDisabled] = React.useState(
    options.indexOf(value) === 0
  );
  const [plusDisabled, setPlusDisabled] = React.useState(
    options.length === 1 || options.indexOf(value) === options.length - 1
  );

  React.useEffect(() => {
    const idx = options.indexOf(value);
    if (options.length === 1) {
      setPlusDisabled(true);
      setMinusDisabled(true);
    } else if (idx === 0) {
      setMinusDisabled(true);
      setPlusDisabled(false);
    } else if (idx === options.length - 1) {
      setPlusDisabled(true);
      setMinusDisabled(false);
    } else {
      setPlusDisabled(false);
      setMinusDisabled(false);
    }
  }, [value, options, setMinusDisabled, setPlusDisabled]);

  function increase() {
    const currentIdx = options.indexOf(value);
    if (currentIdx < options.length - 1) {
      onChange(options[currentIdx + 1]);
    }
  }

  function decrease() {
    const currentIdx = options.indexOf(value);
    if (currentIdx > 0) {
      onChange(options[currentIdx - 1]);
    }
  }

  function onAmountChange(data: React.FocusEvent<HTMLInputElement>) {
    const amount = parseFloat(data.target.value.replace(/,/g, ""));
    onChange(cents ? amount * 100 : amount);
  }

  function onAmountBlur(data: React.FocusEvent<HTMLInputElement>) {
    let amount = parseFloat(data.target.value.replace(/,/g, ""));
    if (cents) {
      amount = amount * 100;
    }
    let currentAmount = options[0];
    let diff = Math.abs(amount - options[0]);
    for (let i = 1; i < options.length; i += 1) {
      const currentDiff = Math.abs(amount - options[i]);
      if (currentDiff < diff) {
        diff = currentDiff;
        currentAmount = options[i];
      }
    }

    onChange(currentAmount);

    if (
      onOutOfRange &&
      (amount < options[0] || amount > options[options.length - 1])
    ) {
      onOutOfRange();
    }
  }

  return (
    <div
      className={cx(
        styles.setting,
        className,
        disabled ? styles.disabled : null
      )}
    >
      {title && (
        <div className={styles.settingTitle}>
          <h4>{title}</h4>
        </div>
      )}
      <div className={styles.settingText}>{description}</div>
      {title && <hr className={styles.line} />}
      <div
        className={cx(styles.settingControls, !title ? styles.noTitle : null)}
      >
        {readonly ? null : (
          <Button
            onClick={decrease}
            disabled={minusDisabled || disabled}
            data-id={`${props["data-id"]}MinusBtn`}
          >
            <MinusIcon className={styles.icon} />
          </Button>
        )}
        {(!editable || disabled) && (
          <div
            className={styles.settingAmount}
            data-id={`${props["data-id"]}Value`}
          >
            {cents
              ? formatMoneyInCents(value, false)
              : formatMoney(value, false)}
          </div>
        )}
        {editable && !disabled && (
          <div className={styles.settingAmount}>
            <Input
              data-id={`${props["data-id"]}Input`}
              prefix="$"
              type="number"
              currency
              className={styles.amountInput}
              value={cents ? value / 100 : value}
              onBlur={onAmountBlur}
              onChange={onAmountChange}
            />
          </div>
        )}
        {readonly ? null : (
          <Button
            onClick={increase}
            disabled={plusDisabled || disabled}
            data-id={`${props["data-id"]}PlusBtn`}
          >
            <PlusIcon className={styles.icon} />
          </Button>
        )}
      </div>
    </div>
  );
}
Coverage.displayName = "Coverage";
