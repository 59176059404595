import * as React from "react";
import cx from "classnames";

import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { ReactComponent as GreenCircle } from "../icons/green-circle.svg";

import styles from "./menu-item.module.css";

interface IProps {
  "data-id"?: string;
  title: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}

export default function MenuItem({
  title,
  active,
  onClick,
  className,
  ...props
}: IProps) {
  return (
    <div
      className={cx(styles.setting, className)}
      onClick={onClick}
      data-id={`open${props["data-id"]}`}
    >
      {active && <GreenCircle className={styles.circleIcon} />}
      <div className={styles.settingText}>
        <h4>{title}</h4>
      </div>
      <ArrowIcon className={styles.arrowIcon} />
    </div>
  );
}
MenuItem.displayName = "MenuItem";
