import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text";
import { SafeAreaContainer } from "../../../components/container";
import Button from "../../../components/button";
import Header from "../../../components/header";
import { getQuote } from "../../../resources/insurance/insurance.selectors";
import { IQuote } from "../../../resources/insurance/types";
import { IStore } from "../../../resources/types";

import styles from "./error.module.css";

interface IProps {
  quote?: IQuote;
}

function CheckPaymentError({ quote }: IProps) {
  const history = useHistory();

  if (!quote) {
    return null;
  }

  const onClick = (quoteId: string) => () =>
    history.replace(`/insurance/edit/${quoteId}/payment/add`);

  return (
    <SafeAreaContainer>
      <Header className={styles.title}>Check payment</Header>
      <Text className={styles.description}>
        There was an issue with the payment method you provided. Please double
        check the information or add another form of payment.
      </Text>
      <Button onClick={onClick(quote._id)} className={styles.btn}>
        Change payment method
      </Button>
    </SafeAreaContainer>
  );
}

CheckPaymentError.displayName = "CheckPaymentError";
export default connect((state: IStore) => ({
  quote: getQuote(state)
}))(CheckPaymentError);
