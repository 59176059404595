import * as React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";
import Spinner from "../../../../components/spinner";
// import Pricing from "../components/pricing";

import { IStore } from "../../../../resources/types";
import { ReactComponent as ArrowIcon } from "../../../../components/icons/arrow.svg";
import { ReactComponent as ChatIcon } from "../../../../components/icons/chat-icon.svg";

import {
  fetchClaimsBoost,
  FetchClaimsBoost,
} from "../../../../resources/insurance/insurance.actions";
import { getBoostClaims } from "../../../../resources/insurance/insurance.selectors";
import {
  IBoostClaim,
  CLAIMS_STATUS,
} from "../../../../resources/insurance/types";

import GTag from "../../../../gtag";

import styles from "./claims-list.module.css";

interface IProps {
  claims?: IBoostClaim[];
  fetchClaimsBoost: FetchClaimsBoost;
}

function ClaimsList({ claims, fetchClaimsBoost }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("claims_list", "Page");
  }, []);

  React.useEffect(() => {
    fetchClaimsBoost({ policyId: id });
  }, [fetchClaimsBoost, id]);

  const openNewClaim = () => {
    history.push(`/insurance/boost/policy/${id}/claims/new`);
  };

  const openClaim = (claim: IBoostClaim) => {
    history.push(`/insurance/boost/policy/${id}/claims/${claim._id}`);
  };

  if (!claims) {
    return <Spinner />;
  }

  return (
    <div className={styles.page}>
      <SafeAreaContainer className={styles.head}>
        <Header>Claims</Header>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.container}>
        {claims.map((claim, index) => (
          <div
            className={styles.setting}
            role="button"
            onClick={() => openClaim(claim)}
            data-id={`openClaim${index}`}
          >
            <div className={styles.settingText}>
              <h4>
                Submitted on{" "}
                {moment.utc(claim.dateOfLoss).format("MMM D, YYYY")}
              </h4>
              <div>{CLAIMS_STATUS[claim.status]}</div>
            </div>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        ))}
        <div>
          <button
            className={styles.link}
            onClick={openNewClaim}
            data-id="addClaimBtn"
          >
            + File a claim
          </button>
        </div>
      </SafeAreaContainer>
      <div className={styles.support}>
        <h4>Got questions?</h4>
        <span>
          Contact us at{" "}
          <a href="mailto:services@latch.com">services@latch.com</a>
        </span>
        <ChatIcon />
      </div>
    </div>
  );
}

ClaimsList.displayName = "ClaimsList";
export default connect(
  (state: IStore) => {
    return {
      claims: getBoostClaims(state),
    };
  },
  {
    fetchClaimsBoost,
  }
)(ClaimsList);
