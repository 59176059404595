import * as yup from "yup";

export interface IFormValues {
  type: string;
  name: string;
  businessName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}

export default yup.object().shape({
  type: yup.string().required(),
  name: yup
    .string()
    .min(2, 'The field "Name" should have a minimum length of 2 characters.')
    .max(20, 'The field "Name" should have a maximum length of 20 characters.')
    .required("Name is required."),
  businessName: yup.string().when("type", {
    is: "business",
    then: yup
      .string()
      .min(
        2,
        'The field "Business name" should have a minimum length of 2 characters.'
      )
      .max(
        20,
        'The field "Business name" should have a maximum length of 20 characters.'
      )
      .required("Business name is required."),
  }),
  email: yup
    .string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'The field "Email" should be an email address.'
    )
    .required("Email is required."),
  address1: yup
    .string()
    .min(2, 'The field "Address" should have a minimum length of 2 characters.')
    .max(
      20,
      'The field "Address" should have a maximum length of 20 characters.'
    )
    .required("Address is required."),
  address2: yup
    .string()
    .max(
      20,
      'The field "Apartment / Unit #" should have a maximum length of 20 characters.'
    ),
  city: yup
    .string()
    .min(2, 'The field "City" should have a minimum length of 2 characters.')
    .max(20, 'The field "City" should have a maximum length of 20 characters.')
    .required("City is required."),
  state: yup.string().required("State is required."),
  zip: yup
    .string()
    .min(2)
    .max(20)
    .required("Postal code is required.")
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please enter a valid Postal code."),
});
