import * as React from "react";
import cx from "classnames";
import _ from "lodash";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { SafeAreaContainer } from "../../components/container";
import Header from "../../components/header";
import Spinner from "../../components/spinner";

import PaymentItem from "./payment-item";

import { IStore } from "../../resources/types";

import {
  fetchPayments,
  FetchPayments,
} from "../../resources/payment/payment.actions";
import { getPayments } from "../../resources/payment/payment.selectors";
import { IPayment } from "../../resources/payment/types";

import GTag from "../../gtag";

import styles from "./payment-history.module.css";

interface IProps {
  payments?: IPayment[];
  fetchPayments: FetchPayments;
}

function PaymentHistory({ payments, fetchPayments }: IProps) {
  React.useEffect(() => {
    GTag.event("payment_history", "Page");
  }, []);

  React.useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  if (!payments) {
    return <Spinner />;
  }

  const scheduledPayment = payments.find((x) => !x._id);

  const paymentsGrouped = _.chain(payments.filter((x) => !!x._id))
    .groupBy((x) => x.policyNumber)
    .map((value: IPayment[], key: string) => ({ key, payments: value }))
    .orderBy((x) => x.payments[0].orderIndex)
    .value();

  return (
    <div className={styles.page}>
      <SafeAreaContainer className={styles.head}>
        <Header>Payment history</Header>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.container}>
        {paymentsGrouped.map(
          (item: { key: string; payments: IPayment[] }, index) => (
            <div key={item.key}>
              <div
                className={cx(
                  styles.separator,
                  index === 0 ? styles.first : null
                )}
              >
                <div className={styles.date}>
                  {moment(item.payments[0].policyStart).format("MMM D, YYYY")}
                  {" — "}
                  {moment(item.payments[0].policyEnd).format("MMM D, YYYY")}
                </div>
                <hr className={styles.line} />
                <div className={styles.number}>Policy number: {item.key}</div>
              </div>
              {index === 0 && scheduledPayment && (
                <PaymentItem payment={scheduledPayment} />
              )}
              {item.payments.map((payment: IPayment) => (
                <PaymentItem key={payment._id} payment={payment} />
              ))}
            </div>
          )
        )}
      </SafeAreaContainer>
    </div>
  );
}

PaymentHistory.displayName = "PaymentHistory";
export default connect(
  (state: IStore) => {
    return {
      payments: getPayments(state),
    };
  },
  {
    fetchPayments,
  }
)(PaymentHistory);
