import * as React from "react";
import cx from "classnames";

import styles from "./container.module.css";

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export default ({ children, className }: IProps) => {
  return (
    <div id="container" className={cx(styles.container, className)}>
      {children}
    </div>
  );
};

export const SafeAreaContainer = ({ children, className }: IProps) => {
  return (
    <div className={cx(styles.safeAreaContainer, className)}>{children}</div>
  );
};
