import React from "react";
import {
  Switch,
  Route,
  withRouter,
  matchPath,
  RouteComponentProps,
} from "react-router-dom";
import NotFound from "./errors/not-found";
import PrivateRoute from "../components/private-route";
import { useAuth } from "../auth";
import InitializeAuth from "../initialize";
import InitializeAuth0 from "../initializeAuth0";
import routes, { IRoute } from "../routes";

import GTag from "../gtag";

interface IProps extends RouteComponentProps {}

function Routes({ location }: IProps) {
  const authData = useAuth();
  const Initialize = authData ? InitializeAuth : InitializeAuth0;
  const items = routes.map((route: IRoute) => {
    if (route.isPrivate) {
      return (
        matchPath(location.pathname, {
          path: route.path,
          exact: route.exact,
        }) && (
          <Initialize key={route.path}>
            <PrivateRoute
              path={route.path}
              exact={route.exact}
              Component={(props: object) => {
                GTag.pageview(window.location.pathname);
                return (
                  <route.Layout
                    {...props}
                    className={route.className}
                    layoutClass={route.layoutClass}
                    headerClass={route.headerClass}
                    showBackButton={route.showBackButton}
                    isBlackButton={route.isBlackButton}
                    isCustomHeader={route.isCustomHeader}
                  >
                    <route.Component {...props} />
                  </route.Layout>
                );
              }}
            />
          </Initialize>
        )
      );
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        component={(props: object) => {
          GTag.pageview(window.location.pathname);
          return (
            <route.Layout
              {...props}
              className={route.className}
              layoutClass={route.layoutClass}
              headerClass={route.headerClass}
              showBackButton={route.showBackButton}
              isBlackButton={route.isBlackButton}
              isCustomHeader={route.isCustomHeader}
            >
              <route.Component {...props} />
            </route.Layout>
          );
        }}
      />
    );
  });

  return (
    <Switch>
      {items}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

Routes.displayName = "Routes";
export default withRouter(Routes);
