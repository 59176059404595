import * as React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import config from "../../../config";
import { formatMoneyInCents } from "../../../helpers/common";
import withConnectedQuote from "../../../hoc/quote.hoc";

import { SafeAreaContainer } from "../../../components/container";
import Header from "../../../components/header";
import Pricing from "../components/pricing";
import DateComponent from "../../../components/date/inline";
import Button from "../../../components/action-button";
import Checkbox from "../../../components/checkbox";

import { IStore } from "../../../resources/types";
import { ReactComponent as ArrowIcon } from "../../../components/icons/arrow.svg";
import { ReactComponent as LemonadeLogo } from "../../../components/icons/lemonade_logo.svg";

import {
  updateQuote,
  UpdateQuote,
  fetchQuote,
  FetchQuote,
} from "../../../resources/insurance/insurance.actions";
import { IQuote } from "../../../resources/insurance/types";
import { fetchUser, FetchUser } from "../../../resources/user/user.actions";
import { getUser } from "../../../resources/user/user.selectors";
import { IUser } from "../../../resources/user/types";

import GTag from "../../../gtag";

import styles from "./insurance.module.css";

interface IProps {
  user: IUser;
  quote: IQuote;
  fetchQuote: FetchQuote;
  updateQuote: UpdateQuote;
  fetchUser: FetchUser;
}

function EditInsurance({
  fetchUser,
  updateQuote,
  quote,
  user,
  fetchQuote,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isAgree, setIsAgree] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [startDate, setStartDate] = React.useState<Date>(
    new Date(moment(quote.effectiveDate.currentValue * 1000).valueOf())
  );

  React.useEffect(() => {
    fetchQuote();
    GTag.event("lemonade_edit_policy", "Page");
  }, [fetchQuote]);

  React.useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [fetchUser, user]);

  const mustAgree = ["CA"].includes(user.state);

  const onEffectiveDateChange = async (date: Date) => {
    setStartDate(date);

    GTag.event("lemonade_date_change", "Click");
    await updateQuote({ effectiveDate: date });
  };

  const openCoverage = () => {
    GTag.event("lemonade_coverage", "Page");
    history.push(`/insurance/edit/${id}/coverage`);
  };
  const openDeductible = () => {
    GTag.event("lemonade_deductible", "Page");
    history.push(`/insurance/edit/${id}/deductible`);
  };

  const pay = () => {
    setServerError("");
    if (mustAgree && !isAgree) {
      setServerError(
        "You must agree to receive policy documents by email to continue."
      );
      return;
    }
    GTag.event("lemonade_payment", "Page");
    history.push(`/insurance/edit/${id}/payment`);
  };

  const endDate = moment(
    moment
      .tz(quote.effectiveDate.maxValue * 1000, config.defaultTimezone)
      .format("YYYY-MM-DD")
  ).toDate();

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>Build your quote</Header>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.container}>
        <Pricing amount={quote.monthlyPremiumInCents} period={"month"} />
        {quote.installmentFee && quote.installmentFee > 0 && (
          <div className={styles.fee}>
            <i>Your quote includes a $1 monthly installment fee.</i>
          </div>
        )}
        <div className={styles.date}>
          <DateComponent
            startDate={moment()
              .tz(config.defaultTimezone)
              .add(1, "day")
              .toDate()}
            endDate={endDate}
            date={startDate}
            onChange={onEffectiveDateChange}
            readonly={quote.status === "PAID"}
          />
        </div>

        <hr className={styles.line} />

        <div className={styles.descriptionText}>
          Customize your coverage amounts and choose your deductible.
        </div>

        <div
          className={styles.setting}
          role="button"
          onClick={openCoverage}
          data-id="openCoverage"
        >
          <div className={styles.settingText}>
            <h4>Coverage amounts</h4>
          </div>
          <ArrowIcon className={styles.arrowIcon} />
        </div>
        <div
          className={styles.setting}
          role="button"
          onClick={openDeductible}
          data-id="openDeductible"
        >
          <div className={styles.settingText}>
            <h4>Deductible</h4>
          </div>
          <ArrowIcon className={styles.arrowIcon} />
        </div>

        {mustAgree && (
          <div className={styles.checkboxContainer}>
            <Checkbox
              data-id="agree"
              className={styles.checkbox}
              checkmarkClassName={styles.checkmark}
              name="authorization"
              LabelComponent={() => (
                <div className={styles.checkboxLabel}>
                  I agree to receive policy documents by{" "}
                  <a
                    href="https://www.lemonade.com/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    email
                  </a>
                  .
                </div>
              )}
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
          </div>
        )}

        <div className={styles.policyText}>
          By getting a Lemonade policy, you acknowledge that your policy can't
          cover damages caused by wind, hail, or hurricanes.
        </div>
        <div className={styles.powered}>
          Provided by &nbsp;
          <LemonadeLogo />
        </div>

        {serverError && <div className={styles.errorBlock}>{serverError}</div>}
      </SafeAreaContainer>
      <>
        {quote.status === "PENDING" ? (
          <Button onClick={pay} data-id="payBtn">
            Pay {formatMoneyInCents(quote.monthlyPremiumInCents)} a month
          </Button>
        ) : null}
      </>
    </>
  );
}

EditInsurance.displayName = "EditInsurance";
export default withConnectedQuote(
  connect(
    (state: IStore) => {
      return {
        user: getUser(state),
      };
    },
    {
      fetchUser,
      fetchQuote,
      updateQuote,
    }
  )(EditInsurance)
);
