import { IStore } from "../types";

export function getQuote({ insurance }: IStore) {
  return insurance && insurance.quote;
}

export function getBoostQuote({ insurance }: IStore) {
  return insurance && insurance.boost;
}

export function getBoostPolicy({ insurance }: IStore) {
  return insurance && insurance.boostPolicy;
}

export function getPolicy({ insurance }: IStore) {
  return insurance && insurance.policy;
}

export function getInsurance({ insurance }: IStore) {
  return insurance && insurance.insurance;
}

export function getBoostCoverage({ insurance }: IStore) {
  return insurance && insurance.boostCoverage;
}

export function getBoostFactors({ insurance }: IStore) {
  return (insurance && insurance.factors) || {};
}

export function getBoostClaims({ insurance }: IStore) {
  return insurance && insurance.claims;
}

export function getBoostClaim({ insurance }: IStore) {
  return insurance && insurance.claim;
}

export function getPersonalLiabilityOptions({ insurance }: IStore) {
  return insurance && insurance.personalLiabilityOptions;
}
