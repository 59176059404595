import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUser } from "../../../resources/user/user.selectors";
import { IUser } from "../../../resources/user/types";
import Header from "../../../components/header";
import { SafeAreaContainer } from "../../../components/container";

import { IStore } from "../../../resources/types";

import GTag from "../../../gtag";

import styles from "./info.module.css";

interface IProps {
  user: IUser;
}

function Insurance({ user }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("learn_more", "Page");
  });

  const onFaqClick = () => {
    history.push("/insurance/faq");
  };

  const isBoost = user && user.insuranceType === "BOOST";

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.head}>Insurance policy</Header>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.descriptionContainer}>
        <div className={styles.description}>
          Renters insurance helps protect you when the unexpected happens. Latch
          Renters Insurance covers:
          <ul>
            <li>
              Your personal property in case of accidental damage or theft.
            </li>
            <li>
              Liability for medical bills if someone is injured at your
              residence.
            </li>
            <li>
              Living expenses - like temporary housing - if you can’t live in
              your place due to a covered risk, like a fire.
            </li>
            <li>
              Optional coverages that protect specific valuables items or add
              additional protections can be added to your policy as well.
            </li>
          </ul>
        </div>
        <div className={styles.description}>
          Coverage amounts and deductible can be customized to your needs.
          Policies renew monthly (except in CT and FL) and can be canceled at
          any time.
        </div>
        <div className={styles.description}>
          Latch Renters Insurance is produced by{" "}
          {isBoost
            ? "Latch Insurance Services, LLC and underwritten on behalf of Markel American Insurance Company."
            : "Lemonade, Inc."}
        </div>
        <div className={styles.uploadBtn}>
          <button onClick={onFaqClick}>View FAQ</button>
        </div>
      </SafeAreaContainer>
    </>
  );
}

Insurance.displayName = "Insurance";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {}
)(Insurance);
