import * as React from "react";
import moment, { Moment } from "moment-timezone";
import cx from "classnames";

import Text from "../text";
import DatePicker from "../date-picker";

import { ReactComponent as CalendarBlack } from "../icons/calendar-black.svg";
import { ReactComponent as CalendarBlue } from "../icons/calendar-blue.svg";

import styles from "./inline.module.css";

interface IProps {
  className?: string;
  labelClassName?: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  title?: string;
  onChange?: (date: Date) => void;
  readonly?: boolean;
}

export default function DateInline({
  date,
  startDate,
  endDate,
  title,
  onChange,
  className,
  labelClassName,
  readonly,
}: IProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const toggleCalendar = () => {
    if (!onChange || readonly) {
      return;
    }
    setIsCalendarOpen(!isCalendarOpen);
  };
  const onDateChange = (date: Moment) => {
    if (onChange) {
      onChange(date.toDate());
    }
    setIsCalendarOpen(false);
  };
  return (
    <div
      className={cx(styles.service, className)}
      onClick={toggleCalendar}
      data-id="toggleCalendar"
    >
      {!isCalendarOpen && (
        <div className={cx(styles.info)}>
          <Text className={cx(styles.serviceText, labelClassName)}>
            {title || "Start Date"}
          </Text>
          <div
            className={cx(
              styles.dateContainer,
              readonly ? null : styles.pointer
            )}
          >
            {date ? <CalendarBlue /> : <CalendarBlack />}
            <div className={styles.date} data-id="calendarDate">
              {date ? moment.utc(date).format("MMMM D, YYYY") : "Select date"}
            </div>
          </div>
        </div>
      )}
      {isCalendarOpen && (
        <div className={cx(styles.datePicker)}>
          <DatePicker
            startDate={startDate ? moment.utc(startDate) : moment()}
            endDate={endDate ? moment.utc(endDate) : null}
            allowWeekends
            date={moment.utc(date)}
            onChange={onDateChange}
          />
        </div>
      )}
    </div>
  );
}
DateInline.displayName = "DateInline";
