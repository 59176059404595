import React from "react";
import { DELAY_BEFORE_REMOVE_MSEC } from "./constants";
import eventEmitter from "./eventEmitter";

export default (
  component: React.ReactElement,
  options: {
    id?: string;
    delayBeforeRemoveMsec?: number;
    dependent?: boolean;
  } = {
    delayBeforeRemoveMsec: DELAY_BEFORE_REMOVE_MSEC,
    dependent: false
  }
) =>
  eventEmitter.emit("showModal", [
    component,
    options.id,
    options.delayBeforeRemoveMsec,
    options.dependent
  ]);
