import * as React from "react";
import Header from "../../components/header";
import { SafeAreaContainer } from "../../components/container";

import { ReactComponent as ArrowDownIcon } from "../../components/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../components/icons/arrow_up.svg";

import GTag from "../../gtag";

import styles from "./enhancements.module.css";

interface IQuestion {
  title: string;
  content: { base: string; silver: string; gold: string; platinum: string };
  isOpen: boolean;
}

const QUESTIONS: IQuestion[] = [
  {
    title: "Coverage of property away from premises (greater of)",
    content: {
      base: "10% / $1,000",
      silver: "10% / $2,000",
      gold: "10% / $3,500",
      platinum: "10% / $5,000",
    },
    isOpen: false,
  },
  {
    title: "Personal property in or on a motor vehicle",
    content: {
      base: "$1,000",
      silver: "$1,500",
      gold: "$2,500",
      platinum: "$5,000",
    },
    isOpen: false,
  },
  {
    title: "Personal property used in business - on premises",
    content: {
      base: "$1,000",
      silver: "$1,500",
      gold: "$2,500",
      platinum: "$5,000",
    },
    isOpen: false,
  },
  {
    title: "Personal property used in business - off premises",
    content: {
      base: "$500",
      silver: "$500",
      gold: "$500",
      platinum: "$500",
    },
    isOpen: false,
  },
  {
    title: "Money, bank notes, coins, etc.	",
    content: {
      base: "$200",
      silver: "$200",
      gold: "$200",
      platinum: "$200",
    },
    isOpen: false,
  },
  {
    title:
      "Loss or damage while in motor vehicle of not permanently installed sound equipment, tapes, records or discs",
    content: {
      base: "$250",
      silver: "$500",
      gold: "$500",
      platinum: "$500",
    },
    isOpen: false,
  },
  {
    title: "Securites, accounts, stamps, trading/sports cards, etc.",
    content: {
      base: "$500",
      silver: "$1,000",
      gold: "$1,000",
      platinum: "$1,000",
    },
    isOpen: false,
  },
  {
    title: "Watercraft including trailers, motors and accessories",
    content: {
      base: "$500",
      silver: "$1,000",
      gold: "$1,000",
      platinum: "$1,000",
    },
    isOpen: false,
  },
  {
    title: "Camping trailers or bodies, trailers not used with watercraft	",
    content: {
      base: "$500",
      silver: "$750",
      gold: "$1,000",
      platinum: "$1,250",
    },
    isOpen: false,
  },
  {
    title: "Theft of jewelry, watches, furs, precious and semi-precious stones",
    content: {
      base: "$500 / $1,000",
      silver: "$1,000 / $2,000",
      gold: "$1,500 / $3,000",
      platinum: "$2,500 / $7,500",
    },
    isOpen: false,
  },
  {
    title: "Theft of firearms or accessories",
    content: {
      base: "$500",
      silver: "$1,000",
      gold: "$2,500",
      platinum: "$5,000",
    },
    isOpen: false,
  },
  {
    title: "Electronic data processing equipment",
    content: {
      base: "$500",
      silver: "$1,000",
      gold: "$2,500",
      platinum: "$3,000",
    },
    isOpen: false,
  },
  {
    title: "Theft of tools - on premises",
    content: {
      base: "$1,000",
      silver: "$2,500",
      gold: "$5,000",
      platinum: "$7,500",
    },
    isOpen: false,
  },
  {
    title: "Theft of tools - off premises",
    content: {
      base: "$500",
      silver: "$500",
      gold: "$500",
      platinum: "$500",
    },
    isOpen: false,
  },
];
function Enhancements() {
  const [questions, setQuesions] = React.useState<IQuestion[]>(QUESTIONS);
  const [isTable, setIsTable] = React.useState(false);

  React.useEffect(() => {
    if (window.innerWidth > 600) {
      setIsTable(true);
    }
  }, []);

  React.useEffect(() => {
    GTag.event("enhancements", "Page");
  });

  const toggle = (q: IQuestion) => {
    setQuesions((prevQuestions) => {
      const prevQ = prevQuestions.find((x) => x.title === q.title);
      const index = prevQuestions.findIndex((x) => x.title === q.title);
      if (!prevQ) {
        return prevQuestions;
      }
      let newQuestion = prevQuestions;
      if (!prevQ.isOpen) {
        newQuestion = newQuestion.map((x) => ({ ...x, isOpen: false }));
      }
      return [
        ...newQuestion.slice(0, index),
        {
          ...prevQ,
          isOpen: !prevQ.isOpen,
        },
        ...newQuestion.slice(index + 1),
      ];
    });
  };

  if (isTable) {
    return (
      <div className={styles.container}>
        <div className={styles.tableHeader}>
          Silver, Gold & Platinum Enhancement Coverage Comparison.
        </div>
        <div className={styles.content}>
          <table>
            <tr>
              <th />
              <th>Base Policy</th>
              <th>Silver Enhancement</th>
              <th>Gold Enhancement</th>
              <th>Platinum Enhancement</th>
            </tr>
            {QUESTIONS.map((q) => (
              <tr key={q.title}>
                <td>{q.title}</td>
                <td>{q.content.base}</td>
                <td>{q.content.silver}</td>
                <td>{q.content.gold}</td>
                <td>{q.content.platinum}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.head}>
          Silver, Gold & Platinum Enhancement Coverage Comparison.
        </Header>
      </SafeAreaContainer>
      <div className={styles.container}>
        {questions.map((q) => (
          <div className={styles.question} onClick={() => toggle(q)}>
            <div className={styles.row}>
              <div className={styles.title}>{q.title}</div>
              {q.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {q.isOpen && (
              <div className={styles.text}>
                <div>
                  <div className={styles.title}>Base</div>
                  <div className={styles.value}>{q.content.base}</div>
                </div>
                <div>
                  <div className={styles.title}>Silver</div>
                  <div className={styles.value}>{q.content.silver}</div>
                </div>
                <div>
                  <div className={styles.title}>Gold</div>
                  <div className={styles.value}>{q.content.gold}</div>
                </div>
                <div>
                  <div className={styles.title}>Platinum</div>
                  <div className={styles.value}>{q.content.platinum}</div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

Enhancements.displayName = "Enhancements";
export default Enhancements;
