import * as React from "react";
import { connect } from "react-redux";
import { useAuth } from "./auth";
import client, { APIError } from "./helpers/api";
import umbrellaClient from "./helpers/api/umbrella";
import Spinner from "./components/spinner";
import ErrorPage from "./components/error-page";
import Maintenance from "./pages/maintenance";

import GTag from "./gtag";

import {
  fetchUser as fetchUserAction,
  FetchUser
} from "./resources/user/user.actions";
import { getUser } from "./resources/user/user.selectors";
import { IUser } from "./resources/user/types";
import { IStore } from "./resources/types";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  user: IUser;
  fetchUser: FetchUser;
}

function Initialize({ children, user, fetchUser }: IProps) {
  const [status, setStatus] = React.useState<number | string>("initializing");
  const { getToken, login, logout, refreshToken } = useAuth();
  const handleUnauthorized = () => {
    if (status === 401) {
      return;
    }
    refreshToken()
      .then(token => {
        client.setToken(token.token);
        umbrellaClient.setToken(token.token);
      })

      .catch(async () => {
        const { token } = await getToken();
        if (token && token.type === "invite") {
          window.location.reload();
          return;
        }
        logout();
        setStatus(401);
      });
  };

  React.useEffect(() => {
    client.onUnauthorized = handleUnauthorized;
    umbrellaClient.onUnauthorized = handleUnauthorized;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, setStatus, status]);

  React.useEffect(() => {
    (async () => {
      const { token, loggedIn } = await getToken();
      try {
        if (!token) {
          throw new APIError({}, 401);
        }
        if (token) {
          client.setToken(token.token);
          umbrellaClient.setToken(token.token);
        }

        if (loggedIn) {
          if (!user._id) {
            const u = await fetchUser();
            GTag.init({ userId: u._id });
          }
          setStatus(200);
        } else {
          const t = await login();
          client.setToken(t.token);
          umbrellaClient.setToken(t.token);
          const u = await fetchUser();
          GTag.init({ userId: u._id });
          setStatus(200);
        }
        GTag.event("token_auth", "Auth");
        GTag.event("init", "Auth");
      } catch (err) {
        if (err instanceof APIError) {
          switch (err.status) {
            case 404: {
              setStatus(404);
              break;
            }
            case 403: {
              setStatus(403);
              break;
            }
            case 400:
            case 401: {
              if (!token || (token && token.type === "invite")) {
                window.location.reload();
                return;
              }
              logout();
              setStatus(401);
              break;
            }
            default:
              break;
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  function exit() {
    logout();
  }

  switch (status) {
    case "initializing": {
      return <Spinner />;
    }
    case 403: {
      return <Maintenance />;
    }
    case 200: {
      return <>{children}</>;
    }
    default: {
      return (
        <ErrorPage
          status={status as number}
          description="Please go back and try again."
          action={exit}
          actionText="Back"
        />
      );
    }
  }
}

Initialize.displayName = "Initialize";
export default connect(
  (state: IStore) => ({
    user: getUser(state)
  }),
  {
    fetchUser: fetchUserAction
  }
)(Initialize);
