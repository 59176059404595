import createApiAction from "../../helpers/apiActionCreator";
import * as api from "./insurance.api";
import {
  IUpdateQuoteParams,
  IQuote,
  IBoostQuote,
  IBoostPolicy,
  IBoostClaim,
  IUpdateBoostQuoteParams,
  IUploadPolicy,
  IAdditionalInsured,
  IAdditionalInterested,
  IPolicy,
  ISetQuoteTokenParams,
  IInsurance,
  IFactors,
  IPaymentMethod,
} from "./types";

export const QUOTE_CREATE = "QUOTE_CREATE";
export const QUOTE_FETCH = "QUOTE_FETCH";
export const QUOTE_UPDATE = "QUOTE_UPDATE";
export const QUOTE_PAY = "QUOTE_PAY";
export const POLICY_UPLOAD = "POLICY_UPLOAD";
export const POLICY_FETCH = "POLICY_FETCH";
export const FETCH_INSURANCE = "FETCH_INSURANCE";
export const POLICY_DELETE = "POLICY_DELETE";
export const QUOTE_SET_TOKEN = "QUOTE_SET_TOKEN";
export const QUOTE_LIABILITY_OPTIONS_FETCH = "QUOTE_LIABILITY_OPTIONS_FETCH";

export const BOOST_QUOTE_CREATE = "BOOST_QUOTE_CREATE";
export const BOOST_QUOTE_FETCH = "BOOST_QUOTE_FETCH";
export const BOOST_QUOTE_UPDATE = "BOOST_QUOTE_UPDATE";
export const BOOST_QUOTE_PAY = "BOOST_QUOTE_PAY";
export const BOOST_POLICY_FETCH = "BOOST_POLICY_FETCH";
export const BOOST_POLICY_FETCH_BY_TOKEN = "BOOST_POLICY_FETCH_BY_TOKEN";
export const BOOST_POLICY_CANCEL = "BOOST_POLICY_CANCEL";
export const BOOST_POLICY_CANCEL_BY_TOKEN = "BOOST_POLICY_CANCEL_BY_TOKEN";
export const BOOST_CLAIM_CREATE = "BOOST_CLAIM_CREATE";
export const BOOST_CLAIM_ADD_DOCUMENTS = "BOOST_CLAIM_ADD_DOCUMENTS";
export const BOOST_CLAIMS_FETCH = "BOOST_CLAIMS_FETCH";
export const BOOST_CLAIM_FETCH = "BOOST_CLAIM_FETCH";
export const BOOST_ADD_ADDITIONAL_INSURED = "BOOST_ADD_ADDITIONAL_INSURED";
export const BOOST_UPDATE_ADDITIONAL_INSURED =
  "BOOST_UPDATE_ADDITIONAL_INSURED";
export const BOOST_REMOVE_ADDITIONAL_INSURED =
  "BOOST_REMOVE_ADDITIONAL_INSURED";
export const BOOST_FETCH_ADDITIONAL_INSURED = "BOOST_FETCH_ADDITIONAL_INSURED";
export const BOOST_ADD_ADDITIONAL_INTERESTED =
  "BOOST_ADD_ADDITIONAL_INTERESTED";
export const BOOST_UPDATE_ADDITIONAL_INTERESTED =
  "BOOST_UPDATE_ADDITIONAL_INTERESTED";
export const BOOST_REMOVE_ADDITIONAL_INTERESTED =
  "BOOST_REMOVE_ADDITIONAL_INTERESTED";
export const BOOST_FETCH_ADDITIONAL_INTERESTED =
  "BOOST_FETCH_ADDITIONAL_INTERESTED";

export const BOOST_COVERAGE_SET = "BOOST_COVERAGE_SET";
export const BOOST_COVERAGE_SET_FACTORS = "BOOST_COVERAGE_SET_FACTORS";

export type CreateQuote = (data: { dateOfBirth: Date }) => Promise<IQuote>;
export function createQuote(data: { dateOfBirth: Date }) {
  return createApiAction(QUOTE_CREATE, api.createQuote)(data);
}

export type CreateQuoteBoost = () => Promise<IBoostQuote>;
export function createQuoteBoost() {
  return createApiAction(BOOST_QUOTE_CREATE, api.createQuoteBoost)({});
}

export type FetchQuote = () => Promise<IBoostQuote>;
export function fetchQuote() {
  return createApiAction(QUOTE_FETCH, api.getQuote)({});
}

export type FetchPersonalLiabilityOptions = (data?: {
  skipLoading: boolean;
}) => Promise<number[]>;
export function fetchPersonalLiabilityOptions(data?: { skipLoading: boolean }) {
  return createApiAction(
    QUOTE_LIABILITY_OPTIONS_FETCH,
    api.getPersonalLiabilityOptions
  )({}, {}, data?.skipLoading);
}

export type FetchQuoteBoost = (data?: {
  skipLoading: boolean;
}) => Promise<IBoostQuote>;
export function fetchQuoteBoost(data?: { skipLoading: boolean }) {
  return createApiAction(BOOST_QUOTE_FETCH, api.getQuoteBoost)(
    {},
    {},
    data?.skipLoading
  );
}

export type FetchPolicyBoost = (data?: {
  skipLoading: boolean;
}) => Promise<IBoostPolicy>;
export function fetchPolicyBoost(data?: { skipLoading: boolean }) {
  return createApiAction(BOOST_POLICY_FETCH, api.getPolicyBoost)(
    {},
    {},
    data?.skipLoading
  );
}

export type FetchPolicyBoostByToken = (token: string) => Promise<IBoostPolicy>;
export function fetchPolicyBoostByToken(token: string) {
  return createApiAction(
    BOOST_POLICY_FETCH_BY_TOKEN,
    api.getPolicyBoostByToken
  )({ token });
}

export type CancelPolicyBoost = () => Promise<IBoostPolicy>;
export function cancelPolicyBoost() {
  return createApiAction(BOOST_POLICY_CANCEL, api.cancelPolicyBoost)({});
}

export type CancelPolicyBoostByToken = (token: string) => Promise<IBoostPolicy>;
export function cancelPolicyBoostByToken(token: string) {
  return createApiAction(
    BOOST_POLICY_CANCEL_BY_TOKEN,
    api.cancelPolicyBoostByToken
  )({ token });
}

export type AddAdditionalInsured = (data: {
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) => Promise<IBoostQuote>;
export function addAdditionalInsured(data: {
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) {
  return createApiAction(
    BOOST_ADD_ADDITIONAL_INSURED,
    api.addAdditionalInsured
  )(data);
}
export type UpdateAdditionalInsured = (data: {
  id: string;
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) => Promise<IBoostQuote>;
export function updateAdditionalInsured(data: {
  id: string;
  quoteId: string;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}) {
  return createApiAction(
    BOOST_UPDATE_ADDITIONAL_INSURED,
    api.updateAdditionalInsured
  )(data);
}
export type RemoveAdditionalInsured = (data: {
  id: string;
  quoteId: string;
}) => Promise<IBoostQuote>;
export function removeAdditionalInsured(data: { id: string; quoteId: string }) {
  return createApiAction(
    BOOST_REMOVE_ADDITIONAL_INSURED,
    api.removeAdditionalInsured
  )(data);
}
export type FetchAdditionalInsured = (data: {
  id: string;
  quoteId: string;
}) => Promise<IAdditionalInsured>;
export function fetchAdditionalInsured(data: { id: string; quoteId: string }) {
  return createApiAction(
    BOOST_FETCH_ADDITIONAL_INSURED,
    api.getAdditionalInsured
  )(data);
}

export type AddAdditionalInterested = (data: {
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) => Promise<IBoostQuote>;
export function addAdditionalInterested(data: {
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) {
  return createApiAction(
    BOOST_ADD_ADDITIONAL_INTERESTED,
    api.addAdditionalInterested
  )(data);
}
export type UpdateAdditionalInterested = (data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) => Promise<IBoostQuote>;
export function updateAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
  type: string;
  name: string;
  businessName?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}) {
  return createApiAction(
    BOOST_UPDATE_ADDITIONAL_INTERESTED,
    api.updateAdditionalInterested
  )(data);
}
export type RemoveAdditionalInterested = (data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) => Promise<IBoostQuote>;
export function removeAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) {
  return createApiAction(
    BOOST_REMOVE_ADDITIONAL_INTERESTED,
    api.removeAdditionalInterested
  )(data);
}
export type FetchAdditionalInterested = (data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) => Promise<IAdditionalInterested>;
export function fetchAdditionalInterested(data: {
  id: string;
  entityId: string;
  isPolicy: boolean;
}) {
  return createApiAction(
    BOOST_FETCH_ADDITIONAL_INTERESTED,
    api.getAdditionalInterested
  )(data);
}

export type CreateClaimBoost = (data: {
  dateOfLoss?: Date;
  description: string;
  email: string;
  phone: string;
  policyId: string;
  files?: File[];
}) => Promise<IBoostClaim>;
export function createClaimBoost(data: {
  dateOfLoss?: Date;
  description: string;
  email: string;
  phone: string;
  policyId: string;
  files?: File[];
}) {
  return createApiAction(BOOST_CLAIM_CREATE, api.createClaimBoost)(data);
}

export type AddClaimDocuments = (data: {
  policyId: string;
  claimId: string;
  files?: File[];
}) => Promise<IBoostClaim>;
export function addClaimDocuments(data: {
  policyId: string;
  claimId: string;
  files?: File[];
}) {
  return createApiAction(
    BOOST_CLAIM_ADD_DOCUMENTS,
    api.addClaimDocuments
  )(data);
}

export type FetchClaimsBoost = (data: {
  policyId: string;
}) => Promise<IBoostClaim>;
export function fetchClaimsBoost(data: { policyId: string }) {
  return createApiAction(BOOST_CLAIMS_FETCH, api.fetchClaimsBoost)(data);
}

export type FetchClaimBoost = (data: {
  policyId: string;
  claimId: string;
}) => Promise<IBoostClaim>;
export function fetchClaimBoost(data: { policyId: string; claimId: string }) {
  return createApiAction(BOOST_CLAIM_FETCH, api.fetchClaimBoost)(data);
}

export type UpdateQuote = (
  data: Partial<IUpdateQuoteParams>
) => Promise<IQuote>;
export function updateQuote(data: Partial<IUpdateQuoteParams>) {
  return createApiAction(QUOTE_UPDATE, api.updateQuote)(data);
}

export type UpdateQuoteBoost = (
  data: Partial<IUpdateBoostQuoteParams>
) => Promise<IBoostQuote>;
export function updateQuoteBoost(data: Partial<IUpdateBoostQuoteParams>) {
  return createApiAction(BOOST_QUOTE_UPDATE, api.updateQuoteBoost)(data);
}

export type SetQuoteToken = (
  data: Partial<ISetQuoteTokenParams>
) => Promise<IQuote>;
export function setQuoteToken(data: Partial<ISetQuoteTokenParams>) {
  return createApiAction(QUOTE_SET_TOKEN, api.setQuoteToken)(data);
}

export type PayBoost = ({
  id,
  paymentMethod,
  confirmed,
}: {
  id: string;
  paymentMethod?: IPaymentMethod;
  confirmed?: boolean;
}) => Promise<IBoostQuote>;
export function payBoost({
  id,
  paymentMethod,
  confirmed,
}: {
  id: string;
  paymentMethod?: IPaymentMethod;
  confirmed?: boolean;
}) {
  return createApiAction(
    BOOST_QUOTE_PAY,
    api.payBoost
  )({ id, paymentMethod, confirmed });
}

export type PayQuote = (data: { tosApproved: boolean }) => Promise<IQuote>;
export function payQuote(data: { tosApproved: boolean }) {
  return createApiAction(QUOTE_PAY, api.payQuote)(data);
}

export type UploadPolicy = (data: IUploadPolicy) => Promise<IPolicy>;
export function uploadPolicy(data: IUploadPolicy) {
  return createApiAction(POLICY_UPLOAD, api.uploadPolicy)(data);
}

export type DeletePolicy = (id: string) => Promise<any>;
export function deletePolicy(id: string) {
  return createApiAction(POLICY_DELETE, api.deletePolicy)({ id });
}

export type FetchPolicy = () => Promise<IPolicy>;
export function fetchPolicy() {
  return createApiAction(POLICY_FETCH, api.getPolicy)({});
}

export type FetchInsurance = () => Promise<IInsurance>;
export function fetchInsurance() {
  return createApiAction(FETCH_INSURANCE, api.getInsurance)({});
}

export type SetBoostCoverage = (coverage: IUpdateBoostQuoteParams) => void;
export function setBoostCoverage(coverage: IUpdateBoostQuoteParams) {
  return {
    type: BOOST_COVERAGE_SET,
    coverage,
  };
}

export type SetBoostFactors = (factors: IFactors) => void;
export function setBoostFactors(factors: IFactors) {
  return {
    type: BOOST_COVERAGE_SET_FACTORS,
    factors,
  };
}
