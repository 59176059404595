import * as React from "react";
import NumberFormat from "react-number-format";
import InputMask, { Props } from "react-input-mask";
import cx from "classnames";

import styles from "./styles.module.css";

const zipBeforeMaskedValueChange = (
  newState: any,
  oldState: any,
  userInput: string
) => {
  let { value } = newState;
  let { selection } = newState;
  let cursorPosition = selection ? selection.start : null;

  // keep minus if entered by user
  if (
    value.endsWith("-") &&
    userInput !== "-" &&
    !oldState.value.endsWith("-")
  ) {
    if (cursorPosition === value.length) {
      cursorPosition -= 1;
      selection = { start: cursorPosition, end: cursorPosition };
    }
    value = value.slice(0, -1);
  }

  return {
    value,
    selection
  };
};

const phoneBeforeMaskedValueChange = (newState: any) => {
  let { value } = newState;
  const { selection } = newState;

  if (
    selection &&
    selection.start === 1 &&
    selection.end === 1 &&
    value === "("
  ) {
    value = "";
  }

  return {
    value,
    selection
  };
};

interface IProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  label?: string;
  icon?: React.ReactElement;
  mask?: string;
  currency?: boolean;
  prefix?: string;
  maskChar?: string | null;
  autoComplete?: string;
  formatChars?: {
    [key: string]: string;
  };
  "data-id"?: string;
}

export default function Input({
  className,
  name,
  label,
  icon,
  prefix,
  placeholder,
  value,
  type,
  onChange,
  onBlur,
  mask = "",
  currency = false,
  maskChar = null,
  formatChars = {
    "9": "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]"
  },
  autoComplete,
  readOnly,
  pattern,
  maxLength = 50,
  disabled = false,
  ...props
}: IProps) {
  let beforeMaskedValueChange;
  switch (name) {
    case "zip":
      beforeMaskedValueChange = zipBeforeMaskedValueChange;
      break;
    case "phone":
      beforeMaskedValueChange = phoneBeforeMaskedValueChange;
      break;
    default:
      beforeMaskedValueChange = undefined;
  }

  let component = (
    <input
      name={name}
      placeholder={placeholder}
      value={value}
      type={type}
      pattern={pattern}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete={autoComplete}
      readOnly={!!readOnly}
      maxLength={maxLength}
      disabled={disabled}
      {...props}
    />
  );
  if (mask) {
    component = (
      <InputMask
        mask={mask}
        maskChar={maskChar}
        formatChars={formatChars}
        value={value}
        type={type}
        pattern={pattern}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        beforeMaskedValueChange={beforeMaskedValueChange}
        autoComplete={autoComplete}
        readOnly={!!readOnly}
        disabled={disabled}
      >
        {(inputProps: Props) => <input {...inputProps} />}
      </InputMask>
    );
  }
  if (currency) {
    component = (
      <NumberFormat
        value={value as number}
        displayType={"input"}
        thousandSeparator
        name={name}
        placeholder={placeholder}
        pattern={pattern}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        readOnly={!!readOnly}
        maxLength={maxLength}
        disabled={disabled}
        data-id={props["data-id"]}
      />
    );
  }
  return (
    <div
      className={cx(
        styles.inputContainer,
        { [styles.hasIcon]: !!icon },
        { [styles.hasPrefix]: !!prefix && value },
        className
      )}
    >
      {label && <label htmlFor={name}>{label}</label>}
      {prefix && value && <span className={styles.prefix}>{prefix}</span>}
      {icon &&
        React.cloneElement(icon, {
          ...icon.props,
          className: cx(icon.props.className, styles.icon)
        })}
      {component}
    </div>
  );
}
Input.displayName = "Input";
