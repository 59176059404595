import * as React from "react";
import cx from "classnames";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as CloseWhiteIcon } from "../icons/close-white.svg";

import styles from "./modal.module.css";

interface IProps {
  className?: string;
  show?: boolean;
  children: React.ReactChild | React.ReactChild[];
  onClose: () => void;
  isWhite?: boolean;
  isLoading?: boolean;
  showCloseIcon?: boolean;
  header?: React.ReactElement;
}

export default function Modal({
  className,
  show,
  children,
  onClose,
  isWhite,
  isLoading = false,
  header,
  showCloseIcon = true,
}: IProps) {
  function handleBackgroundClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={styles.background} onClick={handleBackgroundClick} />
      <div className={cx(styles.modalContainer, className)}>
        <div
          className={cx(styles.modal, className, {
            [styles.hidden]: show !== undefined && !show,
          })}
        >
          {showCloseIcon && isWhite && (
            <CloseWhiteIcon className={styles.icon} onClick={onClose} />
          )}
          {showCloseIcon && !isWhite && (
            <CloseIcon className={styles.icon} onClick={onClose} />
          )}
          {header}
          <div className={styles.scrollArea}>{children}</div>
        </div>
      </div>
    </>
  );
}
Modal.displayName = "Modal";
