import * as React from "react";
import momentTz from "moment-timezone";
import cx from "classnames";
import values from "lodash/values";
import { Formik, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../../config";
import UploadFiles from "../../../components/upload-files";
import Checkbox from "../../../components/checkbox";
import Button from "../../../components/action-button";
import Header from "../../../components/header";
import Select from "../../../components/select";
import Text from "../../../components/text";
import Input from "../../../components/input";
import DateInput from "../../../components/date-input";
import { SafeAreaContainer } from "../../../components/container";
import { InsuranceCompanies } from "../../../helpers/constants";
import mimeTypes from "../../../helpers/mimeTypes";

import { IStore } from "../../../resources/types";
import {
  uploadPolicy,
  UploadPolicy,
} from "../../../resources/insurance/insurance.actions";

import schema from "./schema";

import GTag from "../../../gtag";

import styles from "./upload-insurance.module.css";

interface IValues {
  isDamaged?: boolean;
  policy?: File[];
  coverageAmount?: string;
  companyName: string;
  companyNameCustom: string;
  startDate?: Date;
  expiresAt?: Date;
  isContinuous?: boolean;
}

interface IProps {
  uploadPolicy: UploadPolicy;
}

function UploadInsuranceModal({ uploadPolicy }: IProps) {
  const [error, setError] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("upload_policy", "Page");
  });

  const onUpload = async (formValues: IValues) => {
    if (!formValues.policy || formValues.isDamaged !== undefined) {
      return;
    }
    setIsSubmitting(true);

    GTag.event("upload_policy_submit", "Click");

    try {
      await uploadPolicy({
        startDate: formValues.startDate,
        expiresAt: !formValues.isContinuous ? formValues.expiresAt : undefined,
        isContinuous: formValues.isContinuous,
        coverageAmount: formValues.coverageAmount
          ? parseFloat(formValues.coverageAmount.replace(/,/g, ""))
          : 0,
        companyName:
          formValues.companyName === "Other"
            ? formValues.companyNameCustom
            : formValues.companyName,
        policy: formValues.policy,
      });
      history.push("/insurance/upload/completed");
    } catch (err) {
      if (err.data) {
        const msg = values(err.data.errors[0])[0];
        switch (msg) {
          case "Unsupported file format.":
            setError("Sorry, this file type is not allowed.");
            break;
          case "Payload is too large.":
            setError(
              "Sorry, file size is too large. The maximum file size allowed is set to 10Mb."
            );
            break;
          default:
            setError("Something went wrong.");
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>Upload policy</Header>
        <Text>
          Please upload your insurance policy document or Certificate of
          Insurance as a PDF or image file. You can upload multiple files if
          necessary.
        </Text>
      </SafeAreaContainer>
      <Formik<IValues>
        initialValues={{
          companyName: "",
          coverageAmount: undefined,
          companyNameCustom: "",
          expiresAt: undefined,
          policy: [],
        }}
        validateOnBlur
        validateOnChange
        validationSchema={schema}
        onSubmit={onUpload}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          setFieldTouched,
          setFieldValue,
          setValues,
          setTouched,
          values,
          errors,
          touched,
        }: FormikProps<IValues>) => {
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <SafeAreaContainer className={styles.container}>
                <div className={styles.uploadContainer}>
                  <UploadFiles
                    setIsDamanged={(d: boolean) =>
                      setFieldValue("isDamaged", d)
                    }
                    accept={[...mimeTypes.image, ...mimeTypes.pdf].join(",")}
                    files={values.policy || []}
                    allowedFileTypes={["image", "pdf"]}
                    errorsFiles={errors.policy}
                    touchedFiles={touched.policy}
                    setTouched={() =>
                      setTouched({
                        policy: true,
                        isDamaged: true,
                      })
                    }
                    setFiles={(files: File[]) => {
                      setValues({
                        ...values,
                        policy: files,
                      });
                    }}
                  />
                  <div className={styles.inputContainer}>
                    <hr className={styles.line} />
                    <Select
                      data-id="companyNameSelect"
                      required
                      name="companyName"
                      value={values.companyName}
                      className={cx(styles.select, {
                        [styles.error]:
                          touched.companyName && errors.companyName,
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option disabled value="">
                        Select your insurance provider
                      </option>
                      {InsuranceCompanies.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </Select>
                    {values.companyName === "Other" && (
                      <Input
                        data-id="companyNameInput"
                        name="companyNameCustom"
                        value={values.companyNameCustom}
                        className={cx(styles.input, {
                          [styles.error]:
                            touched.companyNameCustom &&
                            errors.companyNameCustom,
                        })}
                        placeholder="Insurance company"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                    <hr className={styles.line} />
                    <DateInput
                      data-id="startDate"
                      startDate={momentTz(0).toDate()}
                      value={values.startDate}
                      containerClassName={cx(styles.dateInput, {
                        [styles.error]: touched.startDate && errors.startDate,
                      })}
                      className={styles.input}
                      textClassName={styles.value}
                      title="Policy start date"
                      onChange={(value: Date) => {
                        setFieldTouched("startDate");
                        setFieldValue("startDate", value);
                      }}
                      Icon={() => (
                        <div className={styles.dateInputIcon}>
                          {values.startDate ? "Edit" : "Select"}
                        </div>
                      )}
                      hasMonthSelector
                      hasYearSelector
                    />
                    <hr className={styles.line} />
                    <div className={styles.checkboxContainer}>
                      <Checkbox
                        data-id="continuousCheckbox"
                        className={styles.checkbox}
                        checkmarkClassName={styles.checkmark}
                        name="authorization"
                        LabelComponent={() => (
                          <div className={styles.checkboxLabel}>
                            Continuous policy
                          </div>
                        )}
                        checked={values.isContinuous || false}
                        onChange={() => {
                          setFieldValue("isContinuous", !values.isContinuous);
                        }}
                      />
                    </div>
                    <hr className={styles.line} />
                    {!values.isContinuous && (
                      <>
                        <DateInput
                          data-id="endDate"
                          startDate={momentTz()
                            .tz(config.defaultTimezone)
                            .add(1, "day")
                            .toDate()}
                          value={values.expiresAt}
                          containerClassName={cx(styles.dateInput, {
                            [styles.error]:
                              touched.expiresAt && errors.expiresAt,
                          })}
                          className={styles.input}
                          textClassName={styles.value}
                          title="Policy expiration date"
                          onChange={(value: Date) => {
                            setFieldTouched("expiresAt");
                            setFieldValue("expiresAt", value);
                          }}
                          Icon={() => (
                            <div className={styles.dateInputIcon}>
                              {values.expiresAt ? "Edit" : "Select"}
                            </div>
                          )}
                          hasMonthSelector
                          hasYearSelector
                        />
                        <hr className={styles.line} />
                      </>
                    )}
                    <Input
                      data-id="coverageInput"
                      name="coverageAmount"
                      type="number"
                      currency
                      value={values.coverageAmount || ""}
                      className={cx(styles.input, {
                        [styles.error]:
                          touched.coverageAmount && errors.coverageAmount,
                      })}
                      placeholder="Liability coverage amount (US$)"
                      prefix="$"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <hr className={styles.line} />
                  </div>
                  <div
                    className={cx(styles.errorBlock, {
                      [styles.hidden]: !error,
                    })}
                  >
                    {error}
                  </div>
                </div>
              </SafeAreaContainer>
              <Button
                data-id="submitBtn"
                type="submit"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  values.isDamaged !== undefined ||
                  !values.policy ||
                  values.policy.length === 0
                }
              >
                Submit your policy
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

UploadInsuranceModal.displayName = "UploadInsurance";
export default connect((state: IStore) => ({}), {
  uploadPolicy,
})(UploadInsuranceModal);
