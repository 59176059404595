import moment from "moment-timezone";
import { AnyAction } from "redux";
import { IUserState } from "./types";

import {
  FETCH_USER,
  CONFIRM_INFORMATION,
  CONFIRM_ADDRESS,
  PAYMENT_METHODS_FETCH,
  PAYMENT_METHOD_SET_DEFAULT,
} from "./user.actions";

const defaultState: IUserState = {
  user: {
    _id: "",
    firstName: "",
    lastName: "",
    uuid: "",
    email: "",
    phone: "",
    building: "",
    state: "",
    city: "",
    zip: "",
    unit: "",
    address: "",
    timezone: moment.tz.guess(),
    buildingUuid: "",
    isConfirmed: false,
    insuranceType: null,
  },
  paymentMethods: [],
};

export default function usersReducer(
  state: IUserState | undefined = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_USER:
    case PAYMENT_METHOD_SET_DEFAULT:
    case CONFIRM_INFORMATION:
    case CONFIRM_ADDRESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case PAYMENT_METHODS_FETCH: {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    }
    default:
      return state;
  }
}
