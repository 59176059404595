import * as React from "react";
import ChatButton from "../../../components/chat-button";
import Text from "../../../components/text";
import { SafeAreaContainer } from "../../../components/container";
import Header from "../../../components/header";

import styles from "./error.module.css";

function PaymentError() {
  return (
    <SafeAreaContainer>
      <Header className={styles.title}>Let’s talk</Header>
      <Text className={styles.description}>
        The payment methods you’ve added are not valid. Chat with us and we’ll
        help sort this out.
      </Text>
      <ChatButton className={styles.chatButton} />
    </SafeAreaContainer>
  );
}

PaymentError.displayName = "PaymentError";
export default PaymentError;
