import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import values from "lodash/values";
import remove from "lodash/remove";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Button from "../../../../components/action-button";
import Switch from "../../../../components/switch";
import Checkbox from "../../../../components/checkbox";
import { showModal } from "../../../../modules/modalProvider";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import {
  updateQuoteBoost,
  UpdateQuoteBoost,
} from "../../../../resources/insurance/insurance.actions";
import { getUser } from "../../../../resources/user/user.selectors";
import {
  IBoostQuote,
  IBoostSummary,
  IUpdateBoostQuoteParams,
} from "../../../../resources/insurance/types";
import { IUser } from "../../../../resources/user/types";
import { IStore } from "../../../../resources/types";
import CoverageInput from "../../components/coverage";
import { formatMoneyInCents } from "../../../../helpers/common";

import { IExtraCoverage, EXTRA_COVERAGES } from "../boost.config";

import { ReactComponent as CheckBlueIcon } from "../../../../components/icons/check-blue.svg";

import styles from "./extra-coverage.module.css";

const mapSummary = (summary?: IBoostSummary): IUpdateBoostQuoteParams | null =>
  summary
    ? cloneDeep({
        effectiveDate: summary.startDate,
        enhancementType: summary.enhancementType,
        extraCoverages: summary.extraCoverages,
        personalProperty: summary.personalProperty,
      })
    : null;

interface IProps {
  quote: IBoostQuote;
  user?: IUser;
  updateQuoteBoost: UpdateQuoteBoost;
}

function ExtraCoverage({ quote, user, updateQuoteBoost }: IProps) {
  const { id, type } = useParams<{ id: string; type: string }>();
  const history = useHistory();

  const [summary, setSummary] = React.useState<IUpdateBoostQuoteParams | null>(
    mapSummary(quote.summary)
  );
  const [
    originalSummary,
    setOriginalSummary,
  ] = React.useState<IUpdateBoostQuoteParams | null>(mapSummary(quote.summary));

  const isChanged = !isEqual(summary, originalSummary);

  const [serverError, setServerError] = React.useState("");
  const [isOutOfRange, setIsOutOfRange] = React.useState(false);

  React.useEffect(() => {
    if (!summary && quote.summary) {
      setSummary(mapSummary(quote.summary));
      setOriginalSummary(mapSummary(quote.summary));
    }
  }, [quote, summary]);

  if (!user || !quote.summary || !summary) {
    return null;
  }

  const goBack = () => history.replace(`/insurance/boost/edit/${id}/extra`);

  const AVAILABLE_COVERAGES = EXTRA_COVERAGES.filter((cov: IExtraCoverage) => {
    if (cov.availableStates) {
      if (cov.availableStates.includes(user.state)) {
        return true;
      }
      return false;
    }
    if (cov.notAvailableStates) {
      if (cov.notAvailableStates.includes(user.state)) {
        return false;
      }
      return true;
    }
    return true;
  });

  const selectedCoverage = AVAILABLE_COVERAGES.find((x) => x.value === type);
  if (!selectedCoverage) {
    return null;
  }

  const defaultLimit =
    selectedCoverage && selectedCoverage.defaultLimit
      ? selectedCoverage.defaultLimit[user.state] ||
        selectedCoverage.defaultLimit.default
      : 0;

  const onChange = async () => {
    let newCoverages = [...(summary.extraCoverages || [])];
    if (newCoverages.find((x) => x.id === selectedCoverage.value)) {
      remove(newCoverages, (c) => c.id === selectedCoverage.value);
    } else {
      newCoverages = [
        ...newCoverages,
        { id: selectedCoverage.value, limit: defaultLimit },
      ];
    }
    setSummary({ ...summary, extraCoverages: newCoverages });
  };

  const onOptionOutOfRange = () => {
    setIsOutOfRange(true);
  };

  const onLimitChange = async (limit: number) => {
    setIsOutOfRange(false);
    const newCoverages = [...(summary.extraCoverages || [])];
    const coverage = newCoverages.find((x) => x.id === selectedCoverage.value);
    if (!coverage) {
      return;
    }
    coverage.limit = limit;
    setSummary({ ...summary, extraCoverages: newCoverages });
  };

  const changeEnhancementType = async (enhancementType: string) => {
    setSummary({ ...summary, enhancementType });
  };

  const getOptions = (selectedCoverage: IExtraCoverage) => {
    const options = selectedCoverage.options
      ? selectedCoverage.options[user.state] || selectedCoverage.options.default
      : [];
    if (selectedCoverage.value !== "increasedMoldRemediation") {
      return options;
    }
    if (!summary || !summary.personalProperty) {
      return options;
    }
    return options.filter((x: number) => x <= (summary.personalProperty || 0));
  };

  async function onSubmit() {
    setServerError("");
    const newCoverages = [...(summary?.extraCoverages || [])];
    try {
      await updateQuoteBoost({
        enhancementType: summary?.enhancementType || "",
        extraCoverages: newCoverages.map((x) => ({
          id: x.id,
          limit: x.limit,
          factors: x.factors,
        })),
      });
    } catch (err) {
      history.push("/insurance/boost/error");
      return;
    }
    setOriginalSummary(summary);
    goBack();
  }

  const openRevert = () => {
    if (isChanged) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (originalSummary && isChanged) {
        try {
          await updateQuoteBoost(originalSummary);
        } catch (err) {
          history.push("/insurance/boost/error");
          return;
        }
      }
      goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const coverage = summary.extraCoverages?.find(
    (x) => x.id === selectedCoverage.value
  );

  const options = getOptions(selectedCoverage);
  const defaultOptions = selectedCoverage.options
    ? selectedCoverage.options[user.state] || selectedCoverage.options.default
    : [];

  const showEnhancementType =
    selectedCoverage.values && selectedCoverage.value === "enhancementType";
  const showSwitch = !showEnhancementType;

  const disableSwitch =
    options.length === 0 &&
    selectedCoverage.value === "increasedMoldRemediation";

  const isRequired =
    selectedCoverage.requiredStates &&
    selectedCoverage.requiredStates.includes(user.state);

  const disableButton = disableSwitch || isRequired || !isChanged;

  const isTwoLines =
    selectedCoverage.value === "replacementCost" ||
    selectedCoverage.value === "increasedMoldRemediation" ||
    selectedCoverage.value === "windStorm";

  return (
    <>
      <LayoutHeader
        className={styles.headerBlack}
        showBackButton
        isBlackButton={false}
        isCloseButton
        onBack={openRevert}
      />
      <Container className={styles.containerBlack}>
        <SafeAreaContainer className={styles.headerBlack}>
          <Header className={styles.title}>
            {selectedCoverage.icon || <div />}
            <span>{selectedCoverage.title2 || selectedCoverage.title}</span>
          </Header>
        </SafeAreaContainer>
        <SafeAreaContainer
          className={cx(
            styles.mainDetails,
            isTwoLines ? styles.twoLines : null
          )}
        >
          <div className={styles.pageDescription}>
            {selectedCoverage.description ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu rhoncus felis. Aenean blandit posuere sem."}
          </div>
          {showSwitch && (
            <div
              className={cx(
                styles.rowItem,
                disableSwitch ? styles.disabled : null
              )}
            >
              <div className={styles.rowDescription}>
                <span className={styles.label}>{selectedCoverage.title}</span>
              </div>
              <Switch
                className={styles.switch}
                disabled={isRequired || disableSwitch}
                checked={
                  !!summary.extraCoverages?.find(
                    (x) => x.id === selectedCoverage.value
                  )
                }
                onChange={() => onChange()}
              />
            </div>
          )}
          {showEnhancementType && (
            <div>
              {selectedCoverage.values?.map(
                (item: { key: string; value: string }) => (
                  <div className={styles.checkboxContainer} key={item.key}>
                    <Checkbox
                      data-id={item.key}
                      className={styles.checkbox}
                      checkmarkClassName={styles.checkmark}
                      checkIcon={<CheckBlueIcon />}
                      name="type"
                      LabelComponent={() => (
                        <div className={styles.labelContainer}>
                          <div className={styles.labelDescription}>
                            <span className={styles.label}>{item.value}</span>
                          </div>
                          <span />
                        </div>
                      )}
                      checked={summary.enhancementType === item.key}
                      onChange={() => changeEnhancementType(item.key)}
                    />
                  </div>
                )
              )}
            </div>
          )}
          {selectedCoverage.options && selectedCoverage.isSelect && (
            <div>
              {options.map((option: number) => (
                <div
                  className={styles.checkboxContainer}
                  key={`coverage-option-${option}`}
                >
                  <Checkbox
                    data-id={`coverage${option}`}
                    disabled={!coverage}
                    className={styles.checkbox}
                    checkmarkClassName={styles.checkmark}
                    disabledClassName={styles.disabled}
                    checkIcon={<CheckBlueIcon />}
                    name="type"
                    LabelComponent={() => (
                      <div className={styles.labelContainer}>
                        <div className={styles.labelDescription}>
                          <span className={styles.label}>
                            {formatMoneyInCents(option, false)} coverage
                          </span>
                        </div>
                        <span />
                      </div>
                    )}
                    checked={coverage ? coverage.limit === option : false}
                    onChange={() => onLimitChange(option)}
                  />
                </div>
              ))}
            </div>
          )}
          {selectedCoverage.options && !selectedCoverage.isSelect && (
            <CoverageInput
              cents
              data-id={coverage?.id || "coverage"}
              disabled={!coverage}
              editable
              value={coverage?.limit || defaultLimit || 0}
              options={options}
              onChange={(amount: number) => onLimitChange(amount)}
              onOutOfRange={onOptionOutOfRange}
            />
          )}
          <div className={styles.errorBlock}>{serverError}</div>
          {((options.length === 0 &&
            selectedCoverage.value === "increasedMoldRemediation") ||
            isOutOfRange) && (
            <div className={styles.errorBlock}>
              Increased mold remediation coverage limits are{" "}
              {formatMoneyInCents(defaultOptions[0], false)} to{" "}
              {formatMoneyInCents(
                defaultOptions[defaultOptions.length - 1],
                false
              )}
              . The limit can not be greater than selected Personal Property
              coverage. Your current Personal Property coverage value is:{" "}
              {formatMoneyInCents(summary?.personalProperty || 0, false)}.
            </div>
          )}
        </SafeAreaContainer>
        <Button disabled={disableButton} onClick={onSubmit}>
          Add coverage
        </Button>
      </Container>
    </>
  );
}

ExtraCoverage.displayName = "ExtraCoverage";
export default withBoostQuote(
  connect(
    (state: IStore) => ({
      user: getUser(state),
    }),
    {
      updateQuoteBoost,
    }
  )(ExtraCoverage)
);
