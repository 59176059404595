import React from "react";
import MainLayout from "./layouts/main";
import EmptyLayout from "./layouts/empty";

import Home from "./pages/home";
import homeStyles from "./pages/home/home.module.css";
import Confirm from "./pages/confirm";
import ConfirmAddress from "./pages/confirm-address";
import ConfirmAddressConfirmation from "./pages/confirm-address/confirmation";
import InsuranceDetails from "./pages/insurance/insurance-details";
import InsuranceCoverage from "./pages/insurance/my-policy/coverage";
import InsuranceDeductible from "./pages/insurance/my-policy/deductible";
import InsurancePurchased from "./pages/insurance/confirmation";
import UploadInsurance from "./pages/insurance/upload-insurance";
import UploadInsuranceCompleted from "./pages/insurance/upload-insurance/completed";
import InsuranceLemonadeHome from "./pages/insurance/lemonade/home";
import InsuranceLemonade from "./pages/insurance/lemonade";
import InsurancePayment from "./pages/insurance/payment";
import InsuranceAddCard from "./pages/insurance/payment/add-card";
import InsuranceChoose from "./pages/insurance";
import InsuranceInfo from "./pages/insurance/info";
import InsuranceFaq from "./pages/insurance/info/faq";
import InsuranceTerms from "./pages/insurance/info/terms";
import InsuranceErrorGeneral from "./pages/insurance/error/general";
import InsuranceErrorPayment from "./pages/insurance/error/payment";
import InsuranceErrorCheckPayment from "./pages/insurance/error/checkPayment";
import PaymentHistory from "./pages/payment-history/payment-history";

import InsuranceBoost from "./pages/insurance/boost";
import InsuranceBoostHome from "./pages/insurance/boost/home";
import InsuranceBoostCoverage from "./pages/insurance/boost/coverage";
import InsuranceBoostExtraCoverage from "./pages/insurance/boost/extra-coverage";
import InsuranceBoostExtraCoverageDetails from "./pages/insurance/boost/extra-coverage/details";
import InsuranceBoostExtraCoverageScheduledProperty from "./pages/insurance/boost/extra-coverage/scheduledProperty";
import InsuranceBoostExtraCoverageScheduledPropertyAdd from "./pages/insurance/boost/extra-coverage/addScheduledProperty";
import InsuranceBoostPayment from "./pages/insurance/boost/payment";
import InsuranceBoostAddCard from "./pages/insurance/boost/payment/add-card";
import InsuranceBoostPurchased from "./pages/insurance/boost/confirmation";
import InsuranceBoostDeductible from "./pages/insurance/boost/deductible";
import InsuranceBoostDetails from "./pages/insurance/boost/policy-details";
import InsuranceBoostAddClaim from "./pages/insurance/boost/claims/add-claim";
import InsuranceBoostClaimConfirmation from "./pages/insurance/boost/claims/confirmation";
import InsuranceBoostClaimsList from "./pages/insurance/boost/claims/claims-list";
import InsuranceBoostClaimDetails from "./pages/insurance/boost/claims/claim-details";
import InsuranceBoostClaimFaq from "./pages/insurance/boost/claims/faq";
import InsuranceBoostErrorGeneral from "./pages/insurance/boost/error/general";
import InsuranceBoostErrorPayment from "./pages/insurance/boost/error/payment";
import InsuranceBoostErrorCheckPayment from "./pages/insurance/boost/error/checkPayment";
import EditAdditionalInsured from "./pages/insurance/boost/additional-insured/edit-additional-insured";
import AdditionalInsured from "./pages/insurance/boost/additional-insured/additional-insured";
import EditAdditionalInterested from "./pages/insurance/boost/additional-interested/edit-additional-interested";
import AdditionalInterested from "./pages/insurance/boost/additional-interested/additional-interested";

import payInsuranceBoostStyles from "./pages/insurance/boost/payment/pay.module.css";
import addCardInsuranceBoostStyles from "./pages/insurance/boost/payment/add-card.module.css";
import confirmationBoostStyles from "./pages/insurance/boost/confirmation/confirmation.module.css";
import insuranceDeductibleBoostStyles from "./pages/insurance/boost/deductible/deductible.module.css";
import insuranceCoverageBoostStyles from "./pages/insurance/boost/coverage/coverage.module.css";
import insuranceExtraCoverageBoostStyles from "./pages/insurance/boost/extra-coverage/extra-coverage.module.css";
import insuranceDetailsBoostStyles from "./pages/insurance/boost/policy-details/policy-details.module.css";
import addClaimBoostStyles from "./pages/insurance/boost/claims/add-claim.module.css";
import insuranceErrorBoostStyles from "./pages/insurance/boost/error/error.module.css";
import claimConfirmationStyles from "./pages/insurance/boost/claims/confirmation.module.css";
import claimsListStyles from "./pages/insurance/boost/claims/claims-list.module.css";
import claimDetailsStyles from "./pages/insurance/boost/claims/claim-details.module.css";

import insuranceUploadCompletedStyles from "./pages/insurance/upload-insurance/completed.module.css";
import insuranceStyles from "./pages/insurance/insurance.module.css";
import insuranceInfoStyles from "./pages/insurance/info/info.module.css";
import insuranceErrorStyles from "./pages/insurance/error/error.module.css";
import insuranceUploadStyles from "./pages/insurance/upload-insurance/upload-insurance.module.css";
import payInsuranceStyles from "./pages/insurance/payment/pay.module.css";
import insuranceLemonadeStyles from "./pages/insurance/lemonade/lemonade.module.css";
import insuranceCoverageStyles from "./pages/insurance/my-policy/coverage/coverage.module.css";
import enhancementsStyles from "./pages/enhancements/enhancements.module.css";
import insuranceDeductibleStyles from "./pages/insurance/my-policy/deductible/deductible.module.css";
import addCardInsuranceStyles from "./pages/insurance/payment/add-card.module.css";
import confirmationStyles from "./pages/insurance/confirmation/confirmation.module.css";
import paymentHistoryStyles from "./pages/payment-history/payment-history.module.css";
import insuranceClaimsFaqStyles from "./pages/insurance/boost/claims/faq.module.css";
import additionalInsuredStyles from "./pages/insurance/boost/additional-insured/additional-insured.module.css";
import editAdditionalInsuredStyles from "./pages/insurance/boost/additional-insured/edit-additional-insured.module.css";
import additionalInterestedStyles from "./pages/insurance/boost/additional-interested/additional-interested.module.css";
import editAdditionalInterestedStyles from "./pages/insurance/boost/additional-interested/edit-additional-interested.module.css";
import confirmStyles from "./pages/confirm/confirm.module.css";
import confirmAddressStyles from "./pages/confirm-address/confirm-address.module.css";
import confirmAddressConfirmationStyles from "./pages/confirm-address/confirmation.module.css";
import cancelPolicyStyles from "./pages/cancel-policy/cancel-policy.module.css";

import NotFound from "./pages/errors/not-found";
import GeneralErrorPage from "./pages/errors";
import PaymentAuth from "./pages/payment-auth";
import Enhancements from "./pages/enhancements";
import CancelPolicy from "./pages/cancel-policy";
import CancelPolicyConfirmation from "./pages/cancel-policy/confirm";

export interface IRoute {
  path: string;
  exact: boolean;
  isPrivate: boolean;
  Layout: Function;
  Component: Function;
  className?: string;
  layoutClass?: string;
  headerClass?: string;
  showBackButton?: boolean;
  isBlackButton?: boolean;
  isCustomHeader?: boolean;
}

const routes: IRoute[] = [
  {
    path: "/",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: Home,
    showBackButton: false,
    layoutClass: homeStyles.layout,
    headerClass: insuranceStyles.header,
  },
  {
    path: "/confirm",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: Confirm,
    layoutClass: confirmStyles.layout,
  },
  {
    path: "/confirm-address",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: ConfirmAddress,
    layoutClass: confirmAddressStyles.layout,
    showBackButton: false,
  },
  {
    path: "/confirm-address/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: ConfirmAddressConfirmation,
    headerClass: confirmAddressConfirmationStyles.header,
    layoutClass: confirmAddressConfirmationStyles.layout,
    showBackButton: false,
  },
  {
    path: "/insurance",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceChoose,
    showBackButton: false,
    layoutClass: insuranceStyles.layout,
    headerClass: insuranceStyles.header,
  },
  {
    path: "/insurance/info",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceInfo,
    headerClass: insuranceInfoStyles.header,
    showBackButton: true,
  },
  {
    path: "/insurance/faq",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceFaq,
    headerClass: insuranceInfoStyles.header,
    layoutClass: insuranceInfoStyles.layout,
    showBackButton: true,
  },
  {
    path: "/insurance/terms",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceTerms,
    headerClass: insuranceInfoStyles.header,
    layoutClass: insuranceInfoStyles.layout,
    showBackButton: true,
  },
  {
    path: "/insurance/lemonade",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceLemonadeHome,
  },
  {
    path: "/insurance/lemonade/start",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceLemonade,
    layoutClass: insuranceLemonadeStyles.layout,
    headerClass: insuranceLemonadeStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/error",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceErrorGeneral,
    layoutClass: insuranceErrorStyles.layout,
    headerClass: insuranceErrorStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/error/payment",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceErrorPayment,
    layoutClass: insuranceErrorStyles.layout,
    headerClass: insuranceErrorStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/error/check",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceErrorCheckPayment,
    layoutClass: insuranceErrorStyles.layout,
    headerClass: insuranceErrorStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/boost",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostHome,
  },
  {
    path: "/insurance/boost/error",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostErrorGeneral,
    layoutClass: insuranceErrorBoostStyles.layout,
    headerClass: insuranceErrorBoostStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/boost/error/payment",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostErrorPayment,
    layoutClass: insuranceErrorBoostStyles.layout,
    headerClass: insuranceErrorBoostStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/boost/error/check",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostErrorCheckPayment,
    layoutClass: insuranceErrorBoostStyles.layout,
    headerClass: insuranceErrorBoostStyles.header,
    isBlackButton: false,
  },
  {
    path: "/insurance/boost/edit/:id",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoost,
    headerClass: insuranceStyles.header,
    showBackButton: false,
  },
  {
    path: "/insurance/boost/edit/:id/сoverage",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostCoverage,
    layoutClass: insuranceCoverageBoostStyles.layout,
    headerClass: insuranceCoverageBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/deductible",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostDeductible,
    layoutClass: insuranceDeductibleBoostStyles.layout,
    headerClass: insuranceDeductibleBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/extra",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostExtraCoverage,
    layoutClass: insuranceExtraCoverageBoostStyles.layout,
    headerClass: insuranceExtraCoverageBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/extra/scheduledProperty",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostExtraCoverageScheduledProperty,
    layoutClass: insuranceExtraCoverageBoostStyles.layoutBlack,
    headerClass: insuranceExtraCoverageBoostStyles.headerBlack,
  },
  {
    path: "/insurance/boost/edit/:id/extra/scheduledProperty/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostExtraCoverageScheduledPropertyAdd,
    layoutClass: insuranceExtraCoverageBoostStyles.layout,
    headerClass: insuranceExtraCoverageBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/extra/:type",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostExtraCoverageDetails,
    layoutClass: insuranceExtraCoverageBoostStyles.layoutBlack,
    headerClass: insuranceExtraCoverageBoostStyles.headerBlack,
  },
  {
    path: "/insurance/boost/edit/:id/payment",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostPayment,
    headerClass: payInsuranceBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/payment/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostAddCard,
    headerClass: addCardInsuranceBoostStyles.header,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInsured",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AdditionalInsured,
    headerClass: additionalInsuredStyles.header,
    layoutClass: additionalInsuredStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInsured/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInsured,
    headerClass: editAdditionalInsuredStyles.header,
    layoutClass: editAdditionalInsuredStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInsured/:partyId",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInsured,
    headerClass: editAdditionalInsuredStyles.header,
    layoutClass: editAdditionalInsuredStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInterested",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AdditionalInterested,
    headerClass: additionalInterestedStyles.header,
    layoutClass: additionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInterested/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInterested,
    headerClass: editAdditionalInterestedStyles.header,
    layoutClass: editAdditionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/additionalInterested/:partyId",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInterested,
    headerClass: editAdditionalInterestedStyles.header,
    layoutClass: editAdditionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/edit/:id/success",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostPurchased,
    headerClass: confirmationBoostStyles.header,
    layoutClass: confirmationBoostStyles.layout,
    showBackButton: false,
  },
  {
    path: "/insurance/boost/policy/:id",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostDetails,
    headerClass: insuranceDetailsBoostStyles.header,
    showBackButton: false,
  },
  {
    path: "/insurance/boost/policy/:id/additionalInterested",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AdditionalInterested,
    headerClass: additionalInterestedStyles.header,
    layoutClass: additionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/policy/:id/additionalInterested/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInterested,
    headerClass: editAdditionalInterestedStyles.header,
    layoutClass: editAdditionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/policy/:id/additionalInterested/:partyId",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: EditAdditionalInterested,
    headerClass: editAdditionalInterestedStyles.header,
    layoutClass: editAdditionalInterestedStyles.layout,
  },
  {
    path: "/insurance/boost/policy/:id/claims/faq",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostClaimFaq,
    headerClass: insuranceClaimsFaqStyles.header,
    layoutClass: insuranceClaimsFaqStyles.layout,
    showBackButton: true,
  },
  {
    path: "/insurance/boost/policy/:id/claims/new",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceBoostAddClaim,
    headerClass: addClaimBoostStyles.header,
    layoutClass: addClaimBoostStyles.layout,
  },
  {
    path: "/insurance/boost/policy/:id/claims/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostClaimConfirmation,
    headerClass: claimConfirmationStyles.header,
    layoutClass: claimConfirmationStyles.layout,
    showBackButton: false,
  },
  {
    path: "/insurance/boost/policy/:id/claims",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostClaimsList,
    headerClass: claimsListStyles.header,
    layoutClass: claimsListStyles.layout,
  },
  {
    path: "/insurance/boost/policy/:id/claims/:claimId",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceBoostClaimDetails,
    headerClass: claimDetailsStyles.header,
    layoutClass: claimDetailsStyles.layout,
  },
  {
    path: "/insurance/edit/:id",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceDetails,
    headerClass: insuranceStyles.header,
  },
  {
    path: "/insurance/edit/:id/coverage",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceCoverage,
    layoutClass: insuranceCoverageStyles.layout,
    headerClass: insuranceCoverageStyles.header,
  },
  {
    path: "/insurance/edit/:id/deductible",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsuranceDeductible,
    layoutClass: insuranceDeductibleStyles.layout,
    headerClass: insuranceDeductibleStyles.header,
  },
  {
    path: "/insurance/edit/:id/payment",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsurancePayment,
    headerClass: payInsuranceStyles.header,
    layoutClass: payInsuranceStyles.layout,
  },
  {
    path: "/insurance/edit/:id/payment/add",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: InsuranceAddCard,
    headerClass: addCardInsuranceStyles.header,
    layoutClass: addCardInsuranceStyles.layout,
  },
  {
    path: "/insurance/edit/:id/success",
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: InsurancePurchased,
    headerClass: confirmationStyles.header,
    layoutClass: confirmationStyles.layout,
    showBackButton: false,
  },
  {
    path: "/insurance/upload",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: UploadInsurance,
    layoutClass: insuranceUploadStyles.layout,
    headerClass: insuranceUploadStyles.header,
  },
  {
    path: "/insurance/upload/completed",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: UploadInsuranceCompleted,
    layoutClass: insuranceUploadCompletedStyles.layout,
    headerClass: insuranceUploadCompletedStyles.header,
    showBackButton: false,
  },
  {
    path: "/payment-history",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PaymentHistory,
    headerClass: paymentHistoryStyles.header,
    layoutClass: paymentHistoryStyles.layout,
  },
  {
    path: "/enhancements",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: Enhancements,
    className: enhancementsStyles.layout,
    showBackButton: false,
  },
  {
    path: "/payment-auth/:clientSecret",
    exact: true,
    isPrivate: false,
    Layout: ({
      children,
    }: {
      children: React.ReactChild | React.ReactChild[];
    }) => <>{children}</>,
    Component: PaymentAuth,
  },
  {
    path: "/policy/cancel/:token",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: CancelPolicy,
    showBackButton: false,
    className: cancelPolicyStyles.layout,
  },
  {
    path: "/policy/cancel/:token/confirmation",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: CancelPolicyConfirmation,
    headerClass: confirmationBoostStyles.header,
    layoutClass: confirmationBoostStyles.layout,
    showBackButton: false,
  },
  {
    path: "/404",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: NotFound,
    showBackButton: false,
  },
  {
    path: "/error",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: GeneralErrorPage,
    showBackButton: false,
  },
];

export default routes;
