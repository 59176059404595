import * as React from "react";
import cx from "classnames";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { showModal } from "../../modules/modalProvider";
import Header from "../../components/header";
import GlobalSpinner from "../../components/spinner/global";
import { SafeAreaContainer } from "../../components/container";
import Confirm from "../../components/confirm";
import { formatMoneyInCents } from "../../helpers/common";
import { mapTypeToTitle } from "../../helpers/constants";
import {
  fetchPolicyBoostByToken,
  FetchPolicyBoostByToken,
  cancelPolicyBoostByToken,
  CancelPolicyBoostByToken,
} from "../../resources/insurance/insurance.actions";
import { getBoostPolicy } from "../../resources/insurance/insurance.selectors";
import { IBoostPolicy } from "../../resources/insurance/types";
import { IStore } from "../../resources/types";

import { refundStates, getQuoteSummary } from "../insurance/boost/boost.config";

import GTag from "../../gtag";

import styles from "./cancel-policy.module.css";

interface IProps {
  policy?: IBoostPolicy;
  fetchPolicyBoostByToken: FetchPolicyBoostByToken;
  cancelPolicyBoostByToken: CancelPolicyBoostByToken;
}

function CancelPolicy({
  policy,
  fetchPolicyBoostByToken,
  cancelPolicyBoostByToken,
}: IProps) {
  const history = useHistory();
  const { token } = useParams();

  const cancelPolicy = React.useCallback(async () => {
    GTag.event("boost_policy_cancellation_cancel", "Click");
    await cancelPolicyBoostByToken(token);
    history.push(`/policy/cancel/${token}/confirmation`);
  }, [cancelPolicyBoostByToken, history, token]);

  const openCancel = React.useCallback(() => {
    const description = refundStates.includes(
      policy?.policy.data.attributes.policy_state
    )
      ? "By state law, coverage will end immediately and you will receive a pro-rated refund for the unused days remaining in the current policy period. Refunds take up to 5 business days to process."
      : "By state law, coverage will continue until the end of the current policy period.";
    showModal(
      <Confirm
        action={() => cancelPolicy()}
        title="Are you sure to cancel your policy?"
        description={description}
        yes="Confirm"
        blockClosing
      />,
      undefined
    );
  }, [policy, cancelPolicy]);

  const fetchPolicy = React.useCallback(async () => {
    try {
      await fetchPolicyBoostByToken(token);
    } catch (err) {
      history.push(
        "/error?message=Your insurance policy has already been canceled.&contact=true"
      );
    }
  }, [fetchPolicyBoostByToken, token, history]);

  React.useEffect(() => {
    if (!policy) {
      fetchPolicy();
    } else {
      openCancel();
    }
  }, [policy, openCancel, fetchPolicy]);

  React.useEffect(() => {
    GTag.event("boost_policy_cancellation", "Page");
  });

  if (!policy) {
    return <GlobalSpinner />;
  }

  const getClaimsText = (count: number) => {
    if (count === 0) {
      return "No active claims";
    }
    return `${policy.numberOfClaims} active claim${count > 1 ? "s" : ""}`;
  };

  let policyStatus = "";
  const startDate = moment.utc(policy.summary.startDate).startOf("day");
  const endDate = moment.utc(policy.summary.endDate).startOf("day");
  const now = moment.utc().startOf("day");
  if (startDate > now) {
    policyStatus = `starts on ${startDate.format("MMMM D, YYYY")}`;
  } else {
    policyStatus = `until ${endDate.format("MMMM D, YYYY")}`;
  }
  if (policy.previousActivePolicyId && startDate > now) {
    policyStatus = `Coverage changes in your insurance policy will be effective starting ${startDate.format(
      "MMMM D, YYYY"
    )}.`;
  }

  const status = policy.status === "FUTURE" ? "Future" : "Active";
  const { primary: summaryItems, details: summaryDetails } = getQuoteSummary(
    policy.summary
  );

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.header}>
          Renters
          <br />
          insurance
        </Header>
      </SafeAreaContainer>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.title}>Insurance policy</div>
          <div className={styles.content}>
            <span className={styles.status}>{status}</span> {policyStatus}
          </div>
          {policy.url && (
            <a
              href={`${policy.url}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Review policy
            </a>
          )}
          {!policy.url && (
            <div className={styles.linkText}>
              Your policy document will be available in 1-2 business days
            </div>
          )}
        </div>
        <hr className={styles.hr} />
        <div className={styles.group}>
          <div className={styles.title}>Monthly premium</div>
          <div className={styles.content}>
            <span className={styles.status}>
              {formatMoneyInCents(policy.summary.paymentAmount)}/mo
            </span>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.title}>Coverage details</div>
          <div className={styles.content}>
            {summaryItems.map((s, index) => (
              <div key={index}>
                {index === 0 && (
                  <div className={styles.summaryDetailsRowFirst}>
                    <div>{s.name}</div>
                    <div>{s.value}</div>
                  </div>
                )}
                {index > 0 && (
                  <div className={styles.summaryDetailsRow}>
                    <div>{s.name}</div>
                    <div>{s.value}</div>
                  </div>
                )}
              </div>
            ))}
            <div>
              {summaryDetails.map((s) => (
                <div className={styles.summaryDetailsRow} key={s.name}>
                  <div>{s.name}</div>
                  <div>{s.value}</div>
                </div>
              ))}
              <div className={styles.summaryDetailsDescription}>
                * Coverage is automatic and not adjustable.
              </div>
            </div>
          </div>
        </div>
        {policy.summary.additionalInsured.length > 1 && (
          <>
            <hr className={styles.hr} />
            <div className={styles.group}>
              <div className={styles.title}>Additional insured</div>
              <div className={styles.content}>
                {policy.summary.additionalInsured
                  .filter((x) => !x.isPrimary)
                  .map((party) => (
                    <div className={styles.status}>
                      {party.businessName ||
                        `${party.firstName} ${party.lastName}`}
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
        {policy.paymentMethod && policy.paymentMethod.card && (
          <>
            <hr className={styles.hr} />
            <div className={styles.group}>
              <div className={styles.title}>Payment method</div>
              <div className={styles.content}>
                <span className={styles.status}>
                  {mapTypeToTitle[policy.paymentMethod.card.brand]} *
                  {policy.paymentMethod.card.last4}
                </span>
              </div>
            </div>
          </>
        )}
        {(policy.status !== "FUTURE" || policy.numberOfClaims > 0) && (
          <>
            <hr className={styles.hr} />
            <div className={styles.group}>
              <div className={styles.title}>Claims</div>
              <div className={styles.content}>
                <div className={cx(styles.claims)}>
                  {getClaimsText(policy.numberOfClaims)}
                </div>
              </div>
            </div>
          </>
        )}
        <hr className={styles.hr} />
      </div>
    </>
  );
}
CancelPolicy.displayName = "CancelPolicy";
export default connect(
  (state: IStore) => ({
    policy: getBoostPolicy(state),
  }),
  {
    fetchPolicyBoostByToken,
    cancelPolicyBoostByToken,
  }
)(CancelPolicy);
