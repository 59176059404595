import * as React from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import ModalProvider from "./modules/modalProvider";
import { IStore } from "./resources/types";
import { getQueryObject } from "./helpers/querystring";
import configureStore from "./resources/store";
import Layout from "./pages/layout";
import AuthProviderWrap, { useAuth } from "./auth";
import { useAuth0 } from "./react-auth0-wrapper";
import Spinner from "./components/spinner";
import ReactHeap from "./heapio";
import config from "./config";

const history = createBrowserHistory();

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

const initialState: IStore = {};
const store = configureStore(initialState, history);

function ReduxProviderWrap({ children }: IProps) {
  return <Provider store={store}>{children}</Provider>;
}

const App = () => {
  const authData = useAuth();
  const auth0Data = useAuth0();
  const loading = authData ? authData.loading : auth0Data.loading;

  React.useEffect(() => {
    if (window.location.search.includes("error_description=")) {
      const q = getQueryObject();
      let message = q.error_description;
      if (q.error_description && q.error_description.includes("blocked")) {
        message =
          "Looks like your account is inactive. Please contact your property manager to solve this issue";
      }
      window.location.href = `/error?message=${message}`;
    }
  }, []);

  React.useEffect(() => {
    ReactHeap.initialize(config.heapId);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <ConnectedRouter history={history}>
      <Layout />
      <ModalProvider />
    </ConnectedRouter>
  );
};

App.displayName = "App";
export default function EnhancedApp() {
  return (
    <React.StrictMode>
      <AuthProviderWrap>
        <ReduxProviderWrap>
          <App />
        </ReduxProviderWrap>
      </AuthProviderWrap>
    </React.StrictMode>
  );
}
