import createApiAction from "../../helpers/apiActionCreator";
import * as api from "./payment.api";
import { IPayment } from "./types";

export const FETCH_PAYMENTS = "FETCH_PAYMENTS";

export type FetchPayments = () => Promise<IPayment[]>;
export function fetchPayments() {
  return createApiAction(FETCH_PAYMENTS, api.getPayments)({});
}
