import React from "react";
import { DELAY_BEFORE_REMOVE_MSEC } from "./constants";

export const ActionType = {
  SHOW: "@react-redux-modal-provider.show",
  HIDE: "@react-redux-modal-provider.hide",
  REMOVE: "@react-redux-modal-provider.remove"
};

export const showModal = (
  component: React.ReactElement,
  id?: string,
  delayBeforeRemoveMsec: number = DELAY_BEFORE_REMOVE_MSEC,
  dependant: boolean = false // close this modal when any other modal closed
) => {
  if (!component) {
    return undefined;
  }

  return {
    type: ActionType.SHOW,
    id,
    component,
    dependant,
    delayBeforeRemoveMsec
  };
};

export const hideModal = (id: number) => ({
  type: ActionType.HIDE,
  id
});

export const removeModal = (id: number) => ({
  type: ActionType.REMOVE,
  id
});
