import React from "react";

import { IModalProps } from "./types";

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

export function modalComponent<P>(
  Component: React.ComponentType<P>
): React.ComponentType<Diff<P, IModalProps>> {
  function component(props: Diff<P, IModalProps>) {
    return <Component {...(props as P)} />;
  }
  return component;
}
