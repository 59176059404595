import * as React from "react";
import { connect } from "react-redux";
import Header from "../../../components/header";
import { SafeAreaContainer } from "../../../components/container";

import GTag from "../../../gtag";

import styles from "./info.module.css";

function Insurance() {
  React.useEffect(() => {
    GTag.event("terms_and_conditions", "Page");
  });

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header className={styles.head}>Terms & Conditions</Header>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.descriptionContainer}>
        <div className={styles.description}>
          Renters insurance helps protect you when the unexpected happens.
        </div>
      </SafeAreaContainer>
    </>
  );
}

Insurance.displayName = "Insurance";
export default connect(() => ({}), {})(Insurance);
