import * as React from "react";

import styles from "./upload.module.css";

interface IProps extends React.HTMLProps<HTMLInputElement> {
  onUpload: (files: FileList | null) => void;
}

export default function Upload({ onUpload, ...props }: IProps) {
  const onUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(event.target.files);
  };

  return (
    <input
      {...props}
      type="file"
      onChange={onUploadFiles}
      className={styles.input}
    />
  );
}
Upload.displayName = "Upload";
