export const SAVE_FORM = "SAVE_FORM";
export const RESET_FORM = "RESET_FORM";

export function saveForm(name: string, values: object) {
  return { type: SAVE_FORM, payload: { name, values } };
}

export function resetForm(name: string) {
  return { type: RESET_FORM, payload: { name } };
}
