import isArray from "lodash/isArray";

export default function mapToFormData(
  data: { [key: string]: any },
  form?: FormData,
  prefix?: string
) {
  const formData = form || new FormData();

  // add files at the end to use other fields while processing
  const files: Array<{ key: string; value: File }> = [];
  Object.keys(data).forEach(key => {
    const fieldName = prefix ? `${prefix}.${key}` : key;

    switch (typeof data[key]) {
      case "string": {
        formData.append(fieldName, data[key]);
        break;
      }
      case "object": {
        if (isArray(data[key])) {
          (data[key] as any[]).forEach(item => {
            formData.append(`${fieldName}[]`, item);
          });
        } else if (data[key] instanceof File) {
          files.push({ key: fieldName, value: data[key] as File });
        } else if (data[key] instanceof Date) {
          formData.append(fieldName, data[key].toISOString());
        } else {
          mapToFormData(data[key], formData, fieldName);
        }
        break;
      }
      default: {
        if (data[key]) {
          formData.append(fieldName, data[key]);
        }
        break;
      }
    }
  });

  files.forEach(({ key, value }) => {
    formData.append(key, value);
  });

  return formData;
}
