import * as React from "react";
import cx from "classnames";

import styles from "./back.module.css";

import { ReactComponent as BackBtn } from "../icons/back-button.svg";
import { ReactComponent as BackBtnWhite } from "../icons/back-button-white.svg";
import { ReactComponent as CloseBtn } from "../icons/close-white.svg";

interface IProps {
  onClick: () => void;
  isBlack?: boolean;
  isClose?: boolean;
}

export default function BackButton({
  onClick,
  isBlack = true,
  isClose = false,
}: IProps) {
  return (
    <div
      className={cx(styles.back, isClose ? styles.close : null)}
      onClick={onClick}
      data-id="backBtn"
    >
      {!isClose && (isBlack ? <BackBtn /> : <BackBtnWhite />)}
      {isClose && <CloseBtn />}
    </div>
  );
}
BackButton.displayName = "BackButton";
