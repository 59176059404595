import * as React from "react";
import cx from "classnames";
import moment from "moment";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow-black.svg";
import { IInsurance } from "../../resources/insurance/types";

import styles from "./task.module.css";

interface IProps {
  title: string;
  description?: string;
  isDisabled?: boolean;
  isComplete?: boolean;
  insurance?: IInsurance;
  onClick: () => void;
}

export default function Task({
  title,
  description,
  insurance,
  isDisabled,
  isComplete,
  onClick
}: IProps) {
  const canClickOnInsurance = (insurance?: IInsurance) => {
    if (
      insurance &&
      insurance.type === "LEMONADE" &&
      insurance.status === "INSUFFICIENT_COVERAGE"
    ) {
      return false;
    }
    if (insurance && insurance.type === "BOOST" && insurance.policyId) {
      return true;
    }
    return (
      !insurance ||
      insurance.status === "DECLINED" ||
      insurance.status === "EXPIRED" ||
      insurance.status === "CANCELED" ||
      insurance.status === "INSUFFICIENT_COVERAGE" ||
      !isComplete
    );
  };

  const onTaskClick = () => {
    if (canClickOnInsurance(insurance)) {
      return onClick();
    }
    return null;
  };

  let status: React.ReactNode = null;
  let descriptionLine2 = "";
  if (insurance) {
    switch ((insurance.status || "").toUpperCase()) {
      case "IN_REVIEW": {
        status = <span>In Review</span>;
        break;
      }
      case "DECLINED": {
        status = <span className={styles.red}>Rejected</span>;
        break;
      }
      case "CANCELED": {
        status = <span className={styles.red}>Canceled</span>;
        break;
      }
      case "EXPIRED": {
        status = <span className={styles.red}>Expired</span>;
        break;
      }
      case "INSUFFICIENT_COVERAGE": {
        status = <span className={styles.red}>Insufficient coverage</span>;
        break;
      }
      case "CONFIRMED":
      case "ACTIVE": {
        status = <span className={styles.green}>Active</span>;
        break;
      }
      case "FUTURE": {
        status = <span className={styles.green}>Future</span>;
        if (insurance.type === "EXTERNAL") {
          descriptionLine2 += `Starts on ${moment(insurance.startDate).format(
            "MM/DD/YYYY"
          )}`;
        }
        break;
      }
      default: {
        status = null;
      }
    }
  }
  const canClick = !isDisabled && canClickOnInsurance(insurance);

  return (
    <div
      onClick={onTaskClick}
      className={cx(styles.task, canClick ? styles.pointer : "")}
    >
      <div className={cx(styles.label, isDisabled ? styles.disabled : "")}>
        {canClick && <ArrowIcon className={styles.icon} />}
        <h3>{title}</h3>
        <p>{description}</p>
        {descriptionLine2 && <p>{descriptionLine2}</p>}
        <div className={styles.action}>{status}</div>
      </div>
    </div>
  );
}
Task.displayName = "InsuranceTask";
