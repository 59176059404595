import * as React from "react";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import { Formik, FormikProps } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import LayoutHeader from "../../../../layouts/main/header";
import Container from "../../../../components/container";
import Button from "../../../../components/action-button";
import Confirm from "../../../../components/confirm";
import Input from "../../../../components/input";
import UploadFiles from "../../../../components/upload-files";
import Textarea from "../../../../components/textarea";
import Header from "../../../../components/header";
import DateComponent from "../../../../components/date/inline";
import { showModal } from "../../../../modules/modalProvider";
import { getUser } from "../../../../resources/user/user.selectors";
import { IUser } from "../../../../resources/user/types";
import mimeTypes from "../../../../helpers/mimeTypes";

import GTag from "../../../../gtag";

import { IStore } from "../../../../resources/types";
import { fetchUser, FetchUser } from "../../../../resources/user/user.actions";
import {
  createClaimBoost,
  CreateClaimBoost,
} from "../../../../resources/insurance/insurance.actions";

import { saveForm, resetForm } from "../../../../resources/form/form.actions";

import schema, { IFormValues } from "./add-claim.validation";

import styles from "./add-claim.module.css";

const FORM_NAME = "addClaim";

interface IProps {
  user: IUser;
  createClaimBoost: CreateClaimBoost;
  fetchUser: FetchUser;
}

function AddClaim({ user, createClaimBoost, fetchUser }: IProps) {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const initialValues: IFormValues = {
    phone: user.phone,
    email: user.email,
    description: "",
    dateOfLoss: undefined,
    files: [],
  };

  const storeValues =
    useSelector((state: IStore) =>
      state.form ? state.form[FORM_NAME] : undefined
    ) || initialValues;

  React.useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user, fetchUser]);

  React.useEffect(() => {
    GTag.event("add_claim", "Page");
  }, []);

  const onFaqClick = () => {
    history.push(`/insurance/boost/policy/${id}/claims/faq`);
  };

  const openRevert = () => {
    if (!isEqual(storeValues, initialValues)) {
      showModal(
        <Confirm
          action={async () => {
            dispatch(resetForm(FORM_NAME));
            history.goBack();
          }}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onClickSubmit(formValues: IFormValues) {
    if (formValues.isDamaged) {
      return;
    }

    GTag.event("submit_claim", "Click");

    await createClaimBoost({
      ...formValues,
      policyId: id,
    });

    dispatch(resetForm(FORM_NAME));
    history.push(`/insurance/boost/policy/${id}/claims/success`);
  }

  function onValidate(formValues: IFormValues) {
    if (!isEqual(formValues, storeValues)) {
      dispatch(saveForm(FORM_NAME, formValues));
    }
  }

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <Header className={styles.head}>
          Create <br /> new claim
        </Header>

        <Formik<IFormValues>
          initialValues={storeValues}
          validateOnBlur
          validateOnChange={false}
          validationSchema={schema}
          validate={onValidate}
          enableReinitialize
          onSubmit={onClickSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            setTouched,
            touched,
            errors,
            values,
          }: FormikProps<IFormValues>) => {
            return (
              <form onSubmit={handleSubmit} className={cx(styles.form)}>
                <div className={styles.content}>
                  <div className={styles.section1}>
                    <div className={cx(styles.row, styles.panel)}>
                      <DateComponent
                        className={styles.date}
                        labelClassName={styles.dateLabel}
                        startDate={moment
                          .utc()
                          .subtract(1, "month")
                          .startOf("day")
                          .toDate()}
                        endDate={moment.utc().startOf("day").toDate()}
                        date={values.dateOfLoss}
                        onChange={(d: Date) => {
                          setFieldValue("dateOfLoss", d);
                          setTimeout(() => setFieldTouched("dateOfLoss", true));
                        }}
                        title="Date of loss"
                      />
                      {errors.dateOfLoss && touched.dateOfLoss && (
                        <div className={styles.errorMessage}>
                          {touched.dateOfLoss && errors.dateOfLoss}
                        </div>
                      )}
                    </div>

                    <div className={styles.row}>
                      <Textarea
                        type="text"
                        data-id="descriptionInput"
                        className={cx(styles.textarea, {
                          [styles.error]:
                            touched.description && errors.description,
                        })}
                        name="description"
                        placeholder="Please describe the loss (550 characters max). If the loss occurred somewhere besides the address on the policy, please include any location information you have."
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <div className={styles.errorMessage}>
                          {touched.description && errors.description}
                        </div>
                      )}
                    </div>
                    <div className={styles.uploadConatiner}>
                      <UploadFiles
                        accept={[
                          ...mimeTypes.image,
                          ...mimeTypes.pdf,
                          ...mimeTypes.excel,
                          ...mimeTypes.csv,
                        ].join(",")}
                        text="Drag or click to add an image and document"
                        setIsDamanged={(d: boolean) =>
                          setFieldValue("isDamaged", d)
                        }
                        files={values.files || []}
                        allowedFileTypes={["image", "pdf", "csv", "excel"]}
                        rejectedFileTypes={["gif"]}
                        errorsFiles={errors.files}
                        touchedFiles={touched.files}
                        setTouched={() =>
                          setTouched({
                            files: true,
                            isDamaged: true,
                          })
                        }
                        setFiles={(files: File[]) => {
                          setFieldValue("files", files);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.section2}>
                    <div className={styles.sectionTitle}>
                      Your contact information
                    </div>

                    <div className={cx(styles.row, styles.contactRow)}>
                      <Input
                        data-id="phoneInput"
                        type="tel"
                        className={cx(styles.input, {
                          [styles.error]: touched.phone && errors.phone,
                        })}
                        placeholder="Enter contact phone"
                        name="phone"
                        mask="(999) 999-9999"
                        formatChars={{
                          "9": "[0-9]",
                          "*": "",
                        }}
                        autoComplete="off"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && (
                        <div className={styles.errorMessage}>
                          {touched.phone && errors.phone}
                        </div>
                      )}
                    </div>
                    <div className={cx(styles.row, styles.contactRow)}>
                      <Input
                        data-id="emailInput"
                        type="text"
                        className={cx(styles.input, {
                          [styles.error]: touched.email && errors.email,
                        })}
                        placeholder="Enter contact email"
                        name="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && (
                        <div className={styles.errorMessage}>
                          {touched.email && errors.email}
                        </div>
                      )}
                    </div>

                    <div className={styles.linkBtn}>
                      <button onClick={onFaqClick} data-id="faqBtn">
                        Tap here to view FAQ
                      </button>
                    </div>
                  </div>
                </div>

                <Button type="submit" data-id="submitBtn">
                  Submit claim
                </Button>
              </form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}

AddClaim.displayName = "AddClaim";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {
    createClaimBoost,
    fetchUser,
  }
)(AddClaim);
