import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import values from "lodash/values";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Price from "../../components/pricing";
import Button from "../../../../components/action-button";
import Coverage from "../../components/coverage";
import { showModal } from "../../../../modules/modalProvider";
import {
  updateQuoteBoost,
  UpdateQuoteBoost,
} from "../../../../resources/insurance/insurance.actions";
import { IBoostQuote } from "../../../../resources/insurance/types";
import { IStore } from "../../../../resources/types";
import { deductibleOptions } from "../boost.config";

import styles from "./deductible.module.css";

interface IProps {
  quote: IBoostQuote;
  updateQuoteBoost: UpdateQuoteBoost;
}

function Deductible({ quote, updateQuoteBoost }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [value, setValue] = React.useState(
    quote ? quote.summary.baseDeductible : 0
  );
  const [originalValue, setOriginalValue] = React.useState(
    quote ? quote.summary.baseDeductible : 0
  );
  const [serverError, setServerError] = React.useState("");

  const isChanged = value !== originalValue;

  async function setDeductible(newValue: number) {
    setServerError("");
    const prevValue = value;
    try {
      setValue(newValue);
      await updateQuoteBoost({ baseDeductible: newValue });
    } catch (err) {
      setValue(prevValue);
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  function onSubmit() {
    setServerError("");
    if (!quote) {
      return;
    }
    setOriginalValue(value);
    history.push(`/insurance/boost/edit/${id}`);
  }

  const openRevert = () => {
    if (isChanged) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (isChanged) {
        await updateQuoteBoost({ baseDeductible: originalValue });
      }
      history.goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const disableButton = !isChanged;

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <SafeAreaContainer className={styles.head}>
          <Header>
            Set
            <br />
            deductible
          </Header>
        </SafeAreaContainer>
        <hr className={styles.hr} />
        <SafeAreaContainer>
          <Price amount={quote.summary.paymentAmount} period="month" />
          <Coverage
            cents
            data-id="deductible"
            title="Deductible"
            description="The amount that’s deducted from any claim payment you receive. For example, setting a $500 deductible means that if you file a claim for $5,000 and it's approved, you will be paid $4,500."
            value={quote.summary.baseDeductible}
            options={deductibleOptions}
            onChange={setDeductible}
          />
          <div className={styles.errorBlock}>{serverError}</div>
        </SafeAreaContainer>
        <Button
          disabled={disableButton}
          onClick={onSubmit}
          data-id="setCoverageBtn"
        >
          Set deductible
        </Button>
      </Container>
    </>
  );
}

Deductible.displayName = "Deductible";
export default withBoostQuote(
  connect((state: IStore) => ({}), {
    updateQuoteBoost,
  })(Deductible)
);
