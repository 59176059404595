import * as React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../../../../components/header";
import { SafeAreaContainer } from "../../../../components/container";
import Button from "../../../../components/action-button";
import Checkbox from "../../../../components/checkbox";
import {
  setBoostFactors,
  SetBoostFactors,
} from "../../../../resources/insurance/insurance.actions";
import { getBoostFactors } from "../../../../resources/insurance/insurance.selectors";
import { IFactors } from "../../../../resources/insurance/types";
import { IStore } from "../../../../resources/types";

import { IExtraCoverage, EXTRA_COVERAGES } from "../boost.config";

import { ReactComponent as CheckBlueIcon } from "../../../../components/icons/check-blue.svg";

import styles from "./extra-coverage.module.css";

interface IProps {
  factors: IFactors;
  setBoostFactors: SetBoostFactors;
}

function ScheduledProperty({ factors, setBoostFactors }: IProps) {
  const history = useHistory();

  const [currentFactors, setCurrentFactors] = React.useState(
    Object.keys(factors).filter((x: string) => !!factors[x])
  );

  React.useEffect(() => {
    if (!currentFactors) {
      setCurrentFactors(
        Object.keys(factors) || ["jewelry", "fine_arts", "bicycles"]
      );
    }
  }, [currentFactors, factors]);

  if (!currentFactors) {
    return null;
  }

  const selectedCoverage = EXTRA_COVERAGES.find(
    (x: IExtraCoverage) => x.value === "scheduledProperty"
  );
  if (!selectedCoverage) {
    return null;
  }

  const onFactorsChange = (value: string) => {
    if (!currentFactors) {
      setCurrentFactors([value]);
      return;
    }
    if (currentFactors.includes(value)) {
      setCurrentFactors(currentFactors.filter((x: string) => x !== value));
    } else {
      setCurrentFactors([...currentFactors, value]);
    }
  };

  async function onSubmit() {
    const newFactors = factors;
    currentFactors.forEach((factor: string) => {
      if (!newFactors[factor]) {
        newFactors[factor] = 100 * 100;
      }
    });
    Object.keys(newFactors).forEach((factor: string) => {
      if (!currentFactors.includes(factor)) {
        delete newFactors[factor];
      }
    });
    setBoostFactors(newFactors);
    history.goBack();
  }

  const coverage = selectedCoverage;

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>
          Add extra coverage
          <br />
          for your personal property
        </Header>
      </SafeAreaContainer>
      <SafeAreaContainer>
        {coverage &&
          selectedCoverage.factors &&
          selectedCoverage.factors.map((factor) => (
            <div className={styles.checkboxContainer} key={factor.value}>
              <Checkbox
                data-id={factor.value}
                className={styles.checkbox}
                checkmarkClassName={styles.checkmark}
                checkIcon={<CheckBlueIcon />}
                name="type"
                LabelComponent={() => (
                  <div className={styles.labelContainer}>
                    <div className={styles.labelDescription}>
                      <span className={styles.label}>{factor.title}</span>
                    </div>
                    <span />
                  </div>
                )}
                checked={
                  currentFactors ? currentFactors.includes(factor.value) : false
                }
                onChange={() => onFactorsChange(factor.value)}
              />
            </div>
          ))}
      </SafeAreaContainer>
      <Button onClick={onSubmit} data-id="addPropertyBtn">
        Add property
      </Button>
    </>
  );
}

ScheduledProperty.displayName = "ScheduledProperty";
export default connect(
  (state: IStore) => ({
    factors: getBoostFactors(state),
  }),
  {
    setBoostFactors,
  }
)(ScheduledProperty);
