import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Button from "../../../../components/action-button";
import { showModal } from "../../../../modules/modalProvider";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import {
  updateQuoteBoost,
  UpdateQuoteBoost,
  setBoostFactors,
  SetBoostFactors,
} from "../../../../resources/insurance/insurance.actions";
import { getBoostFactors } from "../../../../resources/insurance/insurance.selectors";
import { getUser } from "../../../../resources/user/user.selectors";
import { IFactors, IBoostQuote } from "../../../../resources/insurance/types";
import { IUser } from "../../../../resources/user/types";
import { IStore } from "../../../../resources/types";
import CoverageInput from "../../components/coverage";
import { formatMoneyInCents } from "../../../../helpers/common";

import { IExtraCoverage, EXTRA_COVERAGES } from "../boost.config";

import { ReactComponent as CloseIcon } from "../../../../components/icons/close-grey.svg";

import styles from "./extra-coverage.module.css";

interface IProps {
  quote: IBoostQuote;
  user?: IUser;
  factors: IFactors;
  setBoostFactors: SetBoostFactors;
  updateQuoteBoost: UpdateQuoteBoost;
}

function ScheduledProperty({
  quote,
  user,
  factors,
  setBoostFactors,
  updateQuoteBoost,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [originalFactors, setOriginalFactors] = React.useState<IFactors>({});

  const [serverError, setServerError] = React.useState("");

  React.useEffect(() => {
    if (quote.summary) {
      const extraCov = quote.summary.extraCoverages?.find(
        (x: { id: string }) => x.id === "scheduledProperty"
      );
      if (extraCov) {
        setOriginalFactors({
          ...extraCov.factors,
        });
        if (isEmpty(factors)) {
          setBoostFactors(extraCov.factors || {});
          if (history.action === "POP") {
            setBoostFactors(factors);
          }
        }
      } else if (
        Object.keys(factors).length === 0 &&
        history.action !== "POP"
      ) {
        addProperty();
      } else if (
        Object.keys(factors).length === 0 &&
        history.action === "POP"
      ) {
        goBack();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [quote, setBoostFactors]);

  if (!user || !quote.summary || !factors) {
    return null;
  }

  const goBack = () => history.replace(`/insurance/boost/edit/${id}/extra`);

  const getTotalAmount = () => {
    let amount = 0;
    Object.keys(factors).forEach((f: string) => {
      amount += factors[f] || 0;
    });
    return amount;
  };

  const AVAILABLE_COVERAGES = EXTRA_COVERAGES.filter((cov: IExtraCoverage) => {
    if (cov.availableStates) {
      if (cov.availableStates.includes(user.state)) {
        return true;
      }
      return false;
    }
    if (cov.notAvailableStates) {
      if (cov.notAvailableStates.includes(user.state)) {
        return false;
      }
      return true;
    }
    return true;
  });

  const selectedCoverage = AVAILABLE_COVERAGES.find(
    (x) => x.value === "scheduledProperty"
  );
  if (!selectedCoverage) {
    return null;
  }

  const onFactorsChange = async (value: string, limit: number) => {
    const newFactors = {
      ...factors,
      [value]: limit,
    };
    setBoostFactors(newFactors);
  };

  const onFactorRemove = (factor: { value: string }) => {
    const newFactors = {
      ...factors,
    };
    delete newFactors[factor.value];
    setBoostFactors(newFactors);
  };

  async function onSubmit() {
    setServerError("");
    if (!quote) {
      return;
    }
    let newCoverages = [...(quote?.summary?.extraCoverages || [])];
    if (isEmpty(Object.keys(factors).filter((x: string) => !!factors[x]))) {
      newCoverages = newCoverages.filter(
        (x: { id: string }) => x.id !== "scheduledProperty"
      );
    } else {
      const cov = newCoverages.find(
        (x: { id: string }) => x.id === "scheduledProperty"
      );
      if (cov) {
        cov.factors = factors;
      } else {
        newCoverages.push({ id: "scheduledProperty", factors });
      }
    }

    await updateQuoteBoost({
      extraCoverages: newCoverages.map((x) => ({
        id: x.id,
        limit: x.limit,
        factors: x.factors,
      })),
    });
    setOriginalFactors(factors || {});
    goBack();
  }

  const addProperty = () =>
    history.push(`/insurance/boost/edit/${id}/extra/scheduledProperty/add`);

  const openRevert = () => {
    if (!isEqual(factors, originalFactors)) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (originalFactors && !isEqual(factors, originalFactors)) {
        const newCoverages = [...(quote?.summary?.extraCoverages || [])];
        const cov = newCoverages.find(
          (x: { id: string }) => x.id === "scheduledProperty"
        );
        if (cov) {
          setBoostFactors(cov.factors || {});
        } else {
          setBoostFactors({});
        }
      }
      goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const totalAmount = getTotalAmount();
  const isLimitExceeded = totalAmount > 10000 * 100;

  return (
    <>
      <LayoutHeader
        className={styles.headerBlack}
        showBackButton
        isBlackButton={false}
        isCloseButton
        onBack={openRevert}
      />
      <Container className={styles.containerBlack}>
        <SafeAreaContainer className={styles.headerBlack}>
          <Header className={styles.title}>
            {selectedCoverage.icon || <div />}
            <span>{selectedCoverage.title2 || selectedCoverage.title}</span>
          </Header>
        </SafeAreaContainer>
        <SafeAreaContainer className={cx(styles.main, styles.twoLines)}>
          <div className={styles.pageDescription}>
            {selectedCoverage.description || ""}
          </div>
          {selectedCoverage.factors &&
            selectedCoverage.factors
              .filter((x) => factors && factors[x.value] !== undefined)
              .map((factor) => (
                <div className={styles.factor} key={factor.value}>
                  <CloseIcon
                    className={styles.factorClose}
                    onClick={() => onFactorRemove(factor)}
                  />
                  <CoverageInput
                    cents
                    data-id={factor.value}
                    editable
                    title={factor.title}
                    description={factor.description}
                    className={styles.coverageInput}
                    value={
                      factors ? factors[factor.value] || 100 * 100 : 100 * 100
                    }
                    options={factor.options.default}
                    onChange={(amount: number) =>
                      onFactorsChange(factor.value, amount)
                    }
                  />
                </div>
              ))}
          <div className={styles.innerContainer}>
            <button
              className={styles.addProperty}
              onClick={addProperty}
              data-id="addPropretyBtn"
            >
              + Add property
            </button>

            <div
              className={cx(
                styles.greyDescription,
                isLimitExceeded ? styles.error : null
              )}
            >
              Total coverage across items cannot exceed $10,000
            </div>
            <hr className={styles.hr} />
            <div className={styles.totalAmount}>
              <span>Total coverage</span>
              <span data-id="totalAmount">
                {formatMoneyInCents(totalAmount, false)}
              </span>
            </div>

            <div className={styles.errorBlock}>{serverError}</div>
          </div>
        </SafeAreaContainer>
        <Button
          disabled={isLimitExceeded}
          onClick={onSubmit}
          data-id="setCoverageBtn"
        >
          Set coverage
        </Button>
      </Container>
    </>
  );
}

ScheduledProperty.displayName = "ScheduledProperty";
export default withBoostQuote(
  connect(
    (state: IStore) => ({
      user: getUser(state),
      factors: getBoostFactors(state),
    }),
    {
      updateQuoteBoost,
      setBoostFactors,
    }
  )(ScheduledProperty)
);
