import * as React from "react";
import cx from "classnames";

import Upload from "../upload";
import Text from "../text";
import { ReactComponent as CloseIcon } from "../icons/close-gray.svg";

import styles from "./upload-files.module.css";

type FileType = "image" | "pdf" | "csv" | "excel" | "gif";

const isFileAllowed = (fileType: FileType, type: string): boolean => {
  switch (fileType) {
    case "image": {
      return type.includes("image") || type.includes("heic");
    }
    case "pdf": {
      return type.includes("application/pdf");
    }
    case "csv": {
      return type.includes("text/csv");
    }
    case "excel":
      return (
        type.includes("vnd.ms-excel") || type.includes("vnd.openxmlformats")
      );
    default: {
      return false;
    }
  }
};

const isFileRejected = (fileType: FileType, type: string): boolean => {
  switch (fileType) {
    case "gif": {
      return type.includes("gif");
    }
    default: {
      return false;
    }
  }
};

interface IProps extends React.HTMLProps<HTMLInputElement> {
  maxFiles?: number;
  text?: string;
  className?: string;
  setIsDamanged: (d: boolean) => void;
  setTouched: () => void;
  files: File[];
  allowedFileTypes: FileType[];
  rejectedFileTypes?: FileType[];
  setFiles: (f: File[]) => void;
  errorsFiles?: string;
  touchedFiles?: boolean;
}

export default function UploadFiles({
  text,
  className,
  setIsDamanged,
  setTouched,
  files,
  allowedFileTypes,
  rejectedFileTypes,
  setFiles,
  errorsFiles,
  touchedFiles,
  accept,
  maxFiles = 5,
}: IProps) {
  const [damagedFileIds, setDamagedFileIds] = React.useState<number[]>([]);

  const checkIsFileValid = React.useCallback(
    (file: File) => {
      const isAllowed = !!allowedFileTypes.find((fileType: FileType) =>
        isFileAllowed(fileType, file.type)
      );
      const isRejected = !!rejectedFileTypes?.find((fileType: FileType) =>
        isFileRejected(fileType, file.type)
      );
      return !isRejected && isAllowed;
    },
    [allowedFileTypes, rejectedFileTypes]
  );

  React.useEffect(() => {
    const ids = [];
    for (let i = 0; i < files.length; i += 1) {
      const isValid = checkIsFileValid(files[i]);
      if (!isValid) {
        ids.push(i);
      }
    }
    setDamagedFileIds(ids);
  }, [files, allowedFileTypes, checkIsFileValid]);

  function onSelectFiles(newFiles: FileList | null) {
    if (!newFiles || newFiles.length === 0) {
      return;
    }
    const value = Array.from(newFiles);
    const isFileDamanged = !!value.find((f: File) => !checkIsFileValid(f));
    setIsDamanged(isFileDamanged);
    setFiles([...files, ...value]);
    setTimeout(setTouched, 100);
  }

  function onRemoveFile(idx: number) {
    return () => {
      setFiles(
        files
          ? [...files.slice(0, idx), ...files.slice(idx + 1, files.length)]
          : []
      );
    };
  }

  return (
    <div className={className}>
      <div className={styles.files}>
        {files.map((file, idx) => (
          <div
            key={`${file.name}_${idx}`}
            className={cx(styles.upload, {
              [styles.error]:
                damagedFileIds.findIndex((x) => x === idx) >= 0 && touchedFiles,
              [styles.done]: Boolean(files && files.length),
            })}
          >
            <Text
              bold
              data-id={`fileName${idx}`}
              className={cx(styles.fileName, styles.uploaded, {
                [styles.done]: Boolean(files && files.length),
              })}
            >
              {file.name}
            </Text>
            <CloseIcon
              data-id={`removeFile${idx}`}
              className={styles.closeIcon}
              onClick={onRemoveFile(idx)}
            />
          </div>
        ))}
      </div>
      {files.length < maxFiles ? (
        <div className={styles.upload}>
          <Upload
            data-id="uploadInput"
            name="policy"
            onUpload={onSelectFiles}
            accept={accept}
            capture={false}
            multiple
          />
          <Text bold className={styles.fileName}>
            {text || "Click to upload a PDF or image"}
          </Text>
        </div>
      ) : null}
      <div
        className={cx(styles.errorBlock, {
          [styles.hidden]:
            !touchedFiles || (!errorsFiles && damagedFileIds.length === 0),
        })}
      >
        {damagedFileIds.length > 0
          ? "Sorry, this file type is not allowed or the file is corrupted."
          : null}
        {errorsFiles}
      </div>
    </div>
  );
}
UploadFiles.displayName = "UploadFiles";
