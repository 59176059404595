import APIError from "./api.error";
import { IQuery, IBody, IHeaders, IXHR } from "./types";
import { buildQueryString } from "../querystring";

type RequestOptions = {
  headers?: IHeaders;
  query?: IQuery;
  disableFilter?: boolean;
  credentials?: boolean;
};

type RequestParams = RequestOptions & {
  url: string;
  method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  body?: IBody | FormData;
  disableFilter?: boolean;
  credentials?: boolean;
};

type XHROptions = RequestOptions & {
  onProgress?: (e: ProgressEvent) => void;
  onSuccess?: (result: any) => void;
  onFail?: (e?: Error) => void;
  onCancel?: () => void;
};

async function transformResponse(response: Response) {
  try {
    return await response.json();
  } catch (err) {
    return null;
  }
}

export default class APIClient {
  protected _baseUrl: string;
  private _defaultHeaders: IHeaders;
  public onUnauthorized?: () => void;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
    this._defaultHeaders = {
      "Content-Type": "application/json",
    };
    this.responseHandler = this.responseHandler.bind(this);
  }

  protected responseHandler(response: Response) {
    if (response.status >= 200 && response.status < 300) {
      return transformResponse(response);
    }

    if (response.status === 401 && this.onUnauthorized) {
      this.onUnauthorized();
    }

    return transformResponse(response).then((data) => {
      throw new APIError(data, response.status);
    });
  }

  protected buildUrl(url: string, query?: IQuery): string {
    let queryString = "";
    if (query) {
      queryString = buildQueryString(query);
    }

    if (url[0] === "/") {
      return `${this._baseUrl}${url}${queryString}`;
    }

    return `${this._baseUrl}/${url}${queryString}`;
  }

  protected sendRequest(options: RequestParams) {
    let headers: IHeaders = {
      ...this._defaultHeaders,
    };

    if (options.headers) {
      headers = {
        ...headers,
        ...options.headers,
      };
    }

    let opts: RequestInit = {
      method: options.method,
      headers,
    };

    if (options.credentials) {
      opts = {
        ...opts,
        credentials: "include",
      };
    }

    if (options.body) {
      if (options.body instanceof FormData) {
        delete headers["Content-Type"];
        opts = {
          ...opts,
          body: options.body,
        };
      } else {
        opts = {
          ...opts,
          body: JSON.stringify(options.body),
        };
      }
    }

    return fetch(this.buildUrl(options.url, options.query), opts).then(
      this.responseHandler
    );
  }

  public setToken(accessToken: string) {
    this._defaultHeaders = {
      ...this._defaultHeaders,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  public post(
    url: string,
    body: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url,
      method: "POST",
      body,
      ...options,
    });
  }

  public get(url: string, options: RequestOptions = {}) {
    return this.sendRequest({
      url,
      method: "GET",
      ...options,
    });
  }

  public put(
    url: string,
    body: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url,
      method: "PUT",
      body,
      ...options,
    });
  }

  public patch(
    url: string,
    body: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url,
      method: "PATCH",
      body,
      ...options,
    });
  }

  public del(url: string, options: RequestOptions = {}) {
    return this.sendRequest({
      url,
      method: "DELETE",
      ...options,
    });
  }

  public upload(path: string, body: FormData, options: XHROptions = {}): IXHR {
    const xhr = new XMLHttpRequest();

    const headers: IHeaders = {
      ...this._defaultHeaders,
      ...options.headers,
    };

    const url = this.buildUrl(path, options.query);

    xhr.open("POST", url, true);

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });

    if (options.onProgress) {
      xhr.upload.onprogress = options.onProgress;
    }

    xhr.onerror = () => {
      if (options.onFail) {
        options.onFail(new APIError("Something went wrong", 500));
      }
    };

    xhr.onabort = () => {
      if (options.onCancel) {
        options.onCancel();
      }
    };

    xhr.onload = function onload() {
      if (this.status >= 200 && this.status < 300 && options.onSuccess) {
        options.onSuccess(JSON.parse(this.response));
      } else if (this.status >= 400 && this.status < 500 && options.onFail) {
        options.onFail(new APIError(JSON.parse(this.response), this.status));
      } else if (options.onFail) {
        options.onFail(new APIError("Something went wrong", 500));
      }
    };

    xhr.send(body);

    return {
      abort: () => xhr.abort(),
    };
  }
}
