import * as React from "react";

interface IProps {
  color?: "black" | "white";
  className?: string;
}

export default ({ color = "black", className }: IProps) => (
  <svg
    className={className}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.3" d="M1 3H17V7H1V3Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0H3V4H5V0ZM15 0H13V4H15V0Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM2 6H16V4H2V6Z"
      fill="black"
    />
  </svg>
);
