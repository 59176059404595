import * as React from "react";
import cx from "classnames";
import Button from "../button";

import styles from "./button.module.css";

interface IProps {
  containerClassName?: string;
  className?: string;
  onClick?: (e: React.FormEvent) => void;
  children: React.ReactChild | React.ReactChild[];
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  relative?: boolean;
  "data-id"?: string;
}

export default function ButtonComponent({
  onClick,
  children,
  className,
  containerClassName,
  type,
  disabled = false,
  relative = false,
  ...props
}: IProps) {
  return (
    <div
      className={cx(
        styles.actions,
        {
          [styles.disabled]: disabled,
          [styles.relative]: relative,
        },
        containerClassName
      )}
    >
      <Button
        type={type}
        className={cx(styles.btn, className)}
        onClick={onClick}
        data-id={props["data-id"]}
      >
        {children}
      </Button>
    </div>
  );
}
ButtonComponent.displayName = "Button";
