import React from "react";
import cx from "classnames";
import styles from "./spinner.module.css";

function times<T>(n: number, iterator: (i: number) => T): Array<T> {
  const result: Array<T> = [];
  for (let i = 0; i < n; i += 1) {
    result.push(iterator(i));
  }

  return result;
}

interface IProps {
  className?: string;
}

export default function GlobalSpinner({ className = "" }: IProps) {
  const leafs = times<React.ReactNode>(12, (n) => <div key={n} />);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0px",
        width: "100%",
        backgroundColor: "#ffffff",
        zIndex: 99999,
        opacity: 1,
      }}
    >
      <div className={cx(styles.spinner, className)}>{leafs}</div>
    </div>
  );
}

GlobalSpinner.displayName = "GlobalSpinner";
