import * as React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import Button from "../../components/action-button";
import { getUser } from "../../resources/user/user.selectors";
import { IUser } from "../../resources/user/types";
import {
  fetchInsurance,
  FetchInsurance,
} from "../../resources/insurance/insurance.actions";
import { loadingStart } from "../../resources/loading";
import { getInsurance } from "../../resources/insurance/insurance.selectors";
import { IInsurance } from "../../resources/insurance/types";

import { IStore } from "../../resources/types";
import { setItem } from "../../helpers/storage";

import { ReactComponent as CloseBtnIcon } from "../../components/icons/close_btn.svg";
import InsuranceHeader from "./insurance_header.png";

import { useAuth } from "../../auth";

import config from "../../config";

import GTag from "../../gtag";

import styles from "./insurance.module.css";

interface IProps {
  user: IUser;
  insurance?: IInsurance;
  fetchInsurance: FetchInsurance;
}

function Insurance({ user, insurance, fetchInsurance }: IProps) {
  const history = useHistory();
  const authData = useAuth();

  React.useEffect(() => {
    GTag.event("insurance_home", "Page");
  });

  React.useEffect(() => {
    if (!insurance) {
      fetchInsurance();
    }
  }, [insurance, fetchInsurance]);

  const openConfirm = (nextUrl: string) => {
    setItem("CONFIRM_NEXT_URL", nextUrl);
    history.push("/confirm");
  };

  const onHaveInsuranceClick = () => {
    const url = "/insurance/upload";
    if (!user.isConfirmed) {
      openConfirm(url);
      return;
    }
    history.push(url);
  };

  const isBoost = user && user.insuranceType === "BOOST";
  const canPurchase = user && !!user.insuranceType;

  function onClick() {
    if (isBoost) {
      onClickBoost();
    } else {
      onClickLemonade();
    }
  }

  function onClickLemonade() {
    const url = "/insurance/lemonade";
    openConfirm(url);
  }

  function onClickBoost() {
    GTag.event("boost_open", "Page");
    const url = "/insurance/boost";
    openConfirm(url);
  }

  function back() {
    if (!insurance || (insurance && insurance._id)) {
      history.push("/");
      return;
    }
    loadingStart();
    const rawToken = authData.getRawToken();
    setTimeout(() => {
      window.location.replace(
        `${config.returnLink}${rawToken ? `?token=${rawToken}` : ""}`
      );
    }, 0);
  }

  function onLearnMoreClick() {
    history.push("/insurance/info");
  }

  const showCloseBtn =
    (insurance && insurance._id) || (authData && authData.type === "in-app");

  return (
    <>
      <div>
        <div className={styles.image}>
          <img src={InsuranceHeader} alt="header" />
        </div>
        {showCloseBtn && (
          <CloseBtnIcon className={styles.closeBtn} onClick={back} />
        )}
        <div
          className={cx(
            styles.container,
            !canPurchase ? styles.uploadOnly : null
          )}
        >
          <h1>Renters insurance</h1>
          {canPurchase && (
            <div className={styles.description}>
              The unexpected happens. Your belongings - like electronics and
              furniture - are accidentally damaged. A guest gets hurt in your
              apartment and you’re liable.
            </div>
          )}
          {canPurchase && isBoost && (
            <div className={styles.description}>
              Latch Renters Insurance helps protect you from the unexpected. We
              make it quick & easy to get a quote and purchase a policy
              customized to your needs. Your policy is managed directly through
              the Latch app - including payments and claims. Policies renew
              monthly and can be canceled at any time.
            </div>
          )}
          {canPurchase && !isBoost && (
            <div className={styles.description}>
              Renters insurance helps protect you from the unexpected. We’ve
              partnered with Lemonade to make it quick & easy to get a quote and
              purchase a policy customized to your needs. Your policy is managed
              directly through Lemonade - including payments and claims.
              Policies renew monthly and can be canceled at any time.
            </div>
          )}
          {canPurchase && (
            <div className={styles.learnMore}>
              <button data-id="learnMoreBtn" onClick={onLearnMoreClick}>
                Learn more
              </button>
            </div>
          )}
          {canPurchase && (
            <div className={styles.description}>
              If you already have an actively policy from another provider, you
              can provide proof of insurance to your building here:
            </div>
          )}
          {canPurchase && (
            <div className={styles.uploadBtn}>
              <button data-id="uploadBtn" onClick={onHaveInsuranceClick}>
                Upload Existing Policy
              </button>
            </div>
          )}
          {!canPurchase && (
            <div className={styles.description}>
              If you have an active renters insurance policy, you can provide
              proof of insurance to your building by uploading policy
              documentations and providing some additional details.
            </div>
          )}
        </div>
      </div>
      {canPurchase && (
        <Button data-id="getQuoteBtn" onClick={onClick}>
          Get a Quote
        </Button>
      )}
      {!canPurchase && (
        <Button data-id="uploadBtnButton" onClick={onHaveInsuranceClick}>
          Upload existing policy
        </Button>
      )}
    </>
  );
}

Insurance.displayName = "Insurance";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
    insurance: getInsurance(state),
  }),
  {
    fetchInsurance,
  }
)(Insurance);
