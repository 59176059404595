import * as React from "react";
import cx from "classnames";

import { ReactComponent as GenericIcon } from "../icons/card.svg";
import { ReactComponent as VisaIcon } from "../icons/card/visa-icon.svg";
import { ReactComponent as MasterCardIcon } from "../icons/card/mastercard-icon.svg";
import { ReactComponent as AmexCardIcon } from "../icons/card/american-express-icon.svg";
import { ReactComponent as DiscoverCardIcon } from "../icons/card/discover-icon.svg";

import styles from "./styles.module.css";

interface IProps {
  className?: string;
  height?: number;
  width?: number;
  cardBrand?: string;
  error?: boolean;
  allBrands?: boolean;
}

function CardIcon({
  className,
  cardBrand,
  height,
  width,
  error,
  allBrands,
}: IProps) {
  const props = {
    height: height || 25,
    width: width || 25,
    className: cx(className, {
      [styles.iconFillError]: error,
    }),
  };
  const visa = <VisaIcon {...props} />;
  const mastercard = <MasterCardIcon {...props} />;
  const amex = <AmexCardIcon {...props} />;
  const discover = <DiscoverCardIcon {...props} />;

  if (allBrands) {
    return (
      <>
        {mastercard}
        {visa}
        {amex}
        {discover}
      </>
    );
  }

  switch (cardBrand) {
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "amex":
      return amex;
    case "discover":
      return discover;
    default:
      return <GenericIcon {...props} />;
  }
}

AmexCardIcon.displayName = "CardIcon";
export default CardIcon;
