import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ErrorPage from "../../components/error-page";

interface IProps extends RouteComponentProps {}

function NotFound({ history }: IProps) {
  const onClick = () => history.push("/");

  return (
    <ErrorPage
      status={404}
      description={
        <>
          Something went wrong…
          <br />
          Please try again.
        </>
      }
      action={onClick}
      actionText="Home"
    />
  );
}

NotFound.displayName = "NotFound";
export default withRouter(NotFound);
