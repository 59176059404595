export const InsuranceCompanies: string[] = [
  "Allstate",
  "American Family Insurance",
  "Amica Mutual Insurance",
  "Assurant",
  "Erie",
  "Farmers",
  "GEICO",
  "The Hartford",
  "Jetty",
  "Lemonade",
  "Liberty Mutual",
  "MetLife",
  "Nationwide",
  "Progressive",
  "State Farm",
  "Toggle",
  "Travelers",
  "USAA",
  "Other"
];

export const mapTypeToTitle: {
  [key: string]: string;
} = {
  mastercard: "Mastercard",
  visa: "Visa",
  "american-express": "American Express",
  amex: "American Express",
  discover: "Discover"
};
