import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/vars.css";
import "./index.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import App from "./App";

function renderApp() {
  ReactDOM.render(<App />, document.getElementById("root"));
}

renderApp();

if (module.hot) {
  module.hot.accept("./routes", () => {
    renderApp();
  });
}
