import * as React from "react";
import cx from "classnames";
import config from "../../config";

import { ReactComponent as MessageIcon } from "../icons/message.svg";

import styles from "./chat-button.module.css";

interface IProps {
  className?: string;
}

const ChatButton = ({ className }: IProps) => {
  return (
    <div className={cx(styles.contact, className)}>
      <MessageIcon />
      <div>
        Contact our support team at{" "}
        <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a> and
        we will get back to you as soon as possible.
      </div>
    </div>
  );
};

ChatButton.displayName = "ChatButton";
export default ChatButton;
