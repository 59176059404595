import { combineReducers, Action } from "redux";
import { connectRouter } from "connected-react-router";

import { Reducer } from "react";
import { History } from "history";
import { reducer as modalProvider } from "../modules/modalProvider";
import { IStore } from "./types";
import payment from "./payment/payment.reducer";
import user from "./user/user.reducer";
import insurance from "./insurance/insurance.reducer";
import loading from "./loading";
import form from "./form/form.reducer";

export default function createReducer(
  history: History
): Reducer<IStore | undefined, Action> {
  return combineReducers<any, any>({
    router: connectRouter(history),
    modalProvider,
    user,
    payment,
    insurance,
    form,
    loading,
  });
}
