import { FormikErrors } from "formik";

export interface IFormValues {
  firstName: string;
  lastName: string;
  zip: string;
  phone: string;
}

export default (values: IFormValues) => {
  const errors: FormikErrors<{
    zip: string;
    phone: string;
    firstName: string;
    lastName: string;
  }> = {};

  if (!values.firstName || values.firstName.length < 2) {
    errors.firstName = "Please enter valid First name";
  }
  if (!values.lastName || values.lastName.length < 2) {
    errors.lastName = "Please enter valid Last name";
  }
  if (
    !values.zip ||
    (values.zip && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zip))
  ) {
    errors.zip = "Please enter valid Zip Code";
  }
  if (
    !values.phone ||
    (values.phone &&
      !/^[2-9]\d{9}$/.test(values.phone.replace(/\(|\)|-|\s+/g, "")))
  ) {
    errors.phone = "Please enter valid Mobile Number";
  }

  return errors;
};
