import * as React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../components/action-button";
import Header from "../../components/header";
import { getUser } from "../../resources/user/user.selectors";
import { IUser } from "../../resources/user/types";
import { formatZip } from "../../helpers/common";

import GTag from "../../gtag";

import { IStore } from "../../resources/types";
import {
  confirmAddress,
  ConfirmAddress,
  rejectAddress,
  RejectAddress,
  fetchUser,
  FetchUser,
} from "../../resources/user/user.actions";

import { ReactComponent as BuildingIcon } from "../../components/icons/building.svg";

import styles from "./confirm-address.module.css";

interface IProps {
  user: IUser;
  confirmAddress: ConfirmAddress;
  rejectAddress: RejectAddress;
  fetchUser: FetchUser;
}

function ConfirmAddressModal({
  user,
  confirmAddress,
  rejectAddress,
  fetchUser,
}: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    fetchUser({ skipLoading: !!user });

    GTag.event("confirm_address", "Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUser]);

  React.useEffect(() => {
    if (user && !user.newAddress) {
      history.push("/");
    }
  }, [user, history]);

  async function onConfirmClick() {
    GTag.event("confirmed_address", "Click");

    await confirmAddress();
    fetchUser();

    history.push("/confirm-address/success");
  }

  async function onRejectClick() {
    GTag.event("rejected_address", "Click");

    await rejectAddress();
    fetchUser();

    history.push("/");
  }

  return (
    <div className={styles.container}>
      <Header singleLine className={styles.header}>
        Hi, {user.firstName}
      </Header>

      <div className={styles.content}>
        <BuildingIcon />

        <div className={styles.info}>
          <div>We'd like to confirm you live at</div>

          <div className={styles.name}>
            {user.newAddress?.unit} | {user.newAddress?.building}
          </div>
          <div>{user.newAddress?.address}</div>
          <div>
            {user.newAddress?.city}, {user.newAddress?.state}{" "}
            {formatZip(user.newAddress?.zip || "")}
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          data-id="submit-info"
          relative
          type="button"
          className={styles.btn}
          onClick={onConfirmClick}
        >
          Confirm information
        </Button>
        <div className={styles.contact}>
          Not correct?
          <br />
          Contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>
        </div>
        <div className={styles.skip}>
          <button onClick={onRejectClick}>Skip and open the app</button>
        </div>
      </div>
    </div>
  );
}

ConfirmAddressModal.displayName = "ConfirmAddress";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {
    confirmAddress,
    rejectAddress,
    fetchUser,
  }
)(ConfirmAddressModal);
