import * as React from "react";
import { ReactComponent as LatchIcon } from "../../components/icons/latch.svg";
import clock from "./clock.png";

import styles from "./maintenance.module.css";

export default function Maintenance() {
  return (
    <div className={styles.maintenance}>
      <div className={styles.header}>
        <LatchIcon />
      </div>
      <div className={styles.content}>
        <img src={clock} alt="clock" />
        <h1>Just a moment</h1>
        <p>
          We're closed for scheduled maintenance while we work on something
          special.
        </p>
      </div>
    </div>
  );
}
Maintenance.displayName = "Maintenance";
