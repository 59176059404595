import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import Header from "../../components/header";
import GlobalSpinner from "../../components/spinner/global";
import { SafeAreaContainer } from "../../components/container";
import { getUser } from "../../resources/user/user.selectors";
import { IUser } from "../../resources/user/types";
import { IStore } from "../../resources/types";
import { fetchUser, FetchUser } from "../../resources/user/user.actions";

import GTag from "../../gtag";

import styles from "./confirmation.module.css";

interface IProps {
  user?: IUser;
  fetchUser: FetchUser;
}

function Confirmation({ user, fetchUser }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("confirm_address_confirmation", "Page");
  }, []);

  React.useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user, fetchUser]);

  if (!user) {
    return <GlobalSpinner />;
  }

  async function onSubmit() {
    if (!user) {
      return;
    }
    history.push("/");
  }

  let description = <></>;
  let getStarted = <></>;
  switch (true) {
    case user.meta?.insuranceType === "LEMONADE":
      description = (
        <>
          Thank you for your address confirmation. We make it easy for you to
          provide proof of an existing policy or purchase a new policy if you
          don’t have one already. Latch offers renters insurance at a
          competitive rate. You can customize a quote and purchase a policy
          tailored to your needs in under 2 minutes - just click "Get Started"
          to begin. If you have an existing policy, you can provide proof of
          coverage to your building through Latch.
        </>
      );
      getStarted = (
        <>
          Click “Get Started” to begin - it only takes a few minutes. Or any
          time later you can get a quote and purchase one by going to the
          ‘Services’ menu in the Latch app and tapping Renters Insurance.
          <br />
          <br />
          At the same time, you can login to your{" "}
          <a href="https://www.lemonade.com/">Lemonade app</a> and manage your
          existing policy.
          <br />
          <br />
          If you have questions or need assistance, please contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
        </>
      );
      break;
    case user.meta?.insuranceType === "BOOST":
      description = (
        <>
          Thank you for your address confirmation. Your new building supports
          Latch Insurance service. We make it easy for you to provide proof of
          an existing policy or purchase a new policy if you don’t have one
          already. Latch offers renters insurance at a competitive rate. You can
          customize a quote and purchase a policy tailored to your needs in
          under 2 minutes - just click “Get Started” to begin. If you have an
          existing policy, you can provide proof of coverage to your building
          through Latch.
          <br />
          <br />
          {user.meta?.archiveToken && (
            <>
              You can cancel your policy by clicking on this{" "}
              <a href={`/policy/cancel/${user.meta?.archiveToken}`}>link</a>. If
              you have any open claims, we will notify you by email about any
              status updates.
            </>
          )}
          <br />
          <br />
          If you have questions or need assistance, please contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
        </>
      );
      getStarted = (
        <>
          Click “Get Started” to begin - it only takes a few minutes. Or any
          time later you can get a quote and purchase one by going to the
          ‘Services’ menu in the Latch app and tapping Renters Insurance.
          <br />
          <br />
          {user.meta?.archiveToken && (
            <>
              At the same time, you can cancel your existing Boost policy by
              click on{" "}
              <a href={`/policy/cancel/${user.meta?.archiveToken}`}>
                Cancel policy
              </a>{" "}
              link or anytime later you can use a policy cancellation link from
              email notiication. If you have any open claims, we will notify you
              by email about any status updates.
            </>
          )}
          <br />
          <br />
          If you have questions or need assistance, please contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
        </>
      );
      break;
    default:
      description = (
        <>
          Thank you for your address confirmation. We make it easy for you to
          provide proof of an existing policy or purchase a new policy if you
          don’t have one already. Latch offers renters insurance at a
          competitive rate. You can customize a quote and purchase a policy
          tailored to your needs in under 2 minutes - just click "Get Started"
          to begin. If you have an existing policy, you can provide proof of
          coverage to your building through Latch.
        </>
      );
      getStarted = (
        <>
          Click "Get Started" to begin - it only takes a few minutes. Or any
          time later you can get a quote and purchase one by going to the
          ‘Services’ menu in the Latch app and tapping Renters Insurance.
          <br />
          <br />
          If you have questions or need assistance, please contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
        </>
      );
  }

  return (
    <SafeAreaContainer className={styles.container}>
      <Header className={styles.title}>Address confirmed</Header>
      <div className={styles.description}>Your address has been confirmed.</div>
      <div className={styles.description}>{description}</div>

      <div className={styles.messageContainer}>
        <div className={styles.subTitle}>What's next?</div>
        <div className={styles.text}>
          <div className={styles.line3}>{getStarted}</div>
        </div>
        <Button onClick={onSubmit} className={styles.btn} data-id="doneBtn">
          {user.meta?.isInsuranceEnabled ? "Get Started" : "Done"}
        </Button>
      </div>
    </SafeAreaContainer>
  );
}

Confirmation.displayName = "Confirmation";
export default connect(
  (state: IStore) => ({
    user: getUser(state),
  }),
  {
    fetchUser,
  }
)(Confirmation);
