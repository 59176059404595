import * as React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";

import withBoostQuote from "../../../../hoc/boostQuote.hoc";

import {
  IBoostQuote,
  IAdditionalInsured,
} from "../../../../resources/insurance/types";
import {
  fetchQuoteBoost,
  FetchQuoteBoost,
} from "../../../../resources/insurance/insurance.actions";

import GTag from "../../../../gtag";

import { ReactComponent as ArrowIcon } from "../../../../components/icons/arrow.svg";

import styles from "./additional-insured.module.css";

const TYPES: { [key: string]: string } = {
  individual: "Individual",
  business: "Business",
};

interface IProps {
  quote: IBoostQuote;
  fetchQuoteBoost: FetchQuoteBoost;
}

function AdditionalInsured({ quote, fetchQuoteBoost }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("additinal_insured", "Page");

    fetchQuoteBoost();
  }, [fetchQuoteBoost]);

  const openAddParty = () => {
    GTag.event("add_additional_insured", "Page");
    history.push(`/insurance/boost/edit/${id}/additionalInsured/add`);
  };

  const openEditParty = (party: IAdditionalInsured) => {
    GTag.event("edit_additional_insured", "Page");
    history.push(`/insurance/boost/edit/${id}/additionalInsured/${party._id}`);
  };

  return (
    <div className={styles.page}>
      <SafeAreaContainer className={styles.head}>
        <Header>Additional insured</Header>
        <div className={styles.subHeader}>
          An additional insured is a person you add to your insurance policy
          (not related to you by blood, marriage, or adoption) who lives with
          you and/or has a financial interest in your place. Here’s where you
          might want to add an additional insured:
          <ul>
            <li>
              You’re living with a partner or significant other but not married
              them
            </li>
            <li>You’re living with a relative who isn’t a blood relation</li>
            <li>
              If someone else owns a part of your place but isn’t living with
              you
            </li>
          </ul>
          Anyone who lives with you who is related by blood, marriage, or
          adoption is already covered under your policy and does not need to be
          added as an additional insured.
        </div>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.container}>
        <div>
          {quote.summary.additionalInsured
            .filter((x) => !x.isPrimary)
            .map((item: IAdditionalInsured, index: number) => (
              <div
                key={index}
                data-id={`additionalInsured${index}`}
                className={styles.setting}
                role="button"
                onClick={() => openEditParty(item)}
              >
                <div className={styles.title}>
                  {item.businessName || `${item.firstName} ${item.lastName}`}
                </div>
                <div className={styles.description}>{TYPES[item.type]}</div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
            ))}
        </div>
        {quote.summary.additionalInsured.length < 5 && (
          <div>
            <button
              className={styles.link}
              onClick={openAddParty}
              data-id="addInsuredBtn"
            >
              + Add additional insured
            </button>
          </div>
        )}
      </SafeAreaContainer>
    </div>
  );
}

AdditionalInsured.displayName = "AdditionalInsured";
export default withBoostQuote(
  connect(() => ({}), {
    fetchQuoteBoost,
  })(AdditionalInsured)
);
