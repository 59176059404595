import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../auth";
import { useAuth0 } from "../react-auth0-wrapper";

import Spinner from "./spinner/global";

interface IProps {
  path: string;
  exact: boolean;
  Component: Function;
}

const PrivateRoute = ({ Component, path, ...rest }: IProps) => {
  const authData = useAuth();
  const auth0Data = useAuth0();
  const loading = authData ? authData.loading : auth0Data.loading;

  const render = (props: object) => {
    if (loading) {
      return <Spinner />;
    }

    return <Component {...props} />;
  };

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.displayName = "PrivateRoute";
export default PrivateRoute;
