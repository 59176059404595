import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Spinner from "../../../components/spinner";
import EditInsurance from "../my-policy";
import BoostInsurance from "../boost";

import { IStore } from "../../../resources/types";

import {
  fetchPolicy,
  FetchPolicy,
} from "../../../resources/insurance/insurance.actions";
import { getPolicy } from "../../../resources/insurance/insurance.selectors";
import { getUser } from "../../../resources/user/user.selectors";
import { IPolicy } from "../../../resources/insurance/types";
import { IUser } from "../../../resources/user/types";

interface IProps extends RouteComponentProps {
  insurance?: IPolicy;
  user?: IUser;
  fetchPolicy: FetchPolicy;
}

function InsuranceDetails({ fetchPolicy, user, insurance }: IProps) {
  React.useEffect(() => {
    if (!insurance) {
      fetchPolicy();
    }
  }, [fetchPolicy, insurance]);

  if (insurance === undefined) {
    return <Spinner />;
  }
  if (user && user.insuranceType === "BOOST") {
    return <BoostInsurance />;
  }
  return <EditInsurance />;
}

InsuranceDetails.displayName = "InsuranceDetails";
export default connect(
  (state: IStore) => {
    return {
      insurance: getPolicy(state),
      user: getUser(state),
    };
  },
  {
    fetchPolicy,
  }
)(InsuranceDetails);
