import * as React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatMoneyInCents } from "../../../../helpers/common";
import Button from "../../../../components/button";
import Header from "../../../../components/header";
import GlobalSpinner from "../../../../components/spinner/global";
import { SafeAreaContainer } from "../../../../components/container";
import { getBoostQuote } from "../../../../resources/insurance/insurance.selectors";
import { IBoostQuote } from "../../../../resources/insurance/types";
import { IStore } from "../../../../resources/types";

import GTag from "../../../../gtag";

import styles from "./confirmation.module.css";

interface IProps {
  quote?: IBoostQuote;
}

function Confirmation({ quote }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("boost_confirmation", "Page");
  }, []);

  if (!quote) {
    return <GlobalSpinner />;
  }

  async function onSubmit() {
    if (!quote) {
      return;
    }
    history.push("/");
  }

  return (
    <SafeAreaContainer className={styles.container}>
      <Header className={styles.title}>Payment confirmation</Header>

      <div className={styles.messageContainer}>
        <div className={styles.subTitle}>What's next?</div>
        <div className={styles.text}>
          <div className={styles.line1}>
            You will receive an email shortly from Latch confirming your renters
            insurance purchase. Your policy document will be emailed to you in
            24 - 48 hours.
          </div>

          {quote.type === "UPDATE" && (
            <div className={styles.line2}>
              Your policy changes will be effective starting{" "}
              <b>
                {moment.utc(quote.summary.startDate).format("MMMM D, YYYY")}.
              </b>{" "}
              Monthly premium of your new policy is{" "}
              <b>{formatMoneyInCents(quote.summary.paymentAmount)}</b>.
            </div>
          )}
          {quote.type !== "UPDATE" && (
            <div className={styles.line2}>
              Your policy will be active on{" "}
              <b>
                {moment.utc(quote.summary.startDate).format("MMMM D, YYYY")}.
              </b>
            </div>
          )}

          <div className={styles.line3}>
            If you need additional information about your policy, please refer
            to your policy document. If you have questions or need assistance,
            please contact us at{" "}
            <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
          </div>
        </div>
        <Button onClick={onSubmit} className={styles.btn} data-id="doneBtn">
          Done
        </Button>
      </div>
    </SafeAreaContainer>
  );
}

Confirmation.displayName = "Confirmation";
export default connect((state: IStore) => ({
  quote: getBoostQuote(state),
}))(Confirmation);
