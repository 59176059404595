import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/button";
import Header from "../../../../components/header";
import { SafeAreaContainer } from "../../../../components/container";

import GTag from "../../../../gtag";

import styles from "./confirmation.module.css";

function Confirmation() {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("claim_confirmation", "Page");
  }, []);

  function onSubmit() {
    history.push("/");
  }

  return (
    <SafeAreaContainer className={styles.container}>
      <Header className={styles.title}>Claim request confirmation</Header>
      <div className={styles.description}>
        This is confirmation that your Boost insurance claim has been
        successfully submitted.
      </div>

      <div className={styles.messageContainer}>
        <div className={styles.subTitle}>What's next?</div>
        <div className={styles.text}>
          <div className={styles.line1}>
            You will receive an acknowledgment email from Markel within 24
            hours. Markel will assign a claims adjuster to your case, who will
            reach out to you separately with a claim number and contact
            information.
          </div>

          <div className={styles.line3}>
            If you have any additional questions unrelated to claims, please
            contact us at{" "}
            <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
          </div>
        </div>
        <Button onClick={onSubmit} className={styles.btn}>
          Done
        </Button>
      </div>
    </SafeAreaContainer>
  );
}

Confirmation.displayName = "ClaimConfirmation";
export default Confirmation;
