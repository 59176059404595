import * as React from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import config from "../../config";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

let stripePromise: Promise<any>;

function Stripe({ children }: IProps) {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripeLemonade.key);
  }
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

function withStripe(
  Component: React.ComponentType<any>
): React.ComponentType<any> {
  return function fn(props: any) {
    return (
      <Stripe>
        <Component {...props} />
      </Stripe>
    );
  };
}

export { withStripe };
Stripe.displayName = "Stripe";
export default Stripe;
