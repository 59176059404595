import * as React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import withBoostQuote from "../../../../hoc/boostQuote.hoc";
import { showModal } from "../../../../modules/modalProvider";
import {
  updateQuoteBoost,
  UpdateQuoteBoost,
} from "../../../../resources/insurance/insurance.actions";
import { getPersonalLiabilityOptions } from "../../../../resources/insurance/insurance.selectors";
import { IBoostQuote } from "../../../../resources/insurance/types";
import { IStore } from "../../../../resources/types";

import Container, { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";
import Confirm from "../../../../components/confirm";
import LayoutHeader from "../../../../layouts/main/header";
import Price from "../../components/pricing";
import Button from "../../../../components/action-button";
import CoverageInput from "../../components/coverage";

import { CoverageType, COVERAGES } from "../boost.config";

import styles from "./coverage.module.css";

interface IProps {
  quote: IBoostQuote;
  updateQuoteBoost: UpdateQuoteBoost;
  personalLiabilityOptions?: number[];
}

interface IField {
  personalLiability: number;
  personalProperty: number;
}

function Coverage({
  updateQuoteBoost,
  quote,
  personalLiabilityOptions,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [value, setValue] = React.useState<IField>({
    personalLiability: quote ? quote.summary.personalLiability : 0,
    personalProperty: quote ? quote.summary.personalProperty : 0,
  });
  const [originalValue, setOriginalValue] = React.useState<IField>({
    personalLiability: quote ? quote.summary.personalLiability : 0,
    personalProperty: quote ? quote.summary.personalProperty : 0,
  });
  const [serverError, setServerError] = React.useState("");

  const isChanged = !isEqual(value, originalValue);

  async function onSubmit() {
    setServerError("");
    if (!quote || !value) {
      return;
    }
    setOriginalValue(value);
    history.push(`/insurance/boost/edit/${id}`);
  }

  function setCoverage(coverage: CoverageType) {
    return async (newValue: number) => {
      setServerError("");
      const prevValue = value;
      try {
        setValue((v) => v && { ...v, [coverage]: newValue });
        await updateQuoteBoost({ [coverage]: newValue });
      } catch (err) {
        setValue(prevValue);
        if (err.data && err.data.errors) {
          setServerError(values(err.data.errors[0])[0]);
        }
      }
    };
  }

  const openRevert = () => {
    if (originalValue && value && isChanged) {
      showModal(
        <Confirm
          action={() => onRevert()}
          title="Your changes have not been saved. Do you want to continue?"
          yes="Yes"
          no="No"
        />,
        undefined
      );
    } else {
      history.goBack();
    }
  };

  async function onRevert() {
    setServerError("");
    try {
      if (originalValue && value && isChanged) {
        await updateQuoteBoost({ ...originalValue });
      }
      history.goBack();
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
    }
  }

  const getAmount = (value: IField, key: CoverageType): number => {
    if (!value) {
      return 0;
    }
    if (key === "lossOfUse") {
      return value.personalProperty * 0.2;
    }
    return value[key];
  };

  const items = COVERAGES.map((item) => {
    return (
      <CoverageInput
        cents
        key={item.key}
        data-id={item.key}
        title={item.title}
        description={item.description}
        value={getAmount(value, item.key)}
        options={
          item.key === "personalLiability"
            ? personalLiabilityOptions || item.options
            : item.options
        }
        onChange={setCoverage(item.key)}
        readonly={item.options.length === 0}
      />
    );
  });

  const disableButton = !isChanged;

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton
        onBack={openRevert}
      />
      <Container className={styles.container}>
        <SafeAreaContainer className={styles.head}>
          <Header>
            Coverage
            <br />
            details
          </Header>
        </SafeAreaContainer>
        <hr className={styles.hr} />
        <SafeAreaContainer>
          <Price amount={quote.summary.paymentAmount} period="month" />
          <div>{items}</div>
          <div className={styles.errorBlock}>{serverError}</div>
        </SafeAreaContainer>
        <Button
          disabled={disableButton}
          onClick={onSubmit}
          data-id="setCoverageBtn"
        >
          Set coverage
        </Button>
      </Container>
    </>
  );
}

Coverage.displayName = "Coverage";
export default withBoostQuote(
  connect(
    (state: IStore) => ({
      personalLiabilityOptions: getPersonalLiabilityOptions(state),
    }),
    {
      updateQuoteBoost,
    }
  )(Coverage)
);
