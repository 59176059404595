import * as React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { setItem } from "../../../../helpers/storage";
import { showModal } from "../../../../modules/modalProvider";
import Button from "../../../../components/button";
import Header from "../../../../components/header";
import LayoutHeader from "../../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../../components/container";
import Confirm from "../../../../components/confirm";
import { formatMoneyInCents } from "../../../../helpers/common";
import {
  cancelPolicyBoost,
  CancelPolicyBoost,
  fetchPolicyBoost,
  FetchPolicyBoost,
} from "../../../../resources/insurance/insurance.actions";
import { IBoostPolicy } from "../../../../resources/insurance/types";
import { IUser } from "../../../../resources/user/types";
import { getUser } from "../../../../resources/user/user.selectors";
import { IStore } from "../../../../resources/types";
import { mapTypeToTitle } from "../../../../helpers/constants";

import { useAuth } from "../../../../auth";
import { loadingStart } from "../../../../resources/loading";
import config from "../../../../config";
import withBoostPolicy from "../../../../hoc/boostPolicy.hoc";
import { refundStates, getQuoteSummary } from "../boost.config";

import { ReactComponent as ArrowDownIcon } from "../../../../components/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../components/icons/arrow_up.svg";
import { ReactComponent as ArrowIcon } from "../../../../components/icons/arrow.svg";

import GTag from "../../../../gtag";

import styles from "./policy-details.module.css";

interface IProps {
  loadingStart: () => void;
  user?: IUser;
  policy: IBoostPolicy;
  cancelPolicyBoost: CancelPolicyBoost;
  fetchPolicyBoost: FetchPolicyBoost;
}

function BoostDetails({
  policy,
  user,
  loadingStart,
  cancelPolicyBoost,
  fetchPolicyBoost,
}: IProps) {
  const history = useHistory();
  const authData = useAuth();

  const [isSummaryOpened, setIsSummaryOpened] = React.useState(false);

  React.useEffect(() => {
    GTag.event("boost_policy_details", "Page");
    fetchPolicyBoost({ skipLoading: true });
  }, [fetchPolicyBoost]);

  const cancelPolicy = async () => {
    GTag.event("boost_cancel", "Click");
    await cancelPolicyBoost();
    history.push("/");
  };

  const openCancel = () => {
    const description = refundStates.includes(
      policy.policy.data.attributes.policy_state
    )
      ? "By state law, coverage will end immediately and you will receive a pro-rated refund for the unused days remaining in the current policy period. Refunds take up to 5 business days to process."
      : "By state law, coverage will continue until the end of the current policy period.";
    showModal(
      <Confirm
        action={() => cancelPolicy()}
        title="Are you sure to cancel your policy?"
        description={description}
        yes="Yes"
        no="No"
      />,
      undefined
    );
  };

  const editPaymentMethod = () => {
    GTag.event("boost_edit_payment_method", "Click");
    const rawToken = authData.getRawToken();
    loadingStart();
    window.location.replace(
      `${config.returnLink}/profile?token=${rawToken}&backUrl=${config.url}`
    );
  };

  const goToPaymentHistory = () => {
    GTag.event("boost_payment_history", "Click");
    history.push("/payment-history");
  };

  let policyStatus = "";
  const startDate = moment.utc(policy.summary.startDate).startOf("day");
  const endDate = moment.utc(policy.summary.endDate).startOf("day");
  const now = moment.utc().startOf("day");
  if (startDate > now) {
    policyStatus = `starts on ${startDate.format("MMMM D, YYYY")}`;
  } else {
    policyStatus = `until ${endDate.format("MMMM D, YYYY")}`;
  }
  if (policy.previousActivePolicyId && startDate > now) {
    policyStatus = `Coverage changes in your insurance policy will be effective starting ${startDate.format(
      "MMMM D, YYYY"
    )}.`;
  }

  const toggleSummaryDetails = () => {
    setIsSummaryOpened(!isSummaryOpened);
  };

  const editCoverage = () => {
    const nextUrl = "/insurance/boost";
    setItem("CONFIRM_NEXT_URL", nextUrl);
    history.push("/confirm");
  };

  const editAdditionalInterested = () => {
    history.push(`/insurance/boost/policy/${policy._id}/additionalInterested`);
  };

  const createClaim = () => {
    history.push(`/insurance/boost/policy/${policy._id}/claims/new`);
  };

  const goToClaims = () => {
    history.push(`/insurance/boost/policy/${policy._id}/claims`);
  };

  const getClaimsText = (count: number) => {
    if (count === 0) {
      return "No active claims";
    }
    return `${policy.numberOfClaims} active claim${count > 1 ? "s" : ""}`;
  };

  const { primary: summaryItems, details: summaryDetails } = getQuoteSummary(
    policy.summary
  );
  const isLastMonth = !policy.installmentPlan.find(
    (x) => x.paymentStatus !== "Complete"
  );

  const isInApp = !!authData && authData.type === "in-app";
  const status = policy.status === "FUTURE" ? "Future" : "Active";

  const onBack = () => {
    loadingStart();
    const rawToken = authData.getRawToken();
    setTimeout(() => {
      window.location.replace(
        `${config.returnLink}${rawToken ? `?token=${rawToken}` : ""}`
      );
    }, 0);
  };

  return (
    <>
      <LayoutHeader
        className={styles.header}
        showBackButton={isInApp}
        onBack={onBack}
      />
      <Container>
        <SafeAreaContainer className={styles.head}>
          <Header className={styles.header}>
            Renters
            <br />
            insurance
          </Header>
        </SafeAreaContainer>
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.title}>Insurance policy</div>
            <div className={styles.content}>
              <span className={styles.status}>{status}</span> {policyStatus}
            </div>
            {policy.url && (
              <a
                href={`${policy.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Review policy
              </a>
            )}
            {!policy.url && (
              <div className={styles.linkText}>
                Your policy document will be available in 1-2 business days
              </div>
            )}
          </div>
          <hr className={styles.hr} />
          <div className={styles.group}>
            <div className={styles.title}>Monthly premium</div>
            <div className={styles.content}>
              <span className={styles.status}>
                {formatMoneyInCents(policy.summary.paymentAmount)}/mo
              </span>
            </div>
            {!isLastMonth && (
              <button
                className={styles.link}
                onClick={editCoverage}
                data-id="editCoverageBtn"
              >
                Edit coverage
              </button>
            )}
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Coverage details</div>
            {user &&
              user.activeInsurance &&
              user.activeInsurance.insuranceStatus ===
                "INSUFFICIENT_COVERAGE" && (
                <div className={styles.message}>
                  The insurance policy you submitted doesn’t meet the
                  requirements of {user.building}. Please contact your property
                  manager for further details.
                </div>
              )}
            <div className={styles.content}>
              {summaryItems.map((s, index) => (
                <div key={index}>
                  {index === 0 && (
                    <div className={styles.summaryDetailsRowFirst}>
                      <div>{s.name}</div>
                      <div>{s.value}</div>
                    </div>
                  )}
                  {index > 0 && (
                    <div className={styles.summaryDetailsRow}>
                      <div>{s.name}</div>
                      <div>{s.value}</div>
                    </div>
                  )}
                </div>
              ))}
              {isSummaryOpened && (
                <div>
                  {summaryDetails.map((s) => (
                    <div className={styles.summaryDetailsRow} key={s.name}>
                      <div>{s.name}</div>
                      <div>{s.value}</div>
                    </div>
                  ))}
                  <div className={styles.summaryDetailsDescription}>
                    * Coverage is automatic and not adjustable.
                  </div>
                </div>
              )}
              <div
                className={styles.summaryToggle}
                onClick={toggleSummaryDetails}
                data-id="detailsBtn"
              >
                {isSummaryOpened ? "Hide details" : "View details"}
                {isSummaryOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </div>
            </div>
          </div>
          {policy.summary.additionalInsured.length > 1 && (
            <>
              <hr className={styles.hr} />
              <div className={styles.group}>
                <div className={styles.title}>Additional insured</div>
                <div className={styles.content}>
                  {policy.summary.additionalInsured
                    .filter((x) => !x.isPrimary)
                    .map((party) => (
                      <div className={styles.status}>
                        {party.businessName ||
                          `${party.firstName} ${party.lastName}`}
                      </div>
                    ))}
                </div>
                {!isLastMonth && (
                  <button className={styles.link} onClick={editCoverage}>
                    Edit additional insured
                  </button>
                )}
              </div>
            </>
          )}
          {policy.summary.additionalInterested.length > 0 && (
            <>
              <hr className={styles.hr} />
              <div className={styles.group}>
                <div className={styles.title}>Additional interested</div>
                <div className={styles.content}>
                  {policy.summary.additionalInterested.map((party) => (
                    <div className={styles.status}>
                      {party.businessName ||
                        `${party.firstName} ${party.lastName}`}
                    </div>
                  ))}
                </div>
                {!isLastMonth && (
                  <button
                    className={styles.link}
                    onClick={editAdditionalInterested}
                  >
                    Edit additional interested
                  </button>
                )}
              </div>
            </>
          )}
          {policy.paymentMethod && policy.paymentMethod.card && (
            <>
              <hr className={styles.hr} />
              <div className={styles.group}>
                <div className={styles.title}>Payment method</div>
                <div className={styles.content}>
                  <span className={styles.status}>
                    {mapTypeToTitle[policy.paymentMethod.card.brand]} *
                    {policy.paymentMethod.card.last4}
                  </span>
                </div>
                {!isInApp ? (
                  <div className={styles.linkText}>
                    Please login to Latch app if you want to edit your payment
                    method.
                  </div>
                ) : (
                  <button
                    className={styles.link}
                    onClick={editPaymentMethod}
                    data-id="editPaymentMethodBtn"
                  >
                    Edit payment method
                  </button>
                )}
                <button
                  className={styles.link}
                  onClick={goToPaymentHistory}
                  data-id="paymentHistoryBtn"
                >
                  Payment history
                </button>
              </div>
            </>
          )}
          {(policy.status !== "FUTURE" || policy.numberOfClaims > 0) && (
            <>
              <hr className={styles.hr} />
              <div className={styles.group}>
                <div className={styles.title}>Claims</div>
                <div className={styles.content}>
                  <div
                    className={cx(styles.claims, styles.clickable)}
                    onClick={goToClaims}
                    data-id="claimsBtn"
                  >
                    {getClaimsText(policy.numberOfClaims)}
                    <ArrowIcon className={styles.arrowIcon} />
                  </div>
                  <Button
                    className={styles.btnBlack}
                    onClick={createClaim}
                    data-id="createClaimBtn"
                  >
                    File a claim
                  </Button>
                </div>
              </div>
            </>
          )}
          <hr className={styles.hr} />
          <button
            className={styles.cancelBtn}
            onClick={openCancel}
            data-id="cancelPolicyBtn"
          >
            Cancel Policy
          </button>
        </div>
      </Container>
    </>
  );
}

BoostDetails.displayName = "BoostDetails";
export default withBoostPolicy(
  connect(
    (state: IStore) => ({
      user: getUser(state),
    }),
    {
      loadingStart,
      cancelPolicyBoost,
      fetchPolicyBoost,
    }
  )(BoostDetails)
);
