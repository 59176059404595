import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useAuth0 } from "./react-auth0-wrapper";
import client from "./helpers/api";
import Spinner from "./components/spinner";
import ErrorPage from "./components/error-page";
import { fetchUser, FetchUser } from "./resources/user/user.actions";
import { getUser } from "./resources/user/user.selectors";
import { IUser } from "./resources/user/types";
import { IStore } from "./resources/types";

import GTag from "./gtag";

interface IProps extends RouteComponentProps {
  user: IUser;
  children: React.ReactChild | React.ReactChild[];
  fetchUser: FetchUser;
}

function Initialize({ user, children, fetchUser }: IProps) {
  const [isTokenSetted, setTokenSetted] = React.useState(false);
  const [status, setStatus] = React.useState<number>();
  const { getTokenSilently, loginWithRedirect, logout } = useAuth0();

  async function loadUser() {
    try {
      const u = await fetchUser();
      GTag.init({ userId: u._id });
    } catch (err) {
      if (err.status === 401) {
        loginWithRedirect();
      }
      setStatus(err.status);
    }
  }

  React.useEffect(() => {
    getTokenSilently()
      .then((token: string) => {
        client.setToken(token);
        client.onUnauthorized = () =>
          logout({ returnTo: `${window.location.origin}` });
        setTokenSetted(true);
        if (!user || !user._id) {
          loadUser();
          GTag.event("auth0_auth", "Auth");
          GTag.event("init", "Auth");
        }
      })
      .catch((err: Error) => {
        /* eslint-disable-next-line no-console */
        console.log(err);
        loginWithRedirect();
      });
    /* eslint-disable-next-line */
  }, [getTokenSilently, setTokenSetted, loginWithRedirect, logout]);

  function exit() {
    logout({ returnTo: `${window.location.origin}` });
  }

  if ((!isTokenSetted || !user || !user._id) && !status) {
    return <Spinner />;
  }

  if (status) {
    return (
      <ErrorPage
        status={status}
        description="Please go back and try again."
        action={exit}
        actionText="Back"
      />
    );
  }

  return <>{children}</>;
}

Initialize.displayName = "Initialize";
export default withRouter(
  connect(
    (state: IStore) => ({
      user: getUser(state)
    }),
    {
      fetchUser
    }
  )(Initialize)
);
