import * as React from "react";
import Button from "../action-button";

import { ReactComponent as NotFoundIcon } from "../icons/404.svg";
import { ReactComponent as UnauthorizedIcon } from "../icons/401.svg";
import { ReactComponent as CommonErrorIcon } from "../icons/common_error.svg";

import styles from "./styles.module.css";

interface IProps {
  title?: string;
  status: number;
  description: React.ReactNode;
  action: () => void;
  actionText: string;
  showContact?: boolean;
}

export default function ErrorPage({
  status,
  title,
  description,
  action,
  actionText,
  showContact,
}: IProps) {
  let icon;
  switch (status) {
    case 401: {
      icon = <UnauthorizedIcon />;
      break;
    }
    case 404:
      icon = <NotFoundIcon />;
      break;
    default: {
      icon = <CommonErrorIcon />;
      break;
    }
  }
  return (
    <div className={styles.errorPage}>
      {icon}
      <div className={styles.title}>{title || "Oops."}</div>
      <div className={styles.description}>{description}</div>
      {showContact && (
        <div className={styles.description}>
          If you have any questions, contact us at{" "}
          <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
        </div>
      )}
      <Button onClick={action} relative>
        {actionText}
      </Button>
    </div>
  );
}
ErrorPage.displayName = "ErrorPage";
