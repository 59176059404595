import * as React from "react";
import { connect } from "react-redux";

import GlobalSpinner from "../components/spinner/global";
import {
  fetchPolicyBoost,
  FetchPolicyBoost,
} from "../resources/insurance/insurance.actions";
import { IBoostPolicy } from "../resources/insurance/types";
import { getBoostPolicy } from "../resources/insurance/insurance.selectors";
import { IStore } from "../resources/types";

interface InjectedProps {
  policy?: IBoostPolicy;
}

interface HocProps {
  policyFromState?: IBoostPolicy;
  fetchPolicyBoost: FetchPolicyBoost;
}

const withConnectedBoostPolicy = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  class Hoc extends React.Component<HocProps> {
    static displayName = `withConnectedBoostPolicy(${BaseComponent.name})`;
    static readonly WrappedComponent = BaseComponent;

    componentDidMount() {
      if (!this.props.policyFromState) {
        this.props.fetchPolicyBoost();
      }
    }

    render() {
      const { policyFromState, fetchPolicyBoost, ...restProps } = this.props;

      if (!policyFromState) {
        return <GlobalSpinner />;
      }

      return (
        <BaseComponent {...(restProps as BaseProps)} policy={policyFromState} />
      );
    }
  }

  const ConnectedHoc = connect(
    (state: IStore) => ({
      policyFromState: getBoostPolicy(state),
    }),
    {
      fetchPolicyBoost,
    }
  )(Hoc);

  return ConnectedHoc;
};
export default withConnectedBoostPolicy;
