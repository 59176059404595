import * as React from "react";
import cx from "classnames";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment-timezone";

import { DayPickerSingleDateController } from "react-dates";
import styles from "./date.module.css";
import { isWeekend } from "../../helpers/common";

import "./react_dates_overrides.css";

const daySizeMap = {
  55: 1000,
  47: 600,
  45: 400,
  40: 350,
  30: 0,
};

interface IProps {
  onChange: (date: Moment) => void;
  date: Moment | null;
  startDate?: Moment | null;
  endDate?: Moment | null;
  allowWeekends?: boolean;
  hasMonthSelector?: boolean;
  hasYearSelector?: boolean;
}

export default function DatePicker({
  date,
  onChange,
  allowWeekends,
  startDate,
  endDate,
  hasMonthSelector,
  hasYearSelector,
}: IProps) {
  const [daySize, setDaySize] = React.useState<number>(50);
  const [internalDate, setInternalDate] = React.useState(date);
  const [key, setKey] = React.useState("default");

  React.useEffect(() => {
    const newDaySize = reduce(
      daySizeMap,
      (acc: number, boundary: number, key: string) => {
        const windowSize: number = Number(window.innerWidth);
        if (windowSize > boundary) {
          return Number(key);
        }

        return acc;
      },
      50
    );

    setDaySize(newDaySize);
  }, []);

  React.useEffect(() => {
    setKey(Date.now().toString());
    if (internalDate && date && internalDate.month() === date.month()) {
      return;
    }
    setInternalDate(date);
  }, [date, internalDate]);

  function onDateChange(d: Moment | null) {
    if (d) {
      if (internalDate && internalDate.month() === d.month()) {
        setInternalDate(d);
      }
      onChange(d);
    }
  }

  function getVisibleMonth() {
    if (date) {
      return date;
    }

    let nextDate = moment.utc().add(1, "day").startOf("day");
    while (!allowWeekends && isWeekend(nextDate)) {
      nextDate = nextDate.add(1, "day");
    }
    return nextDate;
  }

  const renderMonth = ({
    month,
    onMonthSelect,
    onYearSelect,
  }: {
    month: Moment;
    onMonthSelect: (m: Moment, n: string) => void;
    onYearSelect: (m: Moment, n: string) => void;
  }) => {
    if (!hasMonthSelector && !hasYearSelector) {
      return (
        <div className={styles.month}>
          <span>{month.format("MMMM, YYYY")}</span>
        </div>
      );
    }
    let minYear = month.year() - 2;
    let maxYear = month.year() + 2;
    if (minYear > moment().year()) {
      minYear = moment().year() - 2;
    }
    if (maxYear < moment().year()) {
      maxYear = moment().year() + 2;
    }
    const yearOptions = [];
    for (let i = minYear; i <= maxYear; i += 1) {
      yearOptions.push(<option value={i}>{i}</option>);
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select
            className={cx(styles.select, styles.selectMonth)}
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={cx(styles.select, styles.selectYear)}
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {yearOptions}
          </select>
        </div>
      </div>
    );
  };
  const renderDayContents = (day: Moment) => (
    <div
      style={{
        width: `${daySize - 5}px`,
        height: `${daySize - 5}px`,
        lineHeight: `${daySize - 5}px`,
      }}
      className="hbrd_day"
    >
      {day.date()}
    </div>
  );

  const isOutsideRange = (day: Moment) => {
    const isPast = (startDate
      ? moment.utc(moment(startDate.valueOf()))
      : moment().utc()
    ).isAfter(day, "day");
    const isAfterEndDate =
      endDate && moment.utc(moment(endDate).valueOf()).isBefore(day, "day");
    return isPast || isAfterEndDate || (!allowWeekends && isWeekend(day));
  };

  const isDayHighlighted = () => {
    return false;
  };

  return (
    <DayPickerSingleDateController
      key={key}
      hideKeyboardShortcutsPanel
      date={date}
      onDateChange={onDateChange}
      focused
      onFocusChange={() => {}}
      numberOfMonths={1}
      firstDayOfWeek={0}
      enableOutsideDays
      noBorder
      isOutsideRange={isOutsideRange}
      weekDayFormat="dd"
      renderDayContents={renderDayContents}
      daySize={daySize}
      navPrev={<NavPrev />}
      navNext={<NavNext />}
      renderMonthElement={renderMonth}
      initialVisibleMonth={getVisibleMonth}
      isDayHighlighted={isDayHighlighted}
    />
  );
}

function NavPrev(props: any) {
  return (
    <div {...props} className={styles.arrowButtonLeft}>
      <i className={styles.arrowLeft} />
    </div>
  );
}

function NavNext(props: any) {
  return (
    <div {...props} className={styles.arrowButtonRight}>
      <i className={styles.arrowRight} />
    </div>
  );
}

DatePicker.displayName = "DatePicker";
NavPrev.displayName = "NavPrev";
NavNext.displayName = "NavNext";
