import * as React from "react";
import cx from "classnames";
import moment from "moment";

import styles from "./dob.module.css";

interface IProps {
  className?: string;
  value?: Date;
  onChange: (value?: Date) => void;
}

export default function DOBInput({ className, value, onChange }: IProps) {
  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const MIN_DATE = moment("01/01/1900", "MM/DD/YYYY");
  const MAX_DATE = moment();

  const inputDay = React.useRef<HTMLInputElement>(null);
  const inputYear = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!value) {
      return;
    }
    const [d, m, y] = moment(value).format("DD/MM/YYYY").split("/");
    setDay(d);
    setMonth(m);
    setYear(y);
  }, [value]);

  function onValueChanged(
    currentYear?: string,
    currentMonth?: string,
    currentDay?: string
  ) {
    if (!currentDay || !currentMonth || !currentYear) {
      return;
    }

    const date = moment(
      `${currentMonth}/${currentDay}/${currentYear}`,
      "MM/DD/YYYY"
    );

    if (!date.isValid() || date.isBefore(MIN_DATE) || date.isAfter(MAX_DATE)) {
      onChange(undefined);
      return;
    }

    onChange(date.toDate());
  }

  function onDayChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (!value) {
      setDay("");
      return;
    }
    if (value.length > 2) {
      return;
    }
    const num = +value;
    if (value.length === 2 && (num > 31 || num < 1)) {
      setDay("");
      return;
    }
    setDay(value);
    if (value.length === 2 && inputYear.current) {
      inputYear.current.focus();
    }
    onValueChanged(year, month, value);
  }
  function onMonthChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (!value) {
      setMonth("");
      return;
    }
    if (value.length > 2) {
      return;
    }
    const num = +value;
    if (value.length === 2 && (num > 12 || num < 1)) {
      setMonth("");
      return;
    }
    setMonth(value);
    if (value.length === 2 && inputDay.current) {
      inputDay.current.focus();
    }
    onValueChanged(year, value, day);
  }
  function onYearChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (!value) {
      setYear("");
      return;
    }
    if (value.length > 4) {
      return;
    }
    const num = +value;
    if (value.length === 4 && (num > 2019 || num < 1900)) {
      setYear("");
      return;
    }
    setYear(value);
    onValueChanged(value, month, day);
  }

  return (
    <div className={cx(styles.dob, className)}>
      <input
        data-id="month"
        type="number"
        pattern="[0-9]*"
        value={month === undefined ? "" : month}
        onChange={onMonthChange}
        placeholder="MM"
        className={styles.month}
      />
      <input
        data-id="date"
        type="number"
        pattern="[0-9]*"
        value={day === undefined ? "" : day}
        onChange={onDayChange}
        placeholder="DD"
        className={styles.day}
        ref={inputDay}
      />
      <input
        data-id="year"
        type="number"
        pattern="[0-9]*"
        value={year === undefined ? "" : year}
        onChange={onYearChange}
        placeholder="YYYY"
        className={styles.year}
        ref={inputYear}
      />
    </div>
  );
}
DOBInput.displayName = "DOBInput";
