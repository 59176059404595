export type IFactors = { [key: string]: number | undefined };

export const CLAIMS_STATUS: { [key: string]: string } = {
  ACTIVE: "Active",
  CLOSED: "Closed",
};

export interface IUploadPolicy {
  companyName: string;
  coverageAmount?: number;
  startDate?: Date;
  expiresAt?: Date;
  isContinuous?: boolean;
  policy?: File[];
}

export interface ICoverage {
  value: number;
  options: number[];
}

export interface IInsurance {
  _id: string;
  status: string;
  type: "EXTERNAL" | "LEMONADE" | "BOOST";
  startDate?: Date;
  endDate?: Date;
  policyId?: string;
  cancelDate?: Date;
  cancelReason?: string;
  timezone?: string;
}

export interface IPolicy {
  _id: string;
  companyName: string;
  expiresAt: Date;
  type: "EXTERNAL" | "LEMONADE" | "BOOST";
}

export interface IQuote {
  _id: string;
  resident: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    dateOfBirth: number;
  };
  building: {
    _id: string;
    street: string;
    streetNumber: string;
    city: string;
    state: string;
    zip: string;
    unit: string;
  };
  type: "renters" | "condo" | "homeowners";
  token?: string;
  last4?: string;
  cardBrand?: string;
  status: string;
  monthlyPremiumInCents: number;
  installmentFee?: number;
  effectiveDate: {
    currentValue: number;
    minValue: number;
    maxValue: number;
  };
  tosApproved: boolean;
  quoteUrl: string;
  samplePolicyUrl: string;
  termsOfServiceUrl: string;
  coverages: {
    dwelling?: ICoverage;
    personalProperty?: ICoverage;
    personalLiability?: ICoverage;
    lossOfUse?: ICoverage;
    medicalPaymentsToOthers?: ICoverage;
  };
  baseDeductible: ICoverage;
}

export interface IPaymentMethod {
  id: string;
  zip: string;
  type?: "card" | "ach";
  card?: {
    brand: string;
    country: string;
    funding: string;
    expMonth?: number;
    expYear?: number;
    last4: string;
  };
  ach?: {
    mask?: string;
    name?: string;
    subtype?: string;
    type?: string;
  };
}

export interface IAdditionalInsured {
  _id: string;
  type: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  isPrimary: boolean;
}

export interface IAdditionalInterested {
  _id: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  url?: string;
}

export interface IBoostQuote {
  _id: string;
  resident: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  building: {
    _id: string;
    street: string;
    streetNumber: string;
    city: string;
    state: string;
    zip: string;
    unit: string;
  };
  quote: any;
  summary: IBoostSummary;
  policyStatus: "ACTIVE" | "FUTURE" | "CANCELED";
  type: "UPDATE" | null;
  summaryHash?: string;
}

export interface ICoverageValue {
  id: string;
  limit?: number;
  factors?: IFactors;
}

export interface IBoostSummary {
  minDate?: Date;
  startDate: Date;
  endDate: Date;
  personalLiability: number;
  personalProperty: number;
  baseDeductible: number;
  paymentAmount: number;
  feeAmount: number;
  timezone: string;
  enhancementType: string;
  extraCoverages: ICoverageValue[];
  animalLiability: number;
  lossOfUse: number;
  medicalPayments: number;
  damangeToProperty: number;
  additionalInsured: IAdditionalInsured[];
  additionalInterested: IAdditionalInterested[];
  hash?: string;
}

export interface IBoostPolicy {
  _id: string;
  resident: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  building: {
    _id: string;
    street: string;
    streetNumber: string;
    city: string;
    state: string;
    zip: string;
    unit: string;
  };
  policy: any;
  paymentMethod: IPaymentMethod;
  summary: IBoostSummary;
  installmentPlan: any[];
  status: "ACTIVE" | "FUTURE" | "CANCELED";
  url?: string;
  previousActivePolicyId?: string;
  numberOfClaims: number;
}

export interface IBoostClaim {
  _id: string;
  resident: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    unit: string;
  };
  building: {
    _id: string;
  };

  policyId: string;
  policyNumber: string;
  insuredRiskId: string;

  date: Date;
  dateOfLoss: string;
  description: string;
  email: string;
  phone: string;

  claim: any;

  status: string;

  documents?: {
    name: string;
    mimeType: string;
    url: string;
    sizeInBytes: number;
    date: Date;
  }[];

  submittedAt: Date;
  deletedAt?: Date;
}

export interface IUpdateQuoteParams {
  mortgage: boolean;
  effectiveDate: Date;
  dwelling: number;
  personalProperty: number;
  personalLiability: number;
  lossOfUse: number;
  medicalPaymentsToOthers: number;
  baseDeductible: number;
}

export interface IUpdateBoostQuoteParams {
  effectiveDate: Date;
  enhancementType?: string;
  personalLiability?: number;
  personalProperty?: number;
  baseDeductible?: number;
  extraCoverages?: ICoverageValue[];
}

export interface IInsuranceState {
  quote?: IQuote;
  boost?: IBoostQuote;
  boostPolicy?: IBoostPolicy;
  policy?: IPolicy;
  insurance?: IInsurance;
  boostCoverage?: IUpdateBoostQuoteParams;
  factors?: IFactors;
  claims?: IBoostClaim[];
  claim?: IBoostClaim;
  personalLiabilityOptions?: number[];
}

export interface ISetQuoteTokenParams {
  token: string;
  last4: string;
  cardBrand: string;
}

export interface IAddPaymentMethodParams {
  token: string;
  last4: string;
  cardBrand: string;
}
