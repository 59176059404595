import * as React from "react";
import classnames from "classnames";
import { ReactComponent as CheckIcon } from "../icons/check.svg";

import styles from "./checkbox.module.css";

interface IProps {
  "data-id"?: string;
  name: string;
  checked: boolean;
  onChange: any;
  onClick?: any;
  label?: string;
  LabelComponent?: any;
  className?: string;
  checkmarkClassName?: string;
  checkedClassName?: string;
  disabledClassName?: string;
  disabled?: boolean;
  checkIcon?: React.ReactElement;
}

const Checkbox = ({
  name,
  checked = false,
  onChange,
  onClick = () => {},
  label,
  LabelComponent,
  className,
  checkmarkClassName,
  checkedClassName,
  disabledClassName,
  disabled,
  checkIcon,
  ...props
}: IProps) => {
  function onValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled) {
      onChange(e);
    }
  }

  return (
    <label
      data-id={`${props["data-id"]}Checkbox`}
      className={classnames(
        className,
        checkedClassName && {
          [checkedClassName]: checked,
        },
        disabledClassName && {
          [disabledClassName]: disabled,
        }
      )}
    >
      <span
        className={classnames(styles.checkmark, checkmarkClassName, {
          [styles.checked]: checked,
        })}
      >
        {checkIcon ? (
          React.cloneElement(checkIcon, { className: styles.line })
        ) : (
          <CheckIcon className={styles.line} />
        )}
      </span>
      <input
        data-id={`${props["data-id"]}Input`}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onValueChange}
        onClick={onClick}
      />
      {Boolean(LabelComponent) && <LabelComponent />}
      {label}
    </label>
  );
};

Checkbox.displayName = "Checkbox";
export default Checkbox;
