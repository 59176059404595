import * as yup from "yup";

export interface IFormValues {
  files?: File[];
  isDamaged?: number;
}

export default yup.object().shape({
  isDamaged: yup.number().notRequired(),
  files: yup
    .array()
    .of(
      yup
        .mixed()
        .test(
          "fileSize",
          "Sorry, file size is too large. The maximum file size allowed is set to 15Mb.",
          (value: File) => value && value.size <= 15728640
        )
        .test(
          "fileType",
          "Please upload one of the supported file types: pdf, csv, xls, xlsx, png, jpg, jpeg, heic, tif, tiff, bmp.",
          (value: File) =>
            value &&
            (value.type.includes("image/") ||
              value.type.includes("application/pdf") ||
              value.type.includes("csv") ||
              value.type.includes("applcation/pdf") ||
              value.type.includes("vnd.ms-excel") ||
              value.type.includes("vnd.openxmlformats")) &&
            !value.type.includes("image/gif")
        )
        .test(
          "fileName",
          "Sorry, file name is too long. The maximum file name is 250 characters.",
          (value: File) => value && value.name.length <= 250
        )
    )
    .max(15, "Maximum 15 files are allowed.")
});
