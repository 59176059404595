import * as React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import BackButton from "../../../components/back-button";
import { useAuth } from "../../../auth";
import { loadingStart } from "../../../resources/loading";

import styles from "./header.module.css";

interface IProps {
  loadingStart: () => void;
  className?: string;
  showBackButton: boolean;
  isCloseButton?: boolean;
  isBlackButton?: boolean;
  onBack?: () => void;
}

function Header({
  className,
  showBackButton,
  isCloseButton,
  isBlackButton,
  onBack,
  loadingStart,
}: IProps) {
  const history = useHistory();
  const authData = useAuth();

  const showBackButtonOnHome =
    history.location.pathname === "/" && authData && authData.type === "in-app";

  const onBackClick = async () => {
    if (history.location.pathname === "/insurance") {
      history.push("/");
      return;
    }
    if (history.location.pathname === "/") {
      loadingStart();
      const rawToken = await authData.getRawToken();
      setTimeout(() => {
        window.location.replace(
          `${config.returnLink}${rawToken ? `?token=${rawToken}` : ""}`
        );
      }, 0);
      return;
    }
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  const backButtonShown = showBackButton || showBackButtonOnHome;
  return (
    <div id="header" className={cx(styles.container, className)}>
      <div className={styles.header}>
        {backButtonShown && (
          <BackButton
            onClick={onBackClick}
            isBlack={isBlackButton}
            isClose={isCloseButton}
          />
        )}
        <div className={styles.services} />
      </div>
    </div>
  );
}

Header.displayName = "Header";
export default connect(() => ({}), {
  loadingStart,
})(Header);
