import * as React from "react";
import { useLocation } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { withStripe } from "../credit-card/stripe";
import Modal from "../modal";
import Button from "../action-button";
import { modalComponent } from "../../modules/modalProvider";

import styles from "./auth-payment.module.css";

interface IProps {
  hideModal: () => void;
  show?: boolean;
  clientSecret: string;
  paymentMethodId: string;
  onComplete?: () => void;
  currentPathname?: string;
}

export const messages = {
  capture:
    "The payment was authorized and you will be charged when booking will be completed.",
  complete: "Payment completed.",
  fail: "Payment failed.",
  progress: "Payment is in progress...",
  default:
    "To complete your payment you will be redirected to the bank’s website.",
};

export const mapStripeCodeToMessage: { [key: string]: string } = {
  payment_intent_authentication_failure:
    "We are unable to authenticate your payment method. Please try again or contact our support team.",
  setup_intent_authentication_failure:
    "We are unable to authenticate your payment method. Please choose a different payment method or try again.",
};

function AuthorizePayment({
  hideModal,
  show,
  clientSecret,
  paymentMethodId,
  onComplete,
  currentPathname,
}: IProps) {
  const location = useLocation();
  const stripe = useStripe();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<undefined | string>();
  const [message, setMessage] = React.useState(messages.default);

  const initAuth = () => {
    setError(undefined);
    if (stripe && !isSubmitting && clientSecret && paymentMethodId) {
      setMessage(messages.default);
      setIsSubmitting(true);

      setTimeout(authPayment, 2000);
    }
  };

  const authPayment = async () => {
    if (stripe) {
      setMessage(messages.progress);
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethodId,
        }
      );

      const customMessage =
        confirmError &&
        confirmError.code &&
        mapStripeCodeToMessage[confirmError.code];
      if (confirmError && customMessage) {
        setMessage(messages.fail);
        setError(customMessage);
      } else if (confirmError) {
        setMessage(messages.fail);
        setError(confirmError.message);
      } else {
        setMessage(messages.complete);
        setTimeout(() => {
          hideModal();
          if (onComplete) {
            onComplete();
          }
        }, 2000);
      }

      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (currentPathname && location.pathname !== currentPathname) {
      hideModal();
    }
  }, [location, hideModal, currentPathname]);

  React.useEffect(() => {
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, clientSecret, paymentMethodId]);

  const header = <div className={styles.title}>{message}</div>;

  return (
    <Modal
      header={header}
      className={styles.modal}
      show={show}
      onClose={hideModal}
    >
      <div className={styles.error}>{error || ""}</div>
      <div>
        {error && (
          <div className={styles.buttons}>
            <Button onClick={initAuth} type="button" disabled={isSubmitting}>
              Try again
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default modalComponent(withStripe(AuthorizePayment));
