import * as React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { SafeAreaContainer } from "../../../../components/container";
import Header from "../../../../components/header";

import { IStore } from "../../../../resources/types";
import {
  IBoostQuote,
  IBoostPolicy,
  IAdditionalInterested,
} from "../../../../resources/insurance/types";
import {
  getBoostQuote,
  getBoostPolicy,
} from "../../../../resources/insurance/insurance.selectors";
import {
  fetchQuoteBoost,
  FetchQuoteBoost,
  fetchPolicyBoost,
  FetchPolicyBoost,
} from "../../../../resources/insurance/insurance.actions";

import GTag from "../../../../gtag";

import { ReactComponent as ArrowIcon } from "../../../../components/icons/arrow.svg";

import styles from "./additional-interested.module.css";

const TYPES: { [key: string]: string } = {
  individual: "Individual",
  business: "Business",
};

interface IProps {
  quote?: IBoostQuote;
  policy?: IBoostPolicy;
  fetchQuoteBoost: FetchQuoteBoost;
  fetchPolicyBoost: FetchPolicyBoost;
}

function AdditionalInterested({
  quote,
  fetchQuoteBoost,
  policy,
  fetchPolicyBoost,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const isPolicy = history.location.pathname.includes("boost/policy");

  React.useEffect(() => {
    GTag.event("additinal_interested", "Page");

    if (isPolicy) {
      fetchPolicyBoost();
    } else {
      fetchQuoteBoost();
    }
  }, [fetchQuoteBoost, fetchPolicyBoost, isPolicy]);

  const openAddParty = () => {
    GTag.event("add_additional_interested", "Page");
    history.push(
      `/insurance/boost/${
        isPolicy ? "policy" : "edit"
      }/${id}/additionalInterested/add`
    );
  };

  const openEditParty = (party: IAdditionalInterested) => {
    GTag.event("edit_additional_interested", "Page");
    history.push(
      `/insurance/boost/${
        isPolicy ? "policy" : "edit"
      }/${id}/additionalInterested/${party._id}`
    );
  };

  if (!quote && !policy) {
    return null;
  }

  const summary = quote?.summary || policy?.summary;
  if (!summary) {
    return null;
  }

  return (
    <div className={styles.page}>
      <SafeAreaContainer className={styles.head}>
        <Header>Additional interested</Header>
        <div className={styles.subHeader}>
          An additional interest (sometimes referred to as an interested party
          or a party of interest) is a third party who benefits from knowing an
          insurance policy is in place but does not directly benefit from the
          coverage (e.g. property manager of your building). Additional
          interests that are added to insurance policies will get Certificates
          of insurance and will be notified when changes to the policy are made.
          These changes include policy cancellations, lapses in coverage,
          renewals, or failure to renew a policy. You can add up to 5 additional
          interest.
        </div>
      </SafeAreaContainer>
      <SafeAreaContainer className={styles.container}>
        <div>
          {summary.additionalInterested.map(
            (item: IAdditionalInterested, index: number) => (
              <div
                key={index}
                data-id={`additionalInterested${index}`}
                className={styles.setting}
                role="button"
                onClick={() => openEditParty(item)}
              >
                <div className={styles.title}>
                  {item.businessName || `${item.firstName} ${item.lastName}`}
                </div>
                <div className={styles.description}>{TYPES[item.type]}</div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
            )
          )}
        </div>
        {summary.additionalInterested.length < 5 && (
          <div>
            <button
              className={styles.link}
              onClick={openAddParty}
              data-id="addInterestedBtn"
            >
              + Add additional interested
            </button>
          </div>
        )}
      </SafeAreaContainer>
    </div>
  );
}

AdditionalInterested.displayName = "AdditionalInterested";
export default connect(
  (state: IStore) => ({
    quote: getBoostQuote(state),
    policy: getBoostPolicy(state),
  }),
  {
    fetchQuoteBoost,
    fetchPolicyBoost,
  }
)(AdditionalInterested);
