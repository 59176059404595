import * as React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import withBoostQuote from "../../../hoc/boostQuote.hoc";
import { formatMoneyInCents } from "../../../helpers/common";

import { showModal } from "../../../modules/modalProvider";
import LayoutHeader from "../../../layouts/main/header";
import Container, { SafeAreaContainer } from "../../../components/container";
import Header from "../../../components/header";
import DateComponent from "../../../components/date/inline";
import Button from "../../../components/action-button";
import Price from "../components/pricing";
import Confirm from "../../../components/confirm";

import { IStore } from "../../../resources/types";
import { ReactComponent as ArrowIcon } from "../../../components/icons/arrow.svg";

import {
  updateQuoteBoost,
  UpdateQuoteBoost,
  fetchQuoteBoost,
  FetchQuoteBoost,
  fetchPersonalLiabilityOptions,
  FetchPersonalLiabilityOptions,
} from "../../../resources/insurance/insurance.actions";
import { IBoostQuote } from "../../../resources/insurance/types";
import { fetchUser, FetchUser } from "../../../resources/user/user.actions";
import { getUser } from "../../../resources/user/user.selectors";
import { IUser } from "../../../resources/user/types";

import { notCoverWindHailHurricane } from "./boost.config";

import GTag from "../../../gtag";

import styles from "./boost.module.css";

interface IProps {
  user: IUser;
  quote: IBoostQuote;
  fetchQuoteBoost: FetchQuoteBoost;
  updateQuoteBoost: UpdateQuoteBoost;
  fetchPersonalLiabilityOptions: FetchPersonalLiabilityOptions;
  fetchUser: FetchUser;
}

function EditInsurance({
  fetchUser,
  updateQuoteBoost,
  quote,
  user,
  fetchQuoteBoost,
  fetchPersonalLiabilityOptions,
}: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [startDate, setStartDate] = React.useState<Date>(
    quote
      ? moment.utc(quote.summary.startDate).toDate()
      : moment.utc().add(1, "day").startOf("day").toDate()
  );

  React.useEffect(() => {
    fetchQuoteBoost({ skipLoading: true });
    fetchPersonalLiabilityOptions({ skipLoading: true });
    GTag.event("boost_edit_policy", "Page");
  }, [fetchQuoteBoost, fetchPersonalLiabilityOptions]);

  React.useEffect(() => {
    setStartDate(moment.utc(quote.summary.startDate).toDate());
  }, [quote]);

  React.useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [fetchUser, user]);

  const onEffectiveDateChange = async (date: Date) => {
    setStartDate(date);
    GTag.event("boost_date_change", "Click");
    await updateQuoteBoost({ effectiveDate: date });
  };

  const openCoverage = () => {
    GTag.event("boost_coverage", "Page");
    history.push(`/insurance/boost/edit/${id}/сoverage`);
  };

  const openDeductible = () => {
    GTag.event("boost_deductible", "Page");
    history.push(`/insurance/boost/edit/${id}/deductible`);
  };

  const openExtraCoverage = () => {
    GTag.event("boost_extra_coverage", "Page");
    history.push(`/insurance/boost/edit/${id}/extra`);
  };

  const openAdditionalInsured = () => {
    GTag.event("additional_insured", "Page");
    history.push(`/insurance/boost/edit/${id}/additionalInsured`);
  };

  const openAdditionalInterested = () => {
    GTag.event("additional_interested", "Page");
    history.push(`/insurance/boost/edit/${id}/additionalInterested`);
  };

  const goBack = () => {
    if (
      quote.type !== "UPDATE" ||
      (quote.summaryHash && quote.summaryHash === quote.summary.hash)
    ) {
      history.push("/");
      return;
    }
    showModal(
      <Confirm
        action={async () => {
          history.push("/");
        }}
        title="Your modifications have not been applied. Do you want to continue?"
        yes="Yes"
        no="No"
      />,
      undefined
    );
  };

  const pay = () => {
    GTag.event("boost_payment", "Page");
    history.push(`/insurance/boost/edit/${id}/payment`);
  };

  const isModified =
    quote.summaryHash && quote.summaryHash !== quote.summary.hash;

  return (
    <>
      <LayoutHeader className={styles.header} showBackButton onBack={goBack} />
      <Container>
        <SafeAreaContainer className={styles.head}>
          <Header>
            Build your
            <br />
            quote
          </Header>
        </SafeAreaContainer>
        <div className={styles.container}>
          <div>
            <div className={styles.section1}>
              <Price
                className={styles.pricing}
                amount={quote.summary.paymentAmount}
                period="month"
              />
              <div className={styles.panel}>
                <DateComponent
                  startDate={
                    quote.summary.minDate
                      ? moment
                          .utc(moment(quote.summary.minDate).valueOf())
                          .startOf("day")
                          .toDate()
                      : moment.utc().add(1, "day").startOf("day").toDate()
                  }
                  endDate={moment.utc().add(30, "day").startOf("day").toDate()}
                  date={startDate}
                  onChange={onEffectiveDateChange}
                  title={
                    quote.type === "UPDATE" ? "Effective Date" : "Start Date"
                  }
                  readonly={
                    quote.type === "UPDATE" && quote.policyStatus !== "FUTURE"
                  }
                />
              </div>
            </div>
            <div className={styles.section2}>
              <div className={styles.descriptionText}>
                Customize your coverage amounts and choose your deductible.
              </div>

              <div
                className={styles.setting}
                role="button"
                data-id="openCoverage"
                onClick={openCoverage}
              >
                <div className={styles.settingText}>
                  <h4>Coverage details</h4>
                </div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
              <div
                className={styles.setting}
                role="button"
                data-id="openDeductible"
                onClick={openDeductible}
              >
                <div className={styles.settingText}>
                  <h4>Deductible amount</h4>
                </div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
              <div
                className={styles.setting}
                role="button"
                data-id="openOptionalCoverage"
                onClick={openExtraCoverage}
              >
                <div className={styles.settingText}>
                  <h4>Optional coverage</h4>
                </div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
              <hr className={styles.line} />
              <div className={styles.parties}>
                <div className={styles.descriptionText}>
                  Manage additional insured and additional interested.
                </div>
                <div
                  className={styles.setting}
                  role="button"
                  data-id="openAdditionalInsured"
                  onClick={openAdditionalInsured}
                >
                  <div className={styles.settingText}>
                    <h4>Additional insured</h4>
                  </div>
                  <ArrowIcon className={styles.arrowIcon} />
                </div>
                <div
                  className={styles.setting}
                  role="button"
                  data-id="openAdditionalInterested"
                  onClick={openAdditionalInterested}
                >
                  <div className={styles.settingText}>
                    <h4>Additional interested</h4>
                  </div>
                  <ArrowIcon className={styles.arrowIcon} />
                </div>
              </div>
              <div className={styles.disclosure}>
                {notCoverWindHailHurricane.includes(user.state)
                  ? ""
                  : "Your coverage includes a $1,000 wind, hail, or hurricane deductible."}
              </div>
            </div>
          </div>
          <>
            <Button
              onClick={pay}
              data-id="payBtn"
              disabled={quote.type === "UPDATE" && !isModified}
            >
              {quote.type === "UPDATE"
                ? "Modify policy"
                : `Pay ${formatMoneyInCents(
                    quote.summary.paymentAmount
                  )} a month`}
            </Button>
          </>
        </div>
      </Container>
    </>
  );
}

EditInsurance.displayName = "EditInsurance";
export default withBoostQuote(
  connect(
    (state: IStore) => {
      return {
        user: getUser(state),
      };
    },
    {
      fetchUser,
      fetchQuoteBoost,
      updateQuoteBoost,
      fetchPersonalLiabilityOptions,
    }
  )(EditInsurance)
);
