import * as React from "react";
import capitalize from "lodash/capitalize";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import values from "lodash/values";
import { lowerFirst } from "lodash";
import { formatMoneyInCents } from "../../../helpers/common";
import Header from "../../../components/header";
import Checkbox from "../../../components/checkbox";
import { withStripe } from "../../../components/credit-card/stripe.lemonade";
import Button from "../../../components/action-button";
import CardIcon from "../../../components/card-icon";
import { SafeAreaContainer } from "../../../components/container";
import withConnectedQuote from "../../../hoc/quote.hoc";
import { loadingStart, loadingEnd } from "../../../resources/loading";
import { getItem, setItem } from "../../../helpers/storage";
import {
  payQuote,
  PayQuote,
  fetchQuote,
  FetchQuote,
} from "../../../resources/insurance/insurance.actions";

import { IQuote } from "../../../resources/insurance/types";

import GTag from "../../../gtag";

import styles from "./pay.module.css";

interface IProps {
  fetchQuote: FetchQuote;
  quote: IQuote;
  payQuote: PayQuote;
}

const STORAGE_PAYMENT_ERROR = "lemonade_payment_errors";

function Payment({ quote, fetchQuote, payQuote }: IProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  const onAddNewCard = () => history.push(`/insurance/edit/${id}/payment/add`);

  React.useEffect(() => {
    fetchQuote();
  }, [fetchQuote]);

  async function onSubmit(e: React.FormEvent) {
    setServerError("");
    e.preventDefault();
    e.stopPropagation();
    if (!quote.token) {
      setServerError("Please add a credit card");
      return;
    }
    setIsSubmitting(true);

    try {
      GTag.event("lemonade_submit", "Click");
      await payQuote({ tosApproved: true });
      history.push(`/insurance/edit/${id}/success`);
    } catch (err) {
      if (err.data && err.data.errors) {
        setServerError(values(err.data.errors[0])[0]);
      }
      setIsSubmitting(false);
      const numberOfErrors = (+getItem(STORAGE_PAYMENT_ERROR) || 0) + 1;
      setItem(STORAGE_PAYMENT_ERROR, numberOfErrors.toString());
      if (numberOfErrors < 3) {
        history.push("/insurance/error/check");
      } else {
        history.push("/insurance/error/payment");
      }
    }
  }

  return (
    <>
      <SafeAreaContainer className={styles.head}>
        <Header>Payment method</Header>
      </SafeAreaContainer>
      <SafeAreaContainer>
        <div className={styles.subTitle}>
          Choose a payment method you’d like to use for Lemonade.
        </div>
        <Checkbox
          data-id="card"
          className={styles.checkbox}
          checkmarkClassName={styles.checkmark}
          key={quote._id}
          name={quote._id}
          LabelComponent={() => (
            <div className={styles.paymentMethod}>
              <div className={styles.cardNumber}>
                {quote.token ? (
                  <CardIcon
                    cardBrand={lowerFirst(quote.cardBrand)}
                    className={styles.cardIcon}
                  />
                ) : null}
                {quote.token ? (
                  <span className={styles.card}>
                    {capitalize(quote.cardBrand)} ending in {quote.last4}
                  </span>
                ) : (
                  "Credit card"
                )}
              </div>
              {!quote.token ? (
                <div className={styles.icons}>
                  <CardIcon allBrands className={styles.cardIcon} />
                </div>
              ) : null}
            </div>
          )}
          checked={Boolean(quote.token)}
          onChange={onAddNewCard}
        />
        <div className={styles.errorBlock}>{serverError}</div>
      </SafeAreaContainer>
      <Button onClick={onSubmit} disabled={isSubmitting} data-id="payBtnt">
        Pay {formatMoneyInCents(quote.monthlyPremiumInCents)} a month
      </Button>
    </>
  );
}

Payment.displayName = "Payment";
export default withConnectedQuote(
  connect(() => ({}), {
    loadingStart,
    loadingEnd,
    payQuote,
    fetchQuote,
  })(withStripe(Payment))
);
