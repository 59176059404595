import config from "../config";

export default function mergePath(api: "umbrella" | "api", path: string) {
  switch (api) {
    case "umbrella": {
      return config.umbrellaBasePath
        ? `/${config.umbrellaBasePath}${path}`
        : path;
    }
    case "api": {
      return config.apiBasePath ? `/${config.apiBasePath}${path}` : path;
    }
    default: {
      return path;
    }
  }
}
