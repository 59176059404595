import moment from "moment-timezone";

import { IUser } from "../resources/user/types";
import { IInsurance } from "../resources/insurance/types";

export const getInsuranceText = (user: IUser, insurance?: IInsurance) => {
  let insuranceText = "Upload your existing policy or purchase a new policy.";
  const companyName =
    user.insuranceType === "LEMONADE" ? "Lemonade" : "Latch Insurance";
  if (insurance && insurance.type === "EXTERNAL") {
    switch ((insurance.status || "").toUpperCase()) {
      case "IN_REVIEW": {
        if (user.isD2R) {
          insuranceText =
            "Thank you for uploading your policy. We’ll contact you if there are any questions or issues.";
        } else {
          insuranceText = `
            Hold tight, we’re checking to see if the policy meets the requirements of ${user.building}. We’ll contact you if there are any questions or issues.
          `;
        }
        break;
      }
      case "DECLINED": {
        insuranceText = `
          The insurance policy you uploaded does not meet the requirements of ${
            user.building
          }.
          Please contact your property manager for additional details.
          ${!!user.insuranceType &&
            `You can upload another active policy here or purchase a policy from ${companyName} that meets the requirements.`}
        `;
        break;
      }
      case "CONFIRMED":
      case "FUTURE":
      case "ACTIVE": {
        insuranceText = "Well done! Your insurance policy is confirmed.";
        break;
      }
      default: {
        break;
      }
    }
  }
  if (insurance && insurance.type === "LEMONADE") {
    switch ((insurance.status || "").toUpperCase()) {
      case "IN_REVIEW": {
        insuranceText = `Hold tight, ${companyName} is finalizing some details on your order and will get back to you in a bit.`;
        break;
      }
      case "DECLINED": {
        break;
      }
      case "CONFIRMED":
      case "FUTURE":
      case "ACTIVE": {
        if (
          moment
            .utc(insurance.startDate)
            .startOf("day")
            .toDate() <
          moment
            .utc()
            .startOf("day")
            .toDate()
        ) {
          if (insurance.endDate) {
            insuranceText = `Active through ${moment
              .utc(insurance.endDate)
              .format("MM/DD/YYYY")}`;
          }
        } else {
          insuranceText = `Starts on ${moment
            .utc(insurance.startDate)
            .format("MM/DD/YYYY")}`;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  if (insurance && insurance.type === "BOOST") {
    switch ((insurance.status || "").toUpperCase()) {
      case "IN_REVIEW": {
        insuranceText = `Hold tight, ${companyName} is finalizing some details on your order and will get back to you in a bit.`;
        break;
      }
      case "CANCELED":
      case "DECLINED": {
        if (
          insurance.cancelDate &&
          insurance.cancelReason &&
          insurance.cancelReason !== "insured_requested" &&
          insurance.cancelReason !== "non_continue"
        ) {
          insuranceText = `Your insurance policy has been cancelled by Boost Insurance. Our team will contact you about this issue.`;
        } else if (
          insurance.cancelDate &&
          moment
            .utc(insurance.cancelDate)
            .startOf("day")
            .toDate() >
            moment
              .utc()
              .startOf("day")
              .toDate()
        ) {
          insuranceText = `You policy will be deactivated on ${moment
            .utc(insurance.cancelDate)
            .format("MM/DD/YYYY")}`;
        } else if (
          insurance.cancelDate &&
          moment
            .utc(insurance.cancelDate)
            .startOf("day")
            .toDate() <=
            moment
              .utc()
              .startOf("day")
              .toDate()
        ) {
          insuranceText = `You policy has been deactivated on ${moment
            .utc(insurance.cancelDate)
            .format("MM/DD/YYYY")}`;
        }
        break;
      }
      case "CONFIRMED":
      case "FUTURE":
      case "ACTIVE": {
        if (
          moment
            .utc(insurance.startDate)
            .startOf("day")
            .toDate() <
          moment
            .utc()
            .startOf("day")
            .toDate()
        ) {
          if (insurance.endDate) {
            insuranceText = `Active through ${moment
              .utc(insurance.endDate)
              .format("MM/DD/YYYY")}`;
          }
        } else {
          insuranceText = `Starts on ${moment
            .utc(insurance.startDate)
            .format("MM/DD/YYYY")}`;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  return insuranceText;
};
