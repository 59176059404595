import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import Header from "../../components/header";
import GlobalSpinner from "../../components/spinner/global";
import { SafeAreaContainer } from "../../components/container";
import { getBoostPolicy } from "../../resources/insurance/insurance.selectors";
import { IBoostPolicy } from "../../resources/insurance/types";
import { IStore } from "../../resources/types";

import { refundStates } from "../insurance/boost/boost.config";

import GTag from "../../gtag";

import styles from "./confirm.module.css";

interface IProps {
  policy?: IBoostPolicy;
}

function Confirmation({ policy }: IProps) {
  const history = useHistory();

  React.useEffect(() => {
    GTag.event("boost_policy_cancellation_confirmation", "Page");
  }, []);

  if (!policy) {
    return <GlobalSpinner />;
  }

  async function onSubmit() {
    if (!policy) {
      return;
    }
    history.push("/");
  }

  const description = refundStates.includes(
    policy?.policy.data.attributes.policy_state
  )
    ? "By state law, coverage will end immediately and you will receive a pro-rated refund for the unused days remaining in the current policy period. Refunds take up to 5 business days to process."
    : "By state law, coverage will continue until the end of the current policy period.";

  return (
    <SafeAreaContainer className={styles.container}>
      <Header className={styles.title}>Cancel confirmation</Header>
      <div className={styles.description}>{description}</div>

      <div className={styles.messageContainer}>
        <div className={styles.subTitle}>What's next?</div>
        <div className={styles.text}>
          <div className={styles.line3}>
            If you need additional information about your policy, please refer
            to your policy document. If you have questions or need assistance,
            please contact us at{" "}
            <a href="mailto:insurance@latch.com">insurance@latch.com</a>.
          </div>
        </div>
        <Button onClick={onSubmit} className={styles.btn} data-id="doneBtn">
          Done
        </Button>
      </div>
    </SafeAreaContainer>
  );
}

Confirmation.displayName = "Confirmation";
export default connect((state: IStore) => ({
  policy: getBoostPolicy(state),
}))(Confirmation);
